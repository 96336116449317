import React, { Component, Fragment, useState } from 'react';
import { confirmWrapper } from '../ConfirmAlert';
import { infoWrapper } from '../InfoAlert';
import * as api from '../API/Api';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import $ from 'jquery';

export class AddorUpdateCountry extends Component {
  static DisplayName = AddorUpdateCountry.name
  constructor(props){
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.state = {
      Action:'',
      CountryId:'',
      Name:'',
      Code:''
    }
  }
  componentDidMount(){
    const id = this.props.id
    if(id > 0){
      api.fetchData('/Api/Countries/CountrydetailsById?id='+id)
      .then(res => {
        let data = res.data
        this.setState({Action: 'Update', CountryId: data.countryId, Name: data.name, Code: data.code})
        console.log(data)
      })
    }
    else{
      this.setState({Action: 'Create', CountryId: 0, Name: '', Code:''})
    }    
  }
  onSubmit(e){
    e.preventDefault()
    const Country = {
      CountryId: this.state.CountryId,
      Name:this.state.Name,
      Code:this.state.Code      
    }
    api.postData('/Api/Countries/AddorUpdateCountry', Country)
    .then(res => {
      // if(this.state.CountryId === 0)
      //   (async () => await infoWrapper("New Country Added Successfully."))();
      // else
      //   (async () => await infoWrapper("Data Updated Successfully."))();
        this.props.closeAddorEdit();
    })
  }
  changeName = (e)=>{
    if(e.target.value.length <= 100){
      this.setState({ Name : e.target.value})
    }else{
      (async () => infoWrapper("The maximum characters should not exceed more than 100."))();
    }
  }
  changeCode = (e)=>{
    if(e.target.value.length <= 15){
      this.setState({ Code : e.target.value})
    }else{
      (async () => infoWrapper("The maximum characters should not exceed more than 15."))();
    }
  }
  onCancel(e){
    e.preventDefault()
    this.props.closeAddorEdit();
  }
  render() {
    const {Action, Name, Code} = this.state;
    return (
      <Fragment>
        <div className="row d-flex justify-content-between card-header align-items-center">
            <h5 className="">{Action} Country</h5>
        </div>
        <div className="mb-5" data-aos="fade-up" data-aos-duration="2000">
          <form action="" method="post" onSubmit={this.onSubmit}>
            <div className="my-4" data-aos="fade-up" data-aos-duration="2000">
              <label htmlFor="Country-code" className="h6">Country Code</label>
              <input className="form-control round"
                id="Country-code"
                value={Code}
                onChange={this.changeCode}
                type="text" required />
            </div>
            <div className="my-4" data-aos="fade-up" data-aos-duration="2000">
              <label htmlFor="Country-name" className="h6">Country Name</label>
              <input className="form-control round"
                id="Country-name"
                value={Name}
                onChange={this.changeName}
                type="text" required />
            </div>
            <div className="my-2">
              <button type="submit" className="btn btn-default btn-md" onClick={this.onSubmit}>{Action}</button>
              <button type="reset" className="btn btn-default btn-md" onClick = {this.onCancel}>Cancel</button>
            </div>
          </form>
        </div>
      </Fragment>
    )
  }
}

const TableRow = (props)=>{
  const rowID = "Row_" + props.obj.countryId;

  const DeleteCountry = async () => {
    if (await confirmWrapper('Are you sure that you want to delete the record ?')){
      api.deleteData('api/Countries/DeleteCountry?id=' + props.obj.countryId)
      .then(res => {
        if (res.data.status === 'Delete') {
          // (async () => infoWrapper('Record deleted successfully!!'))();
          document.getElementById("Row_" + props.obj.countryId).remove();
        }
      })
    }
  };
  const EditCountry = (e)=>{
    e.preventDefault();
    props.forEdit(props.obj.countryId)
  };
  return (
    <tr id={rowID}>
      <td>
        {props.obj.code}
      </td>
      <td>
        {props.obj.name}
      </td>
      <td>
        <button title="Edit" type="button" onClick={EditCountry} className="btn-floating btn-sm btn-default m-1">
          <i className="fas fa-pen text-white"></i>
        </button>
        <button title="Delete" type="button" onClick={DeleteCountry} className="btn-floating btn-sm btn-danger m-1">
          <i className="fas fa-trash text-white"></i>
        </button>
      </td>
    </tr>
  ); 
};

class CountriesList extends Component {
  static DisplayName = CountriesList.name
  constructor(props){
    super(props)
    this.state = { Countrys: []}
  }
  componentDidMount(){
    //Get('api/Countries/CountriesList')
    api.fetchData('api/Countries/CountriesList')
    .then(response => {
      let data = response.data;
      this.setState({ Countrys: data });
      $('#dtCountriesList').DataTable();
      $('.dataTables_length').addClass('bs-select');
    })  
    .catch(function (error) {  
      console.log(error);  
    })
  }
  onAddCountry = ()=>{
    this.props.forAddorEdit(0)
  }
  forEditCountry = (id)=>{
    this.props.forAddorEdit(id)
  }

  render() {
    const Countrieslist = this.state.Countrys.map(Country => <TableRow obj={Country} key={Country.countryId} forEdit={this.forEditCountry}/>)
    return (
      <Fragment>
        <div className="row d-flex justify-content-between card-header align-items-center">
          <h5 className="">Countries</h5>
            <button className="btn btn-default btn-md btn-rounded" onClick={this.onAddCountry}>Add Country</button>
        </div>
        { this.state.Countrys.length >0 ?
          <div className="row">
            <div className="col-sm-12" className="table-responsive">
              <table id="dtCountriesList" className="table table-striped table-bordered table-sm" cellSpacing="0" width="100%" role="grid" aria-describedby="dtCountriesList_info" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th className="th-sm">Country Code</th>
                    <th className="th-sm">Country Name</th>
                    <th className="th-sm">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Countrieslist}
                </tbody>
              </table>
            </div>
          </div>:<p>No Data found to Display</p>
        }
      </Fragment>
    )
  }
}

const Country=()=> {
  const [isAddorEdit, setIsAddorEdit] = useState(false);
  const [addorEditId, setAddorEditId] = useState(0);

  const onAddOrEdit = (id)=>{
    setIsAddorEdit(true)
    setAddorEditId(id)
  };
  const forList = ()=>{
    setIsAddorEdit(false)
  }

  return (
    <Fragment>
      { !isAddorEdit && <CountriesList forAddorEdit={onAddOrEdit}/> }
      { isAddorEdit && <AddorUpdateCountry id={addorEditId} closeAddorEdit={forList}/>}
    </Fragment>
  )
};
export default Country;