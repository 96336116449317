import authService from '../api-authorization/AuthorizeService'
import axios from 'axios'

export function getApiHeaders() {

    return new Promise((resolve, reject) => {
        authService.getAccessToken().then(response => {
            resolve({ headers: { 'Authorization': `Bearer ${response}` } })
        })
            .catch(error => {
                reject({ headers: "sdf" })
            });
    });
}

export function fetchData(uri) {
    return new Promise((resolve, reject) => {
        axios.get(uri, {
            withCredentials: true            
        })
        .then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

export function postData(uri, data) {
    return new Promise((resolve, reject) => {        
            //fetch(uri, options)
        axios.post(uri, data,{
                withCredentials: true
        })
        .then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

export function deleteData(uri) {
    return new Promise((resolve, reject) => {
        axios.delete(uri, {
            withCredentials: true
        })
        .then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}