import React, { useState, useEffect } from 'react';
import * as api from '../API/Api';
import { infoWrapper } from '../InfoAlert';

export const PersonalData = (props) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [profilePic, setProfilePic] = useState();
  const [grade, setGrade] = useState(0);
  const [cbNumber, setCBNumber] = useState('');
  const [schoolName, setSchoolName] = useState(0);
  const [studyYear, setStudyYear] = useState(0);
  const [commPref, setCommPref]  = useState(true);
  const [Apartment, setApartment] = useState('');
  const [Street, setStreet] = useState('');
  const [Town, setTown] = useState('');
  const [State, setState] = useState('0');
  const [vid, setVid] = useState('0');
  const [PINCode, setPINCode] = useState('');
  const [States, setStates] = useState([]);
  const [Countries, setCountries] = useState([]);
  const [Grades, setGrades] = useState([]);
  const [MedSchools, setMedSchools] = useState([]);
  const [Years, setYears] = useState([]);
  

  useEffect(()=>{
    api.fetchData('api/Grades/GradesList')
    .then(response => {
      setGrades(response.data);
    })  
    .catch(error => {  
        console.log(error);  
    });
    api.fetchData('api/MedSchools/MedSchoolsList')
    .then(response => {
      setMedSchools(response.data);
    })  
    .catch(error => {  
      console.log(error);  
    });
    api.fetchData('api/Years/YearsList')
    .then(response => {
      setYears(response.data);
    })  
    .catch(error => {  
      console.log(error);  
    });
    api.fetchData('api/Countries/CountriesListWithStates').then(response => {
      let data = response.data
      let countries =[];
      data.map(country=>{
        let vid = Math.floor((Math.random() * 10000) + 9000);
        countries[vid]={vid:vid, 
          CountryId:country.countryId,
          Name:country.name,
          Code:country.code,
          States:country.states
        }
      })
      setCountries(countries);
      api.fetchData('/Api/UserInfo/GetUserInfo?email=' + props.User)
      .then(res => {
        let data = res.data;
        if(data){
          setFirstName(data.firstName);
          setLastName(data.lastName);
          setGrade(data.gradeId);
          setCBNumber(data.cbNumber);
          setSchoolName(data.medSchoolId);
          setStudyYear(data.yearId);
          setCommPref(data.beNotified);
          setStreet(data.street);
          setApartment(data.apartment);
          setTown(data.town);
          setPINCode(data.pinCode);
          if(data.countryId > 0){
            let country = countries.filter( cn => cn.CountryId == data.countryId);
            setVid(country[0].vid);
            setStates(country[0].States);
            setState(data.stateId);
          }else{
            setVid('0');
            setState('0');
          }
        }
      })
      .catch(err => {
        console.log(err.message);
      });
    }).catch(error=> {console.log(error);});
    
  },[]);
  const changeFN = (e)=>{
    if(e.target.value.length <= 15){
      setFirstName(e.target.value);
    }else{
      (async () => infoWrapper("The maximum characters should not exceed more than 15."))();
    }
  };
  const changeLN = (e)=>{
    if(e.target.value.length <= 15){
      setLastName(e.target.value);
    }else{
      (async () => infoWrapper("The maximum characters should not exceed more than 15."))();
    }
  };
  const changeGrade = (e)=>{ setGrade(+e.target.value)};
  const changeCBNumber = (e)=>{ setCBNumber(e.target.value)};
  const changeSchoolName = (e)=>{ setSchoolName(+e.target.value)};
  const changeStudyYear = (e)=>{ setStudyYear(+e.target.value)};
  const changeCommPref = (e)=>{ setCommPref(e.target.checked)};
  const changeStreet = (e)=>{
    if(e.target.value.length <= 100){
      setStreet(e.target.value);
    }else{
      (async () => infoWrapper("The maximum characters should not exceed more than 100."))();
    }
  };
  const changeApartment = (e)=>{
    if(e.target.value.length <= 100){
      setApartment(e.target.value);
    }else{
      (async () => infoWrapper("The maximum characters should not exceed more than 100."))();
    }
  };
  const changeTown = (e)=>{
    if(e.target.value.length <= 50){
      setTown(e.target.value);
    }else{
      (async () => infoWrapper("The maximum characters should not exceed more than 50."))();
    }
  };
  const changeState = (e)=>{ setState(+e.target.value)};
  const changeCountry = (e)=>{ 
    if(+e.target.value > 0)
    { let country = Countries[+e.target.value];
      setVid(e.target.value);
      setStates(country.States);
      setState('0');
    }
    else{
      setVid('0');
      setStates([]);
      setState('0');
    }
  };
  const changePINCode = (e)=>{ setPINCode(e.target.value)};
  const CountriesList = Countries.map(obj => <option key={obj.vid} value={obj.vid}>{obj.Name}</option>)
  const StatesList = States.map(obj => <option key={obj.stateId} value={obj.stateId}>{obj.name}</option>)
  const changePic = (e)=>{
    var picture = e.target.files[0];
    if(picture.size <25000){ 
      var picture = e.target.files[0];
      getBase64(picture, (result) => {
        setProfilePic(result);
      });
    }
    else{
      (async () => await infoWrapper("The file size not more than 20MB"))();
    }
  };
  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
      (async () => await infoWrapper("The Given file is not valid. Please choose another!"))();
    };
  };
  const ValidateForm = () =>{
    let isValid = true;
    if(firstName === ''){
      (async () => await infoWrapper("Please enter First Name!"))();
      isValid = false;
    }
    else if(lastName === ''){
      (async () => await infoWrapper("Please enter Last Name!"))();
      isValid = false;
    }
    else if(grade === 0){
      (async () => await infoWrapper("Please Choose the Grade!"))();
      isValid = false;
    }
    else if(schoolName === 0){
      (async () => await infoWrapper("Please Choose the Medical School!"))();
      isValid = false;
    }
    else if(studyYear === 0){
      (async () => await infoWrapper("Please Choose the Currently studying year!"))();
      isValid = false;
    }
    else if(Street === ''){
      (async () => await infoWrapper("Please enter Street Name!"))();
      isValid = false;
    }
    else if(Apartment === ''){
      (async () => await infoWrapper("Please enter Apartment or suite Name!"))();
      isValid = false;
    }
    else if(Town === ''){
      (async () => await infoWrapper("Please enter Town Name!"))();
      isValid = false;
    }
    else if(State === '0' || State === undefined){
      (async () => await infoWrapper("Please select respective state from the countries!"))();
      isValid = false;
    }
    else if(PINCode === ''){
      (async () => await infoWrapper("Please enter PIN Code!"))();
      isValid = false;
    }
    return isValid;
  };
  const handleSubmit = (e)=>{
    e.preventDefault();
    if(ValidateForm()){
      const formData = {
        Email: props.User,
        FirstName: firstName,
        LastName: lastName,
        GradeId: grade,
        CBNumber: cbNumber,
        MedSchoolId: schoolName,
        YearId: studyYear,
        Street: Street,
        Apartment: Apartment,
        Town: Town,
        StateId: State,
        PINCode: PINCode,
        BeNotified: commPref,
      };
      api.postData('/Api/UserInfo/AddorUpdateUserInfo', formData)
      .then(res => {
        (async () => await infoWrapper(res.data.message))();
      })
      .catch(err => {
        console.log(err.message);
      });
    }
  };

  return (
    <section className="mb-5" data-aos="fade-up" data-aos-duration="2000">
      <div className="container">
        <div className="my-3 d-flex justify-content-between">
          <h4 className="text-default">Personal Details</h4>
          {/* <p className="mr-5 h5">{props.User}</p> */}
        </div>
        <form method="post" onSubmit={handleSubmit}>
          <div className="card my-2">
            < div className="card-header">
              Basic Information
            </div>
            <div className="card-body">
          <div className="row choose-row mt-4">
            <div className="col-md-6 my-1" data-aos="fade-up" data-aos-duration="2000">
              <div className="form-group">
                <label htmlFor="formGroupExampleInput" className="mb-2">First Name</label>
                <input 
                  type="text"
                  className="form-control" 
                  id="formGroupExampleInput" 
                  placeholder="Enter First Name"
                  value={firstName}
                  onChange={changeFN}
                  name="FirstName" />
                 </div>
            </div>
            <div className="col-md-6 my-1" data-aos="fade-up" data-aos-duration="2000">
              <div className="form-group">
                <label htmlFor="formGroupExampleInput" className="mb-2">Last Name</label>
                <input 
                  type="text" 
                  className="form-control" 
                  id="formGroupExampleInput" 
                  placeholder="Enter Last Name"
                  value={lastName}
                  onChange={changeLN}
                  name="LastName"
                   />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 my-1" data-aos="fade-up" data-aos-duration="2000">
              <label htmlFor="exampleForm2" className="mb-2">Grade</label>
               <select className="browser-default custom-select" name="Grade" value={grade} onChange={changeGrade}>
                <option value="">Please select</option>
                {Grades.map(grade => <option key={grade.code} value={grade.gradeId}>{grade.name}</option>)}
              </select>
            </div>
            <div className="col-md-6 my-1" data-aos="fade-up" data-aos-duration="2000">
              <div className="form-group">
                <label htmlFor="formGroupExampleInput" className="mb-2">Certification Body Nnumber</label>
                <input 
                                          type="text"
                                          className="form-control"
                                          id="formGroupExampleInput"
                                          placeholder="Certification Body Nnumber"
                                          value={cbNumber}
                                          onChange={changeCBNumber}
                                          name="CbNumber" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 my-1" data-aos="fade-up" data-aos-duration="2000">
              <label htmlFor="exampleForm2" className="mb-2">Medical School</label>
                <select className="browser-default custom-select" name="Schoolname" value={schoolName} onChange={changeSchoolName}>
                <option value="">Please select</option>
                {MedSchools.map(medSchool => <option key={medSchool.code} value={medSchool.medSchoolId}>{medSchool.name}</option>)}
              </select>
            </div>
            <div className="col-md-6 my-1" data-aos="fade-up" data-aos-duration="2000">
              <label htmlFor="exampleForm2" className="mb-2">Currently Studying Year</label>
               <select className="browser-default custom-select" name="Stdyear" value={studyYear} onChange={changeStudyYear}>
                <option value="">Please select</option>
                {Years.map(year => <option key={year.code} value={year.yearId}>{year.name}</option>)}
              </select>
            </div>
          </div>
          </div>
          </div>
          <div className="card my-2">
            <div className="card-header">
              Address For Communication
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-4 col-md-12 mb-4" data-aos="fade-up" data-aos-duration="2000">
                  <label htmlFor="address" className="">Street</label>
                   <input type="text"
                    id="address"
                    className="form-control mb-4"
                    placeholder="1234 Main St..."
                    value={Street}
                    onChange={changeStreet}
                    />
                </div>
                <div className="col-lg-4 col-md-6 mb-4" data-aos="fade-up" data-aos-duration="2000">
                  <label htmlFor="address-2" className="">Apartment or suite</label>
                  <input type="text"
                   id="address-2"
                   className="form-control mb-4"
                   placeholder="Apartment, Suite, etc."
                   value={Apartment}
                   onChange={changeApartment}
                   />
                </div>
                <div className="col-lg-4 col-md-6 mb-4" data-aos="fade-up" data-aos-duration="2000">
                  <label htmlFor="address-3" className="">Suburb/Town</label>
                  <input type="text"
                  id="address-3"
                  className="form-control mb-4"
                  placeholder="Suburb/Town"
                  value={Town}
                  onChange={changeTown}/>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-12 mb-4" data-aos="fade-up" data-aos-duration="2000">
                  <label htmlFor="country">Country/Region</label>
                   <select className="custom-select d-block w-100" id="country" value={vid} onChange={changeCountry}>
                    <option value="0">Choose Country...</option>
                    {CountriesList}
                  </select>
                </div>
                <div className="col-lg-4 col-md-6 mb-4" data-aos="fade-up" data-aos-duration="2000">
                  <label htmlFor="state">State/territory</label>
                   <select className="custom-select d-block w-100" id="state" value={State} onChange={changeState}>
                    <option value="0">Choose State...</option>
                    {StatesList}
                  </select>
                </div>
                <div className="col-lg-4 col-md-6 mb-4" data-aos="fade-up" data-aos-duration="2000">
                  <label htmlFor="zip">Zip</label>
                  <input type="text" className="form-control" id="zip" 
                  placeholder="zip code" 
                  value={PINCode}
                  onChange={changePINCode}
                  type="number" min="0" step="1"/>
                </div>
              </div>
              <div className="row" data-aos="fade-up" data-aos-duration="2000">
                <div className="col-lg-12 mb-4">
                  <p className="text-dark">Communication Preferences</p>
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" 
                      className="custom-control-input" 
                      id="defaultUnchecked"
                      name="commPrefer"
                      checked={commPref} 
                      onChange={changeCommPref}
                      />
                    <label className="custom-control-label" htmlFor="defaultUnchecked">
                      Please check to receive information and special offers about PracMed products and services through email/phone/sms/post.
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center my-2">
            <button type="submit" className="btn btn-default btn-rounded">Save changes</button>
          </div>
        </form>
      </div>
    </section>
  )
};
export default PersonalData;