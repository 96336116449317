import React, { useState, useEffect, useContext, Fragment } from 'react';
import AuthContext from '../store/auth-context';
import * as api from '../API/Api';
import { Link } from 'react-router-dom';
import RegistrationForm from './RegistrationForm';
import AddressForm from './AddressForm';

export const InformationCollect  = () => {
  const AuthCtx = useContext(AuthContext);
  const [userState, setUserState] = useState('NotUser');
  const [Email, setEmail] = useState('');
  const [ isRegistered, setIsRegistered] = useState(false);
  const [isAddressAvailable, setIsAddressAvailable] = useState(false);
  const [AddressesList, setAddressesList] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState('');
  const [CartItems, setCartItems] = useState([]);
  const [TotalCost, setTotalCost] = useState(0);
  
  useEffect(() => {
    ForCartItems();
    if(AuthCtx.isLoggedIn){
      setEmail(AuthCtx.UserName);
      setIsRegistered(true);
      setUserState('OldUser');
    }else{
      setUserState('NotUser');
    }
  }, [AuthCtx.isLoggedIn, AuthCtx.UserName]);
  useEffect(() => {
    if(Email !== ''){
      ForAddressList()
    }
    else{
      setUserState('NotUser');
    }
  }, [Email, isAddressAvailable ]);

  const ForCartItems = ()=>{
    api.fetchData('/Api/CartItems/PayingForExamsByUserName?user='+AuthCtx.UserName)
    .then( res =>{
      console.log(res.data)
      let Cost = 0;
      res.data.map((item, index) => {
        Cost += item.cost;
      });
      setCartItems(res.data);
      setTotalCost(Cost);
    })
    .catch(err=>{
      console.log(err);
    });
  };
  
  const ForAddressList = () =>{
    api.fetchData('/Api/UserInfo/UserAddressesListByUser?user='+Email)
      .then(res=>{
        console.log(res.data);
        setAddressesList(res.data);
        if(res.data.length > 0){
          let [add] = res.data;
          setIsAddressAvailable(true);
          setSelectedAddressId(add.email);
          setUserState('Success');
        }else{
          setUserState('ForAddress');
        }
      })
      .catch(err=>{console.log(err);});
  };
  const onRegister = (email) =>{
    setIsRegistered(true);
    setEmail(email);
  };
  const onNewAddress = () =>{
    setIsAddressAvailable(true);
  };

  return (
    <section className="banner-area" data-aos="fade-up" data-aos-duration="2000">
      <hr/>
      <div className="container">
        <div className="my-3 d-flex justify-content-between">
          { userState === 'NotUser' && <h4 className="text-default">Register</h4>}
          { userState === 'ForAddress' && <h4 className="text-default">Billing Information</h4>}
          { AddressesList.length > 0 && <h4 className="text-default">User Information</h4>}
        </div>
        <div className="row my-3">
          <div className="col-md-8">
            { userState !== 'NotUser' && <div className="card">
              <div className="card-body">
                <Fragment>
                  { AuthCtx.isLoggedIn && <p className="mr-5 h5"><b>Email:</b> {AuthCtx.UserName}</p> }
                  { !AuthCtx.isLoggedIn && userState !== 'NotUser' && <p className="mr-5 h5"><b>Email:</b> {Email}</p>}
                </Fragment>
              </div>
            </div>}
            { userState !== 'NotUser' && <br/>}
            { userState === 'NotUser' && <RegistrationForm onRegister={onRegister}/>}
            { userState === 'ForAddress' && <AddressForm email = {Email} onNewAddress={onNewAddress}/>}
            { AddressesList.length > 0 && 
              <div className="card">
                <div className="card-body">
                  { AddressesList.map((address, index)=>{
                    return <div key={index}>
                    <p><b>{address.firstName} &nbsp; {address.lastName}</b><br/>
                    {address.street}<br/> {address.apartment}<br/>
                    {address.town}<br/> {address.state}<br/> {address.country} &nbsp; {address.pinCode}.</p>
                  </div>})}
                  <hr/>
                  <div className="mb-1 mx-3">
                    <input 
                    type="checkbox" 
                    className="form-check-input filled-in" 
                    id="chekboxRules"
                    checked={true} readOnly/>
                    <label className="form-check-label" htmlFor="chekboxRules">
                      The given information is true and I accept the terms and conditions of PracMed.
                    </label>
                  </div>
                </div>
              </div>
            }
          </div>
          <div className="col-lg-4 mb-4">
            <div className="card border border-light rounded-0">
              <div className="card-body">
                <h3 className="mb-4 mt-1 h5 text-center font-weight-bold">Summary</h3>
                <hr/>
                {CartItems.map((item, index) =><Fragment key= {index}>
                    <dl className="row">
                      <dd className="col-sm-8">
                        {item.examName}
                      </dd>
                      <dd className="col-sm-4">
                        ${item.cost}
                      </dd>
                      <dd className="col-sm-8">
                        {item.durationName} ({item.days} Days)
                      </dd>
                    </dl>
                    <hr/>
                  </Fragment>)
                }
                <dl className="row">
                  <dt className="col-sm-8">
                    Total
                  </dt>
                  <dt className="col-sm-4">
                    ${TotalCost}
                  </dt>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div className="buttn-align">
          <br/>
          {isRegistered && selectedAddressId !== '' && <Link className="btn btn-default btn-rounded"
            to={{pathname:'/Payment-page', TotalCost: TotalCost, userName : Email, AddressId: selectedAddressId, CartItems: CartItems }} >
              Continue To Payment
            </Link>
          }
        </div>
      </div>
    </section>
  )
};

export default InformationCollect;