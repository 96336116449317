import React, { Component, useState, useContext, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import LoginNavigation from './AccountManagement/LoginNavigation';
import * as api from './API/Api';
import AuthContext from './store/auth-context';
import { FormDialogNoTitle } from './FormDialogNoTitle';

export const HeaderSection = (props) => {
    const [showExams, setShowExams] = useState(false);
    const [ShowPopup, setShowPopup] = useState(false);
    const [ShowPopup1, setShowPopup1] = useState(false);
    const authCtx = useContext(AuthContext);

    const showExamsHandler = () => {
        setShowExams(true);
    }
    const hideExamsHandler = () => {
        setShowExams(false);
    }
    const handleShowPopup = () => {
        setShowPopup(true);
    }
    const handleShowPopup1 = () => {
        setShowPopup1(true);
    }
    const handleClosePopup = () => {
        setShowPopup(false);
        setShowPopup1(false);
    }
    const onLogut = (e) => {
        e.preventDefault();
        authCtx.logout();
    }

    return (
        <section className="header-area">
            <div className="container">
                <div className="header-main">
                    {/* <!-- logo --> */}
                    <div className="logo">
                        <NavLink title="PracMed Home" tag={Link} to="/home"><img src="assets/images/logo.png" alt="img" /></NavLink>
                    </div>
                    {/* <!-- menu --> */}
                    <div className="menu">
                        <a href="#" className="nav-icon"><i className="fa fa-bars"></i></a>
                        <ul>
                            <li><NavLink title="Home" tag={Link} to="/home">Home</NavLink></li>
                            <li onMouseOver={showExamsHandler}
                                className="active"
                                onMouseLeave={hideExamsHandler}
                            >
                            <a data-scroll-nav="2" title="Popular Exams" className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">Exam</a>
                            <DisplayExams closeExamsview={hideExamsHandler} />
                            </li>
                            <li><NavLink title="Features" tag={Link} to="/features">Features</NavLink></li>
                            <li><NavLink title="About Us" tag={Link} to="/aboutus">About Us</NavLink></li>
                            {/* <!-- mobile --> */}
                            {!authCtx.isLoggedIn &&
                                <div className="mobile sign-btn">
                                    <div className="text-center">
                                        <a href="" className="btn btn-default btn-rounded" data-toggle="modal" data-target="#modalLoginForm" onClick={handleShowPopup}>Login</a>
                                        {ShowPopup ?
                                            <FormDialogNoTitle open={ShowPopup} closeDialog={handleClosePopup}>
                                                <LoginNavigation forClose={handleClosePopup} />
                                            </FormDialogNoTitle> : <Fragment />
                                        }
                                    </div>
                                </div>
                            }
                            {authCtx.isLoggedIn && <Fragment>
                                <div className="mobile sign-btn">
                                    <div className="text-center">
                                        <div className="">
                                            <NavLink className="btn btn-default btn-rounded user-icon" title={authCtx.UserName} tag={Link} to="/"><i className="fas fa-user-tie"></i></NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="mobile sign-btn">
                                    <div className="text-center">
                                        <div className="">
                                            <a href="#" className="btn btn-default btn-rounded" onClick={onLogut}>Logout</a>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                            }
                        </ul>
                    </div>
                    <div className="sign-up-menu">
                        <div className="sign-img">
                            <NavLink title="Exams Added To Cart" tag={Link} to="/cart-items"><img src="assets/images/cey.png" alt="img" /></NavLink>
                            {authCtx.CartCount > 0 && <span>{authCtx.CartCount}</span>}
                        </div>
                        {/* <!-- desktop --> */}
                        {!authCtx.isLoggedIn &&
                            <div className="sign-btn">
                                <div className="text-center">
                                    <a href="" className="btn btn-default btn-rounded" data-toggle="modal" data-target="#modalLoginForm" onClick={handleShowPopup1}>Login</a>
                                    {ShowPopup1 ?
                                        <FormDialogNoTitle open={ShowPopup1} closeDialog={handleClosePopup}>
                                            <LoginNavigation forClose={handleClosePopup} />
                                        </FormDialogNoTitle> : <Fragment />
                                    }
                                </div>
                            </div>
                        }
                        {authCtx.isLoggedIn && <Fragment>
                            <div className="sign-btn">
                                <div className="">
                                    <NavLink className="btn btn-default btn-rounded user-icon" title={authCtx.UserName} tag={Link} to="/"><i className="fas fa-user-tie"></i></NavLink>
                                </div>
                            </div>
                            <div className="">
                                <a href="#" className="btn btn-default btn-rounded" onClick={onLogut}>Logout</a>
                            </div>
                        </Fragment>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

export class DisplayExams extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ActiveExams: []
        }
    }
    componentDidMount() {
        api.fetchData('/Api/Exams/ActiveExamsList')
            .then(res => {
                this.setState({ ActiveExams: res.data })
            })
            .catch(err => {
                console.log(err)
            })
    }
    render() {
        const { ActiveExams } = this.state
        return (
            <div onMouseLeave={this.props.closeExamsview} className="dropdown-menu dropdown-primary">
                {ActiveExams.length > 0 ?
                    ActiveExams.map((item, index) => {
                        let slNo = index + 1;
                        return <Link className="dropdown-item" key={slNo} to={{ pathname: '/exam-duration', examId: item.examID }}>{item.examName}</Link>
                    })
                    : <Fragment />}
            </div>
        )
    }
}
    // <section className="header-area">
    //     {/*========================================================================================
    //         Start header area
    //         =========================================================================================*/}
    //     <div className="container">
    //         <div className="header-main">
    //             {/* logo */}
    //             <div className="logo">
    //                 <NavLink title="PracMed Home" tag={Link} to="/home"><img src="assets/images/logo.png" alt="img" /></NavLink>
    //             </div>
    //             {/* menu */}
    //             <div className="menu">
    //                 <a className="nav-icon" ><i className="fa fa-bars"></i></a>
    //                 <ul>
    //                 <li><NavLink title="Home" tag={Link} to="/home">Home</NavLink></li> 
    //                 <li 
    //                 onMouseOver={showExamsHandler} 
    //                 className="active"
    //                 // onMouseLeave={hideExamsHandler}
    //                 >
    //                     <a data-scroll-nav="2" title="Popular Exams">Exams</a>
    //                     {showExams?<DisplayExams closeExamsview={hideExamsHandler}/>:null}
    //                 </li>
    //                 <li><NavLink title="Features" tag={Link} to="/features">Features</NavLink></li>
    //                 <li><NavLink title="About Us" tag={Link} to="/aboutus">About Us</NavLink></li>
    //                 {/* mobile */}                             
    //                 <div className="mobile sign-btn">
    //                     <LoginMenu>Loading. Please wait...</LoginMenu>
    //                 </div>
    //                 </ul>
    //             </div>
    //             {/* sign Up */}
    //             <div className="sign-up-menu">
    //                 <div className="sign-img">
    //                     <NavLink title="Exams Added To Cart" tag={Link} to="/cart-items"><img src="assets/images/cey.png" alt="img" /></NavLink>
    //                     { authCtx.CartCount > 0 && <span>{authCtx.CartCount}</span>}
    //                 </div>
    //                 {/* desktop */}                         
    //                 <div className="sign-btn">
    //                     <LoginMenu>Loading. Please wait...</LoginMenu>                         
    //                 </div>
    //             </div>
    //         </div>
    //     </div>            
    // </section>