import React, {useState, useEffect, Fragment} from 'react';
import { infoWrapper } from '../InfoAlert';
import * as api from '../API/Api';

const ChooseNoOfQuestions = (props)=>{
  const [noOfQuestions, setSelectedNoOfQuestins] = useState(0);
  const [hours, setHours] = useState(2);
  const [minutes, setMinutes] = useState(0);
  const [subjects, setSubjects] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [subWiseQuestons, setSubWiseQuestion] = useState(0);
  const [mode, setMode] = useState('0');
  const selectedQuestionsChangeHandle = (e)=>{
    const sq = +e.target.value;
    setSelectedNoOfQuestins(sq);
  };
  const hoursChangeHandle  = (e)=>{
    const hr = +e.target.value;
    setHours(hr);
  };
  const minutesChangeHandle = (e)=>{
    const min = +e.target.value;
    setMinutes(min);
  };
  useEffect(()=>{
    let id = +props.examId;
    api.fetchData('/api/ExamSubjects/ExamSubjectsListByExamID?id='+id)
    .then(res=>{
      let examsubjects = res.data;
      let Subjects = [];
      examsubjects.map((sub, ind) => {
        Subjects[ind] = {vid:ind,
          SubjectId: sub.subjectId,
          SubjectName: sub.subjectName,
          Count: sub.count,
          isSelected: false
        }
      })
      setSubjects(Subjects);
    })
    .catch(err=>{
      console.log(err.data);
    })
  },[props.examId,])
  const onStartExam = (e)=>{
    e.preventDefault();
    var min = minutes + hours * 60;
    if(mode !== ''){
      if(selectedType === 0){
        if(noOfQuestions > 0){            
          props.startExam(noOfQuestions, min, mode);
        }else{
          (async () => infoWrapper('Please Select the No Of Questions!!'))();
        }
      }
      else if(selectedType === 1){
        if(subWiseQuestons > 0){
          props.startExamSubWise(subjects, subWiseQuestons, min, mode);
        }else{
          (async () => infoWrapper('Please Select atleast one subject!!'))();
        }
      }else{
        (async () => infoWrapper('Please Select the Questions!'))();
      }
    }else{
      (async () => infoWrapper('Please Select the mode of Exam!'))();
    }    
  };
  const chooseQuestionType = (e) => {
    setSelectedType(+e.target.value);
  }
  const changeMode = (e) =>{
    setMode(e.target.value);
  }
  const selectSubject = (e) => {
    let vid = +e.target.value;
    let sub= subjects[vid];
    let subjs = subjects;
    let newSub = {
      vid:vid,
      SubjectId: sub.SubjectId,
      SubjectName: sub.SubjectName,
      Count: sub.Count,
      isSelected: !sub.isSelected
    }
    subjs[vid] = newSub;
    setSubjects(subjs);
    let cn = 0;
    subjs.map(s => {
      if(s.isSelected){cn = cn  +s.Count}});
    setSubWiseQuestion(cn);
  }
  return(
    <div className="container">
      <div className="card mb-5">
        <div className="card-header">
          Total No of Questions: {props.total}
        </div>
        <div className="card-body">
          <div className="my-2">
            <div className="card">
              <ul class="nav nav-tabs choose-tabs">
                <li class="nav-item">
                  <button type='button' class={mode === '0'? "nav-link active": "nav-link"} 
                  value='0' onClick={changeMode} >Practice Mode
                  </button>
                </li>
                <li class="nav-item">
                  <button type='button' class={mode === '1'? "nav-link active": "nav-link"} 
                  value='1' onClick={changeMode} >Exam Mode
                  </button>
                </li>
              </ul>
                <ul className="list-group list-group-flush options">
                    <li className="list-group-item mode-exam">
                        Choose Questions
                    </li>
                    <li className="list-group-item">
                      <div className="custom-control custom-radio">
                        <input type="radio" className="custom-control-input" id="All" value='0' name="selected" checked={selectedType === 0} onChange={chooseQuestionType}/>
                        <label htmlFor="All" className="custom-control-label">
                          All Subjects
                        </label>
                      </div>
                    </li>
                    {selectedType === 0 && <li className="list-group-item">
                      Select the no.of Question to take for the Exam: 
                      <select className="" name="sQ" value={noOfQuestions} onChange={selectedQuestionsChangeHandle}>
                        <option value='0'>--Choose no of Questions--</option>
                        {props.total >10 && <option value='10'>10 Questions</option>}
                        {props.total >20 && <option value='20'>20 Questions</option>}
                        {props.total >50 && <option value='50'>50 Questions</option>}
                        {props.total >100 && <option value='100'>100 Questions</option>}
                        {props.total >200 && <option value='200'>20 Questions</option>}
                        <option value={props.total}>{props.total} Questions</option>
                      </select>
                    </li>}
                {/*</ul>*/}
                {/*<ul className="list-group list-group-flush options">*/}
                    <li className="list-group-item">
                        <div class="custom-control custom-radio">
                            <input type='radio' class="custom-control-input" id='subject'
                                name='selected' value='1'
                                checked={selectedType === 1}
                                onChange={chooseQuestionType} />
                            <label class="custom-control-label" htmlFor="subject">Select the Questions By Subjects. {selectedType === 1 && <div>({subWiseQuestons} Questions selected out of {props.total} Questions)</div>}</label>
                        </div>
                    </li>
                    {selectedType === 1 && <li className="list-group-item">
                        <table className="table table-striped table-responsive">
                            <thead>
                                <tr className='text-center'>
                                    <th className="font-weight-bold">Subject</th>
                                    <th className="font-weight-bold">Questions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {subjects.map((s, i) => <tr>
                                    <td>
                                        <input id={i} vid={s.vid} type="checkbox" value={s.vid} subjectid={s.SubjectId} name={s.SubjectName} checked={s.isSelected} onChange={selectSubject} /> <label htmlFor={i}>{s.SubjectName}</label>
                                    </td>
                                    <td className='text-center'>{s.Count}</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </li>}
                </ul>
            </div>
          </div>        
          <div className="my-2">
            <div className="card">
              <ul className="list-group list-group-flush options">
                <li className="list-group-item">
                Choose Time Duration (optional):
                <select className="" name="hr" value={hours} onChange={hoursChangeHandle}>
                  <option value='2'> 2 </option>
                  <option value='1'> 1 </option>
                  <option value='0'> 0 </option>
                </select> Hour(s) :
                <select className="" name="min" value={minutes} onChange={minutesChangeHandle}>
                  <option value='0'>00</option>
                  <option value='1'>01</option>
                  <option value='2'>02</option>
                  <option value='3'>03</option>
                  <option value='4'>04</option>
                  <option value='5'>05</option>
                  <option value='6'>06</option>
                  <option value='7'>07</option>
                  <option value='8'>08</option>
                  <option value='9'>09</option>
                  <option value='10'>10</option>
                  <option value='11'>11</option>
                  <option value='12'>12</option>
                  <option value='13'>13</option>
                  <option value='14'>14</option>
                  <option value='15'>15</option>
                  <option value='16'>16</option>
                  <option value='17'>17</option>
                  <option value='18'>18</option>
                  <option value='19'>19</option>
                  <option value='20'>20</option>
                  <option value='21'>21</option>
                  <option value='22'>22</option>
                  <option value='23'>23</option>
                  <option value='24'>24</option>
                  <option value='25'>25</option>
                  <option value='26'>26</option>
                  <option value='27'>27</option>
                  <option value='28'>28</option>
                  <option value='29'>29</option>
                  <option value='30'>30</option>
                  <option value='31'>31</option>
                  <option value='32'>32</option>
                  <option value='33'>33</option>
                  <option value='34'>34</option>
                  <option value='35'>35</option>
                  <option value='36'>36</option>
                  <option value='37'>37</option>
                  <option value='38'>38</option>
                  <option value='39'>39</option>
                  <option value='40'>40</option>
                  <option value='41'>41</option>
                  <option value='42'>42</option>
                  <option value='43'>43</option>
                  <option value='44'>44</option>
                  <option value='45'>45</option>
                  <option value='46'>46</option>
                  <option value='47'>47</option>
                  <option value='48'>48</option>
                  <option value='49'>49</option>
                  <option value='50'>50</option>
                  <option value='51'>51</option>
                  <option value='52'>52</option>
                  <option value='53'>53</option>
                  <option value='54'>54</option>
                  <option value='55'>55</option>
                  <option value='56'>56</option>
                  <option value='57'>57</option>
                  <option value='58'>58</option>
                  <option value='59'>59</option>
                </select> Minute(s)
                </li>
              </ul>
            </div>
          </div>
          <div className="my-2">
            <button className="btn btn-default btn-md btn-rounded" type="button" onClick={onStartExam}>Start Exam</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseNoOfQuestions;