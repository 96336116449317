import React, { Component, Fragment, useState } from 'react';
import { confirmWrapper } from '../ConfirmAlert';
import { infoWrapper } from '../InfoAlert';
import * as api from '../API/Api';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import $ from 'jquery';

export class AddorUpdateDiscount extends Component {
  static DisplayName = AddorUpdateDiscount.name
  constructor(props){
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.state = {
      Action:'',
      DiscountId:'',
      DiscountCode:'',
      DiscountType:'',
      Value:''
    }
  }
  componentDidMount(){
    const id = this.props.id
    if(id > 0){
      api.fetchData('/Api/Discounts/DiscountdetailsById?id='+id)
      .then(res => {
        let data = res.data
        this.setState({Action: 'Update', DiscountId: data.discountId, DiscountCode: data.discountCode, DiscountType: data.discountType, Value: data.value })
        console.log(data)
      })
    }
    else{
      this.setState({Action: 'Create', DiscountId: 0, DiscountCode: '', DiscountType:'', Value:''})
    }    
  }
  onSubmit(e){
    e.preventDefault()
    const Discount = {
      DiscountId: this.state.DiscountId,
      DiscountCode:this.state.DiscountCode,
      DiscountType:this.state.DiscountType,
      Value: +this.state.Value
    }
    api.postData('/Api/Discounts/AddorUpdateDiscount', Discount)
    .then(res => {
      // if(this.state.DiscountId === 0)
      //   (async () => await infoWrapper("New Discount Added Successfully."))();
      // else
      //   (async () => await infoWrapper("Data Updated Successfully."))();
        this.props.closeAddorEdit();
    })
  }
  changeDiscountCode = (e)=>{
    if(e.target.value.length <= 20){
      this.setState({ DiscountCode : e.target.value.toUpperCase()})
    }else{
      (async () => infoWrapper("The maximum characters should not exceed more than 20."))();
    }
  }
  changeDiscountType = (e)=>{
    if(e.target.value.length <= 50){
      this.setState({ DiscountType : e.target.value})
    }else{
      (async () => infoWrapper("The maximum characters should not exceed more than 50."))();
    }
  }
  changeValue = (e)=>{
    if(e.target.value === '' || (e.target.value > 0 && e.target.value <= 100)){
      this.setState({ Value : e.target.value})
    }else{
      (async () => infoWrapper("The value must between 0 To 100."))();
    }
  }
  onCancel(e){
    e.preventDefault()
    this.props.closeAddorEdit();
  }
  render() {
    const {Action, DiscountCode, DiscountType, Value} = this.state;
    return (
      <Fragment>
        <div className="row d-flex justify-content-between card-header align-items-center">
            <h5 className="">{Action} Discount</h5>
        </div>
        <div className="mb-5" data-aos="fade-up" data-aos-duration="2000">
          <form action="" method="post" onSubmit={this.onSubmit}>
            <div className="my-4" data-aos="fade-up" data-aos-duration="2000">
              <label htmlFor="Discount-code" className="h6">Discount Code</label>
              <input className="form-control round"
                id="Discount-code"
                value={DiscountCode}
                onChange={this.changeDiscountCode}
                type="text" required />
            </div>
            <div className="my-2" data-aos="fade-up" data-aos-duration="2000">
              <label htmlFor="DiscountType" className="h6">Discount Type</label>
              <select name="DiscountType" className="custom-select" value={DiscountType} onChange={this.changeDiscountType}>
                <option value="">Please Select Discount Type...</option>
                <option value="Percentage">Percentage</option>
                <option value="Amount">Fixed Value</option>
              </select>
            </div>
            <div className="my-4" data-aos="fade-up" data-aos-duration="2000">
              <label htmlFor="Discount-value" className="h6">Discount Value</label>
              <input className="form-control round"
                id="Discount-value"
                value={Value}
                onChange={this.changeValue}
                type="number" min={0} max={100} required />
            </div>
            <div className="my-2">
              <button type="submit" className="btn btn-default btn-md" onClick={this.onSubmit}>{Action}</button>
              <button type="reset" className="btn btn-default btn-md" onClick = {this.onCancel}>Cancel</button>
            </div>
          </form>
        </div>
      </Fragment>
    )
  }
}

const TableRow = (props)=>{
  const rowID = "Row_" + props.obj.discountId;

  const DeleteDiscount = async () => {
    if (await confirmWrapper('Are you sure that you want to delete the record ?')){
      api.deleteData('api/Discounts/DeleteDiscount?id=' + props.obj.discountId)
      .then(res => {
        if (res.data.status === 'Delete') {
          // (async () => infoWrapper('Record deleted successfully!!'))();
          document.getElementById("Row_" + props.obj.discountId).remove();
        }
      })
    }
  };
  const EditDiscount = (e)=>{
    e.preventDefault();
    props.forEdit(props.obj.discountId)
  };
  return (
    <tr id={rowID}>
      <td>
        {props.obj.discountCode}
      </td>
      <td>
        {props.obj.discountType}
      </td>
      <td>
        {props.obj.discountType == "Percentage" ? props.obj.value + "%" : " $ " + props.obj.value}
      </td>
      <td>
        <button title="Edit" type="button" onClick={EditDiscount} className="btn-floating btn-sm btn-default m-1">
          <i className="fas fa-pen text-white"></i>
        </button>
        <button title="Delete" type="button" onClick={DeleteDiscount} className="btn-floating btn-sm btn-danger m-1">
          <i className="fas fa-trash text-white"></i>
        </button>
      </td>
    </tr>
  ); 
};

class DiscountsList extends Component {
  static DisplayName = DiscountsList.name
  constructor(props){
    super(props)
    this.state = { Discounts: []}
  }
  componentDidMount(){
    //Get('api/Discounts/DiscountsList')
    api.fetchData('api/Discounts/DiscountsList')
    .then(response => {
      let data = response.data;
      this.setState({ Discounts: data });
      $('#dtDiscountsList').DataTable();
      $('.dataTables_length').addClass('bs-select');
    })  
    .catch(function (error) {  
      console.log(error);  
    })
  }
  onAddDiscount = ()=>{
    this.props.forAddorEdit(0)
  }
  forEditDiscount = (id)=>{
    this.props.forAddorEdit(id)
  }

  render() {
    const Discountslist = this.state.Discounts.map(Discount => <TableRow obj={Discount} key={Discount.DiscountId} forEdit={this.forEditDiscount}/>)
    return (
      <Fragment>
        <div className="row d-flex justify-content-between card-header align-items-center">
          <h5 className="">Discounts</h5>
            <button className="btn btn-default btn-md btn-rounded" onClick={this.onAddDiscount}>Add Discount</button>
        </div>
        { this.state.Discounts.length >0 ?
          <div className="row">
            <div className="col-sm-12 table-responsive">
              <table id="dtDiscountsList" className="table table-striped table-bordered table-sm" cellSpacing="0" width="100%" role="grid" aria-describedby="dtDiscountsList_info" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th className="th-sm">Discount Code</th>
                    <th className="th-sm">Discount Type</th>
                    <th className="th-sm">Discount Value</th>
                    <th className="th-sm">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Discountslist}
                </tbody>
              </table>
            </div>
          </div>:<p>No Data found to Display</p>
        }
      </Fragment>
    )
  }
}

const Discount=()=> {
  const [isAddorEdit, setIsAddorEdit] = useState(false);
  const [addorEditId, setAddorEditId] = useState(0);

  const onAddOrEdit = (id)=>{
    setIsAddorEdit(true)
    setAddorEditId(id)
  };
  const forList = ()=>{
    setIsAddorEdit(false)
  }

  return (
    <Fragment>
      { !isAddorEdit && <DiscountsList forAddorEdit={onAddOrEdit}/> }
      { isAddorEdit && <AddorUpdateDiscount id={addorEditId} closeAddorEdit={forList}/>}
    </Fragment>
  )
};
export default Discount;