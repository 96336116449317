import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class PopulerExamSection extends Component {
    static displayName = PopulerExamSection.name;  
    constructor(props) {
        super(props)    
        this.state = {
            PopulerExams:[],
            item1: '',
            item2: '',
            item3: '',
            item4: ''
        }
    }
    componentDidMount(){
        axios.get('/Api/Exams/AllExamsWithLowPrice')
        .then(res=> {
            let [i1, i2, i3, i4] = res.data
            this.setState({PopulerExams : res.data, item1: i1, item2: i2, item3: i3,item4: i4})
        })
        .catch(err=>{
            console.log(err)
        })
    }

    render () {
        const {item1, item2, item3, item4} = this.state
        return (
            <section className="populer-exam-area" data-scroll-index="2" data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="populer-heading" data-aos="fade-up" data-aos-duration="2000">
                        <h3>Popular Exams</h3>
                        <p>Choose from our wide range of exam resources</p>
                    </div>
                </div>
            </div>
                <div className="row">
                    {/* {this.state.PopulerExams.map((item,index)=> <SingleItem Item = {item} key={index}/>)} */}
                    {item1 && <SingleItem Item = {item1} image = 'assets/images/b1.png'/>}
                    {item2 && <SingleItem Item = {item2} image = 'assets/images/b2.png'/>}
                    {item3 && <SingleItem Item = {item3} image = 'assets/images/b3.png'/>}
                    {item4 && <SingleItem Item = {item4} image = 'assets/images/b4.png'/>}
                </div>
            </div>
            </section>
        );
    }
}

const SingleItem = (props)=>{
    return(
        <div className="col-md-6 col-lg-3 populer-single" data-aos="fade-up" data-aos-duration="2000">
            <div className="single-populer-main">
                <img src={props.image} alt="img" />
                <div className="ext-aud">
                    <h2>{props.Item.examName}<span>From ${props.Item.cost}</span></h2>
                    <p>{props.Item.examDesc} Lorem Ipsum is simply dummy...</p>
                    <div className="populer-btn">
                        <Link to={{pathname:'/exam-duration', examId: props.Item.examID, image: props.image}}>Buy Now</Link>
                    </div>
                    <div className="populer-btn">
                        <Link to={{pathname:'/exams-demo', examId: props.Item.examID, examName: props.Item.examName}}>Take Demo</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

// {/* single item */}
// <div className="col-md-6 col-lg-3 populer-single" data-aos="fade-up" data-aos-duration="2000">
// <div className="single-populer-main">
//     <img src="assets/images/b1.png" alt="img" />
//     <div className="ext-aud">
//         <h2>MRCGP (AKT)<span>From $54.99</span></h2>
//         <p>Lorem Ipsum is simply dummy...</p>
//         <div className="populer-btn">
//             <a href="#">Buy Now</a>
//         </div>
//     </div>
// </div>
// </div>
// {/* single item */}
// <div className="col-md-6 col-lg-3 populer-single" data-aos="fade-up" data-aos-duration="2000">
// <div className="single-populer-main">
//     <img src="assets/images/b2.png" alt="img" />
//     <div className="ext-aud">
//         <h2>MRCGP (AKT)<span>From $54.99</span></h2>
//         <p>Lorem Ipsum is simply dummy...</p>
//         <div className="populer-btn">
//             <a href="#">Buy Now</a>
//         </div>
//     </div>
// </div>
// </div>
// {/* single item */}
// <div className="col-md-6 col-lg-3 populer-single" data-aos="fade-up" data-aos-duration="2000">
// <div className="single-populer-main">
//     <img src="assets/images/b3.png" alt="img" />
//     <div className="ext-aud">
//         <h2>MRCGP (AKT)<span>From $54.99</span></h2>
//         <p>Lorem Ipsum is simply dummy...</p>
//         <div className="populer-btn">
//             <a href="#">Buy Now</a>
//         </div>
//     </div>
// </div>
// </div>
// {/* single item */}
// <div className="col-md-6 col-lg-3 populer-single" data-aos="fade-up" data-aos-duration="2000">
// <div className="single-populer-main">
//     <img src="assets/images/b4.png" alt="img" />
//     <div className="ext-aud">
//         <h2>MRCGP (AKT)<span>From $54.99</span></h2>
//         <p>Lorem Ipsum is simply dummy...</p>
//         <div className="populer-btn">
//             <a href="#">Buy Now</a>
//         </div>
//     </div>
// </div>
// </div>