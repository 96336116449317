import React, { Component, Fragment } from 'react'
import { NavLink, Link } from 'react-router-dom';

export class TermsAndConditions extends Component {
  static DisplayName = TermsAndConditions.name;

  render() {
    return (
      <Fragment>
        <section className="content-area" data-aos="fade-up" data-aos-duration="2000">
          <h1 className="text-center pt-5 text-default h1">Terms &amp; Conditions</h1>
        </section>
        <main data-aos="fade-up" data-aos-duration="2000" className="container">
          <p>Please read these Terms and conditions of use (Terms) carefully before 
            using the website at www.practicemedicine.com.au/ (PracMed Website)
          </p>
          <div className="my-3 text-justify">
            <p className="h3 text-dark">Acceptance of Terms</p>
            <p>1. The PracMed Website is operated by PracMed Holdings Pty Ltd (ACN 604 765 933) (we/us/our).</p>
            <p>2. By accessing, viewing and using the PracMed Website, you agree to be bound by the Terms.</p>
          </div>
          <div className="my-3 text-justify">
            <p className="h3 text-dark">Changes to Terms</p>
            <p>3. We reserve the right to change these Terms at any time without any prior notice and you will be taken to 
              have accepted any change by continuing to use the PracMed Website.</p>
          </div>
          <div className="my-3 text-justify">
            <p className="h3 text-dark">Definitions</p>
            <p>4. In these Terms:</p>
            <p className="ml-4">a. Product means the provision of access to particular content of the PracMed Website to assist 
              in preparation for educational medical examinations for medical practitioners and will be provided on a content 
              and/or time basis as specified at the time of purchase; and</p>
            <p className="ml-4">b. Customer Account means the account created when you create a username and password and 
              provide additional requested information to the PracMed Website in order to access content on the PracMed 
              Website and use the Products.</p>
          </div>
          <div className="my-3 text-justify">
            <p className="h3 text-dark">Use of the PracMed Website?</p>
            <p>5. You may access the PracMed Website for the purposes of:</p>
            <p className="ml-4">a. establishing a Customer Account; and</p>
            <p className="ml-4">b. browsing, selecting and purchasing the Products.</p>
            <p>6. Any order placed through the PracMed Website is an offer by you to purchase each particular Product in 
              accordance with these Terms. We reserve the right to accept or 
              reject an order you make using the PracMed Website for any reason.</p>
            <p>7. The Product you purchase may provide access to particular content of the PracMed Website for a particular 
              timeframe.</p>
            <p>8. Access to the particular content on the PracMed Website under a Product you purchase will expire at 
              either:</p>
            <p className="ml-4">a. at the end of time frame specified when you purchase the Product; or</p>
            <p className="ml-4">b. if your Customer Account is terminated pursuant to these Terms.</p>
            <p>9. Once we accept your order for the Products and you have made payment for the Products, they are non-refundable and 
              non-transferable.</p>
            <p>10. We may from time to time offer free access to content of the PracMed Website. 
              If you are offered free access to content of the PracMed Website, 
              we reserve the right to cancel or deactivate your access to that particular 
              content at any time.</p>
          </div>
          <div className="my-3 text-justify">
            <p className="h3 text-dark">Use of your Customer Account</p>
            <p>11. Your Customer Account (including the details of the username and password) may not be assigned or 
              transferred to any other person or entity.</p>
            <p>12. You may not permit any other person to use your Customer Account or provide them with the details of your 
              Customer Account. </p>
            <p>13. You must promptly inform us if you are aware of any breach, theft or loss of the details of your Customer 
              Account or any unauthorised use of your Customer Account.</p>
          </div>
          <div className="my-3 text-justify">
            <p className="h3 text-dark">Intellectual property rights</p>
            <p>14. All text, graphics, logos, icons, images, photographs and software that feature on the PraceMed Website 
              are protected under Australian and International copyright laws.</p>
            <p>15. If you upload any material or content on the PracMed Website, you grant us an irrevocable, royalty-free 
              licence to use and reproduce that material or content.</p>
            <p>16. We provide you with a limited non-assignable and non-exclusive licence to view onscreen the content you 
              have purchased as part of a particular Product for the purpose of your own personal study.</p>
            <p>17. Except as specified in clause 16 above, you may not in any form or by any means adapt, reproduce, 
              download, store, create derivative works, distribute, print, display, perform, publish or commercialise any 
              materials obtained from any part of the PracMed Website.</p>
            <p>18. You may not, without our written permission, onsell any information obtained from the PracMed Website, .
              use any data mining robots, other extraction tools, metatags or mirror the PracMed Website.</p>
          </div>
          <div className="my-3 text-justify">
            <p className="h3 text-dark">Payment</p>
            <p>19. Payment for access to the Products must be paid in full before the access is provided by us.</p>
            <p>20. We accept all major credit cards, namely Visa, MasterCard, or PayPal.</p>
            <p>21. We reserve the right to change our mode of payment at any time.</p>
            <p>22. To the extent permitted by law, we will not be held liable and responsible for any damages or 
              consequential loss (whether direct or indirect) suffered by you as a result of any credit card fraud or 
              unauthorised access to a PayPal account.</p>
            <p>23. We will not be held liable or responsible for any additional charges imposed by your credit card 
              provider or bank, especially in the case where your purchase involves foreign exchange transactions.</p>
          </div>
          <div className="my-3 text-justify">
            <p className="h3 text-dark">Prices, GST and taxes</p>
            <p>24. All prices on the PracMed Website are listed in Australian Dollars and they include GST (where applicable).</p>
            <p>25. By placing an order, you agree to pay the stated price (plus any applicable shipping and handling charges) in Australian Dollars for all orders. You agree to pay any additional charges or taxes incurred (including any custom duties) if applicable.</p>
            <p>26. We reserve the right to change the prices at any time without notice to you.</p>
          </div>
          <div className="my-3 text-justify">
            <p className="h3 text-dark">Disclaimer</p>
            <p>27. While the information on the PracMed Website has been researched, reviewed and presented with all due care, the content is provided to assist you in your studies and does not amount to medical advice.</p>
            <p>28. Information on the PracMed Website is not intended to replace specific or tailored medical advice from a health professional and we do not accept any liability for any error or omission resulting in any injury, expense, loss or damage incurred by you or another party as a result of using the PracMed Website.</p>
            <p>29. We do not accept responsibility for any loss or damage, however caused (including through negligence), which you may directly or indirectly suffer in connection with your use of the PracMed Website, nor do we accept any responsibility for any such loss arising out of your use of, or reliance on, information contained on or accessed through the PracMed Website.</p>
            <p>30. We do not accept responsibility or liability for any changes or additions to the syllabus, testing pattern, guidance notes, explanations, announcement of exam dates or any of their contents, or from any action taken (or refrained from being taken) as a result of using the PracMed Website or accessing the Products.</p>
            <p>31. You acknowledge that it is your responsibility to check with your examining institution in relation to the structure, syllabus, and all other relevant matters relating to your particular examination.</p>
            <p>32. We do not accept any responsibility or liability for any such contents or for any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission or through downloadable material and or software(s) and tools, computer virus, communications line failure, theft or destruction or unauthorised access to, alteration of, or use of information contained on the PracMed Website or the Products.</p>
            <p>33. We are not affiliated with, endorsed by, sponsored by or connected with any university, college, medical exam board or other agency responsible for provision of medical examinations, entry medical examinations and/or exit examinations including but not limited to the Royal Australian, NZ College of Surgeons and the Royal Australian College of Physicians and/or Royal Australian College of General Practitioners.</p>
            <p>34. It is your responsibility to ensure that your use of the PracMed Website and the access to the Products complies with any academic guidelines or instructions given to you by your university, college, medical exam board or other agency responsible for provision of medical examinations.</p>
            <p>35. We do not make any representations, warranties or guarantees whatsoever as to the accuracy, adequacy, reliability, completeness, suitability or applicability of the information to your particular situation or examination undertaken.</p>
            <p>36. We do not guarantee that the PracMed Website or the particular Product you access will include individual questions similar to those that will be asked in any particular examination or assessment that is undertaken by you. We do not guarantee that the format of the practice examinations accessed in the Product will be similar to any particular examination or assessment that is undertaken by you.</p>
            <p>37. The use of the Product is only to be exercised by you as a study aid in addition to any recommended study as indicated by your examining institution and we do not accept any responsibility or liability if you do not pass a particular examination or test, or if you receive a grade lower than you expected.</p>
            <p>38. We also do not guarantee that the provision of the Product via the PracMed Website will be uninterrupted, timely, secure or error-free.</p>
            <p>39. While we make every reasonable effort to ensure that the PracMed Website is available at all times, we cannot guarantee 100% website uptime. The PracMed Website must be bought down at times for scheduled maintenance and upgrades. We may experience a high volume of visitors prior to and during exam periods. In the unlikely event that you will not be able to access the website during that period, please note that we would endeavour to restore access to the website in a timely manner, and further, are not responsible for any losses or damage that could arise as a result of your inability to access the PracMed Website. The PracMed Website may also be unavailable due to traffic conditions on the Internet or due to a hardware or software component failure or due to an event that is entirely out of our control. While we will endeavour to restore access to the website in a timely manner, we would not be responsible for any kind of losses that may arise due to any downtime.</p>
          </div>
          <div className="my-3 text-justify">
            <p className="h3 text-dark">Indemnity</p>
            <p>40. You agree to fully indemnify us against any loss (either direct or indirect), damage or expense whatsoever which we may suffer or incur in respect of any breach by you of the provisions of these Terms.</p>
          </div>
          <div className="my-3 text-justify">
            <p className="h3 text-dark">Termination of Customer Account</p>
            <p>41. We reserve the right to refuse access to the PracMed Website or the Products to anyone should we believe that the PracMed Website or the Product have been or are being used for any unlawful purpose, and to take any further action as permitted by law.</p>
            <p>42. In particular, we may terminate a Customer Account immediately should a party breach one of these Terms.</p>
            <p>43. We may randomly from time to time conduct audits of Customer Accounts and usage to ascertain whether a Customer Account is found to be accessed by multiple Internet Protocol addresses at the same time. If a Customer Account is found to be accessed by multiple Internet Protocol addresses at the same time we reserve the right to immediately terminate the Customer Account.</p>
            <p>44. Should your Customer Account become terminated, you will remain liable for all liabilities that may have arisen or arise from your Customer Account and/or the termination thereof.</p>
          </div>
          <div className="my-3 text-justify">
            <p className="h3 text-dark">Australian Consumer Law</p>
            <p>45. If the goods or services (as defined in the Australian Consumer Law) we supply you are not of acceptable quality, you may have rights under:</p>
            <p className="ml-4">a. Schedule 2 of the Competition and Consumer Act 2010 (Cth) (the Australian Consumer Law); and/or</p>
            <p className="ml-4">b. other consumer laws applying in each Australian State or Territory.</p>
            <p>46. If there is a minor problem with the Product that is a good (as defined in the Australian Consumer Law) we may offer you a replacement of the particular good instead of offering a refund.</p>
            <p>47. If there is a major problem with a Product that is a good (as defined in the Australian Consumer Law), a refund may be available.</p>
            <p>48. If there is a minor failure to comply with the consumer guarantees in relation to Product that are services (as defined in the Australian Consumer Law) we limit our liability for failure to comply with the consumer guarantees to supplying the services again.</p>
            <p>49. We invite you to notify us of any issues in relation to the provision of the Products as soon as possible by emailing us at info@practicemedicine.com.au.</p>
          </div>
          <div className="my-3 text-justify">
            <p className="h3 text-dark">Software updates</p>
            <p>50. The software that you use to access the PracMed Website may automatically download and install updates from time to time from us. These updates are designed to improve, enhance and further develop the Products and may take the form of bug fixes, enhanced functions, new software modules and completely new versions. You agree to receive such updates (and permit us to deliver these to you) as part of your use of the Products.</p>
          </div>
          <div className="my-3 text-justify">
            <p className="h3 text-dark">Details you provide us with</p>
            <p>51. It is your responsibility to ensure that the information you provide, including your name, contact numbers, email address and orders are correct, so please check any details carefully before submitting an order to us.</p>
            <p>52. You acknowledge and agree that we will not be responsible for any loss or damage that you may suffer as a result of you providing incorrect information to us including your name, contact phone numbers or email addresses.</p>
          </div>
          <div className="my-3 text-justify">
            <p className="h3 text-dark">Subscription and Email List</p>
            <p>53. From time to time, we may email you advance notice of new content available on the PracMed Website and other news. The email addresses we collect are only used internally for the purposes as stated above.</p>
            <p>54. We respect your privacy rights and will not sell or rent your email address to other companies. If you would like to be removed from our email list you can do so by unsubscribing at the bottom of the emails sent to you.</p>
          </div>
          <div className="my-3 text-justify">
            <p className="h3 text-dark">Social Media</p>
            <p>55. We may from time to time operate and manage social media accounts on Facebook, Instagram and Pinterest or other social media channels in future.</p>
            <p>56. Please note that we do not endorse the opinions expressed by the users on our social media pages. By submitting content, you agree that we may use and reproduce this content including your name, for testimonials or other purposes in any media and without any compensation to you. Any content you submit must be your original work.</p>
            <p>57. You must not post any material that is defamatory, derogatory, racist, sexist, unlawful, obscene, infringing any third party’s intellectual property rights, breaching someone else’s confidentiality or privacy, misrepresenting your relationship with us or the PracMed Website, misleading, false or deceptive on our social media pages. We reserve the right to edit or remove content that violates these Terms and to block your access to our page.</p>
          </div>
          <div className="my-3 text-justify">
            <p className="h3 text-dark">Testimonials</p>
            <p>58. From time to time we may publish testimonials on the PracMed Website, but you acknowledge and agree that this does not constitute a guarantee or warranty relating to us or the Products, but instead represent the experience of individual consumers.</p>
          </div>
          <div className="my-3 text-justify">
            <p className="h3 text-dark">Links to third party Websites</p>
            <p>59. The PracMed Website may contain links to other websites that are operated by third parties.</p>
            <p>60. Unless stated otherwise, the PracMed Website does not control, endorse, sponsor or approve such third party websites or their content, nor are we liable and responsible for any loss or damage that you may suffer as a result of your visits to any third party websites.</p>
          </div>
          <div className="my-3 text-justify">
            <p className="h3 text-dark">Interference with this Website</p>
            <p>61. You agree not to make or attempt to modify, hack, remove, deface, add or otherwise interfere with the PracMed Website or to any material or content posted on the PracMed Website.</p>
          </div>
          <div className="my-3 text-justify">
            <p className="h3 text-dark">Privacy</p>
            <p>62. For our privacy and policy statement, please refer to our Privacy Policy.</p>
          </div>
          <div className="my-3 text-justify">
            <p className="h3 text-dark">Governing law</p>
            <p>63. These Terms are governed by the laws of the State of Victoria, Australia.</p>
          </div>
          <div className="my-3 text-justify">
            <p className="h3 text-dark">Events which are out of our control</p>
            <p>64. We shall not be held liable and responsible for any delay in performance of our obligations under these Terms if the delay is caused by circumstances beyond our reasonable control.</p>
          </div>
          <div className="my-3 text-justify">
            <p className="h3 text-dark">General provision</p>
            <p>65. If we do not take action against you with respect to any breach by you of these Terms, this does not mean that we have waived our rights to take action with respect to subsequent or similar breaches.</p>
            <p>66. Furthermore, if any part of these Terms is found to be void, unlawful or unenforceable for any reason, that part will be deemed severed from the remainder of these Terms and will not affect the validity of the remaining clauses.</p>
          </div>
          <div className="my-3 text-justify">
            <p className="h4 text-dark">Last updated on 25th October 2021</p>
          </div>
          <div className="my-4 bg-light">
            <p className="h1 text-dark py-4 px-4">Whether you are in medical school or a professional of medicine, PracMed has you covered to prepare you for your studies and exams!</p>
            <div className="populer-btn">
              <div className="terms-align text-left px-4 pb-4">
                <NavLink title="About Us" className="nav-link" tag={Link} to="/aboutus">Read More about us</NavLink>
              </div>
            </div>
          </div>
        </main>
      </Fragment>
      
    )
  }
}

{/* <section className="banner-area" data-aos="fade-up" data-aos-duration="2000">
        <div className="container">
          <div className="paragraphs">
            <h3>Terms &amp; Conditions</h3>
            <div>
            <h4 className="text-headding">TERMS AND CONDITIONS</h4>
            <p>Please read these Terms and conditions of use (Terms) carefully before using the website at www.practicemedicine.com.au/ (PracMed Website)</p>
            <h4 className="text-headding">Acceptance of Terms</h4>
            <p>1.	The PracMed Website is operated by PracMed Holdings Pty Ltd (ACN 604 765 933) <strong>(we/us/our).</strong> </p>
            <p>2.	By accessing, viewing and using the PracMed Website, you agree to be bound by the Terms. </p>
            <h4 className="text-headding">Changes to Terms</h4>
            <p>3.	We reserve the right to change these Terms at any time without any prior notice and you will be taken to have accepted any change by continuing to use the PracMed Website.</p>
            <h4 className="text-headding">Definitions</h4>
            <p>4.	In these Terms:<br/>
            a.	Product means the provision of access to particular content of the PracMed Website to assist in preparation for educational medical examinations for medical practitioners and will be provided on a content and/or time basis as specified at the time of purchase; and<br/>
            b.	Customer Account means the account created when you create a username and password and provide additional requested information to the PracMed Website in order to access content on the PracMed Website and use the Products.</p>
            <h4 className="text-headding">Use of the PracMed Website?</h4>
            <p>5.	You may access the PracMed Website for the purposes of:<br/>
            a.	establishing a Customer Account; and<br/>
            b.	browsing, selecting and purchasing the Products.  </p>
            <p>6.	Any order placed through the PracMed Website is an offer by you to purchase each particular Product in accordance with these Terms. We reserve the right to accept or reject an order you make using the PracMed Website for any reason.</p>
            <p>7.	The Product you purchase may provide access to particular content of the PracMed Website for a particular timeframe. </p>
            <p>8.	Access to the particular content on the PracMed Website under a Product you purchase will expire at either:<br/>
            a.	at the end of time frame specified when you purchase the Product; or<br/>
            b.	if your Customer Account is terminated pursuant to these Terms. </p>
            <p>9.	Once we accept your order for the Products and you have made payment for the Products, they are non-refundable and non-transferable. </p>
            <p>10.	We may from time to time offer free access to content of the PracMed Website. If you are offered free access to content of the PracMed Website, we reserve the right to cancel or deactivate your access to that particular content at any time. </p>
            <h4 className="text-headding">Use of your Customer Account</h4>
            <p>11.	Your Customer Account (including the details of the username and password) may not be assigned or transferred to any other person or entity.</p>
            <p>12.	You may not permit any other person to use your Customer Account or provide them with the details of your Customer Account. </p>
            <p>13.	You must promptly inform us if you are aware of any breach, theft or loss of the details of your Customer Account or any unauthorised use of your Customer Account.</p>
            <h4 className="text-headding">Intellectual property rights</h4>
            <p>14.	All text, graphics, logos, icons, images, photographs and software that feature on the PraceMed Website are protected under Australian and International copyright laws. </p>
            <p>15.	If you upload any material or content on the PracMed Website, you grant us an irrevocable, royalty-free licence to use and reproduce that material or content.</p>
            <p>16.	We provide you with a limited non-assignable and non-exclusive licence to view onscreen the content you have purchased as part of a particular Product for the purpose of your own personal study. </p>
            <p>17.	Except as specified in clause 16 above, you may not in any form or by any means adapt, reproduce, download, store, create derivative works, distribute, print, display, perform, publish or commercialise any materials obtained from any part of the PracMed Website.</p>
            <p>18.	You may not, without our written permission, onsell any information obtained from the PracMed Website, use any data mining robots, other extraction tools, metatags or mirror the PracMed Website.</p>
            <h4 className="text-headding">Payment</h4>
            <p>19.	Payment for access to the Products must be paid in full before the access is provided by us. </p>
            <p>20.	We accept all major credit cards, namely Visa, MasterCard, or PayPal. </p>
            <p>21.	We reserve the right to change our mode of payment at any time. </p>
            <p>22.	To the extent permitted by law, we will not be held liable and responsible for any damages or consequential loss (whether direct or indirect) suffered by you as a result of any credit card fraud or unauthorised access to a PayPal account.</p>
            <p>23.	We will not be held liable or responsible for any additional charges imposed by your credit card provider or bank, especially in the case where your purchase involves foreign exchange transactions. </p>
            <h4 className="text-headding">Prices, GST and taxes</h4>
            <p>24.	All prices on the PracMed Website are listed in Australian Dollars and they include GST (where applicable). </p>
            <p>25.	By placing an order, you agree to pay the stated price (plus any applicable shipping and handling charges) in Australian Dollars for all orders. You agree to pay any additional charges or taxes incurred (including any custom duties) if applicable.</p>
            <p>26.	We reserve the right to change the prices at any time without notice to you.</p>
            <h4 className="text-headding">Disclaimer</h4>
            <p>27.	While the information on the PracMed Website has been researched, reviewed and presented with all due care, the content is provided to assist you in your studies and does not amount to medical advice.</p>
            <p>28.	Information on the PracMed Website is not intended to replace specific or tailored medical advice from a health professional and we do not accept any liability for any error or omission resulting in any injury, expense, loss or damage incurred by you or another party as a result of using the PracMed Website. </p>
            <p>29.	We do not accept responsibility for any loss or damage, however caused (including through negligence), which you may directly or indirectly suffer in connection with your use of the PracMed Website, nor do we accept any responsibility for any such loss arising out of your use of, or reliance on, information contained on or accessed through the PracMed Website. </p>
            <p>30.	We do not accept responsibility or liability for any changes or additions to the syllabus, testing pattern, guidance notes, explanations, announcement of exam dates or any of their contents, or from any action taken (or refrained from being taken) as a result of using the PracMed Website or accessing the Products.</p>
            <p>31.	You acknowledge that it is your responsibility to check with your examining institution in relation to the structure, syllabus, and all other relevant matters relating to your particular examination. </p>
            <p>32.	We do not accept any responsibility or liability for any such contents or for any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission or through downloadable material and or software(s) and tools, computer virus, communications line failure, theft or destruction or unauthorised access to, alteration of, or use of information contained on the PracMed Website or the Products. </p>
            <p>33.	We are not affiliated with, endorsed by, sponsored by or connected with any university, college, medical exam board or other agency responsible for provision of  medical examinations, entry medical examinations and/or exit examinations including but not limited to the Royal Australian, NZ College of Surgeons and the Royal Australian College of Physicians and/or Royal Australian College of General Practitioners. </p>
            <p>34.	It is your responsibility to ensure that your use of the PracMed Website and the access to the Products complies with any academic guidelines or instructions given to you by your university, college, medical exam board or other agency responsible for provision of medical examinations.</p>
            <p>35.	We do not make any representations, warranties or guarantees whatsoever as to the accuracy, adequacy, reliability, completeness, suitability or applicability of the information to your particular situation or examination undertaken. </p>
            <p>36.	We do not guarantee that the PracMed Website or the particular Product you access will include individual questions similar to those that will be asked in any particular examination or assessment that is undertaken by you. We do not guarantee that the format of the practice examinations accessed in the Product will be similar to any particular examination or assessment that is undertaken by you.</p>
            <p>37.	The use of the Product is only to be exercised by you as a study aid in addition to any recommended study as indicated by your examining institution and we do not accept any responsibility or liability if you do not pass a particular examination or test, or if you receive a grade lower than you expected. </p>
            <p>38.	We also do not guarantee that the provision of the Product via the PracMed Website will be uninterrupted, timely, secure or error-free. </p>
            <p>39.	While we make every reasonable effort to ensure that the PracMed Website is available at all times, we cannot guarantee 100% website uptime. The PracMed Website must be bought down at times for scheduled maintenance and upgrades. We may experience a high volume of visitors prior to and during exam periods. In the unlikely event that you will not be able to access the website during that period, please note that we would endeavour to restore access to the website in a timely manner, and further, are not responsible for any losses or damage that could arise as a result of your inability to access the PracMed Website.  The PracMed Website may also be unavailable due to traffic conditions on the Internet or due to a hardware or software component failure or due to an event that is entirely out of our control. While we will endeavour to restore access to the website in a timely manner, we would not be responsible for any kind of losses that may arise due to any downtime.  </p>
            <h4 className="text-headding">Indemnity</h4>
            <p>40.	You agree to fully indemnify us against any loss (either direct or indirect), damage or expense whatsoever which we may suffer or incur in respect of any breach by you of the provisions of these Terms.</p>
            <h4 className="text-headding">Termination of Customer Account</h4>
            <p>41.	We reserve the right to refuse access to the PracMed Website or the Products to anyone should we believe that the PracMed Website or the Product have been or are being used for any unlawful purpose, and to take any further action as permitted by law.</p>
            <p>42.	In particular, we may terminate a Customer Account immediately should a party breach one of these Terms.</p>
            <p>43.	We may randomly from time to time conduct audits of Customer Accounts and usage to ascertain whether a Customer Account is found to be accessed by multiple Internet Protocol addresses at the same time. If a Customer Account is found to be accessed by multiple Internet Protocol addresses at the same time we reserve the right to immediately terminate the Customer Account. </p>
            <p>44.	Should your Customer Account become terminated, you will remain liable for all liabilities that may have arisen or arise from your Customer Account and/or the termination thereof.</p>
            <h4 className="text-headding">Australian Consumer Law</h4>
            <p>45.	If the goods or services (as defined in the Australian Consumer Law) we supply you are not of acceptable quality, you may have rights under:<br/>
            a.	Schedule 2 of the Competition and Consumer Act 2010 (Cth) (the Australian Consumer Law); and/or<br/>
            b.	other consumer laws applying in each Australian State or Territory.</p>
            <p>46.	If there is a minor problem with the Product that is a good (as defined in the Australian Consumer Law) we may offer you a replacement of the particular good instead of offering a refund. </p>
            <p>47.	If there is a major problem with a Product that is a good (as defined in the Australian Consumer Law), a refund may be available.</p>
            <p>48.	If there is a minor failure to comply with the consumer guarantees in relation to Product that are services (as defined in the Australian Consumer Law) we limit our liability for failure to comply with the consumer guarantees to supplying the services again. </p>
            <p>49.	We invite you to notify us of any issues in relation to the provision of the Products as soon as possible by emailing us at info@practicemedicine.com.au.</p>
            <h4 className="text-headding">Software updates</h4>
            <p>50.	The software that you use to access the PracMed Website may automatically download and install updates from time to time from us. These updates are designed to improve, enhance and further develop the Products and may take the form of bug fixes, enhanced functions, new software modules and completely new versions. You agree to receive such updates (and permit us to deliver these to you) as part of your use of the Products.</p>
            <h4 className="text-headding">Details you provide us with</h4>
            <p>51.	It is your responsibility to ensure that the information you provide, including your name, contact numbers, email address and orders are correct, so please check any details carefully before submitting an order to us. </p>
            <p>52.	You acknowledge and agree that we will not be responsible for any loss or damage that you may suffer as a result of you providing incorrect information to us including your name, contact phone numbers or email addresses. </p>
            <h4 className="text-headding">Subscription and Email List</h4>
            <p>53.	From time to time, we may email you advance notice of new content available on the PracMed Website and other news. The email addresses we collect are only used internally for the purposes as stated above. </p>
            <p>54.	We respect your privacy rights and will not sell or rent your email address to other companies. If you would like to be removed from our email list you can do so by unsubscribing at the bottom of the emails sent to you.</p>
            <h4 className="text-headding">Social Media</h4>
            <p>55.	We may from time to time operate and manage social media accounts on Facebook, Instagram and Pinterest or other social media channels in future.</p>
            <p>56.	Please note that we do not endorse the opinions expressed by the users on our social media pages.  By submitting content, you agree that we may use and reproduce this content including your name, for testimonials or other purposes in any media and without any compensation to you.  Any content you submit must be your original work. </p>
            <p>57.	You must not post any material that is defamatory, derogatory, racist, sexist, unlawful, obscene, infringing any third party’s intellectual property rights, breaching someone else’s confidentiality or privacy, misrepresenting your relationship with us or the PracMed Website, misleading, false or deceptive on our social media pages. We reserve the right to edit or remove content that violates these Terms and to block your access to our page.</p>
            <h4 className="text-headding">Testimonials</h4>
            <p>58.	From time to time we may publish testimonials on the PracMed Website but you acknowledge and agree that this does not constitute a guarantee or warranty relating to us or the Products, but instead represent the experience of individual consumers.</p>
            <h4 className="text-headding">Links to third party Websites</h4>
            <p>59.	The PracMed Website may contain links to other websites that are operated by third parties. </p>
            <p>60.	Unless stated otherwise, the PracMed Website does not control, endorse, sponsor or approve such third party websites or their content, nor are we liable and responsible for any loss or damage that you may suffer as a result of your visits to any third party websites. </p>
            <h4 className="text-headding">Interference with this Website</h4>
            <p>61.	You agree not to make or attempt to modify, hack, remove, deface, add or otherwise interfere with the PracMed Website or to any material or content posted on the PracMed Website. </p>
            <h4 className="text-headding">Privacy</h4>
            <p>62.	For our privacy and policy statement, please refer to our Privacy Policy.</p>
            <h4 className="text-headding">Governing law</h4>
            <p>63.	These Terms are governed by the laws of the State of Victoria, Australia. 	</p>
            <h4 className="text-headding">Events which are out of our control</h4>
            <p>64.	We shall not be held liable and responsible for any delay in performance of our obligations under these Terms if the delay is caused by circumstances beyond our reasonable control. </p>
            <h4 className="text-headding">General provision</h4>
            <p>65.	If we do not take action against you with respect to any breach by you of these Terms, this does not mean that we have waived our rights to take action with respect to subsequent or similar breaches. </p>
            <p>66.	Furthermore, if any part of these Terms is found to be void, unlawful or unenforceable for any reason, that part will be deemed severed from the remainder of these Terms and will not affect the validity of the remaining clauses.<br/>
            <strong>Last updated on 30th October 2015</strong>
            </p>
            </div>
            <div className="medical-terms">
              <h3 className="terms-align">Whether you are in medical school or a professional<br/>of medicine, PracMed has you covered to prepare<br/>you for your studies and exams!</h3>
              <div className="populer-btn">
                <h3 className="terms-align">
                <NavLink title="About Us" tag={Link} to="/aboutus">Read More about us</NavLink>
                </h3>
              </div>
            </div>
          </div>
      </div>
    </section> */}