import React, { useState, Fragment, useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AppRoutes from './components/api-authorization/AppRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { Layout } from './components/Layout';
import { HeaderSection } from './components/HeaderSection';
import { FooterSection } from './components/FooterSection';
import { Home } from './components/Home';
import { Profile } from './components/Profile';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import { AboutUs } from './components/AboutUs';
import { Features } from './components/Features';
import { TermsAndConditions } from './components/TermsAndConditions';
import { ChooseDuration } from './components/CartComponents/ChooseDuration';
import AuthContext from './components/store/auth-context';
import PasswordReset from './components/AccountManagement/PasswordReset';
import { UserCart } from './components/CartComponents/UserCart';
import { InformationCollect } from './components/CartComponents/InformationCollect';
import { PaymentPage } from './components/CartComponents/PaymentPage';
import { ExamsDemo } from './components/Exams-Demo/ExamsDemo';

function App(props) {
    const authCtx = useContext(AuthContext);

    return (
        <Fragment>
            <HeaderSection />
            <Switch>
                <Route exact path="/" component={Profile} />
                <Route exact path="/home" component={Home} />
                <Route path="/confirmmail" component={Home} />
                <Route path="/aboutus" component={AboutUs} />
                <Route path="/features" component={Features} />
                <Route path="/cart-items" component={() => <UserCart User={authCtx.UserName} CartHandler={authCtx.CartCountHandler} />} />
                <Route path="/exam-duration" component={(props) => <ChooseDuration onChooseExam={props} />} />
                <Route path="/exams-demo" component={(props) => <ExamsDemo onSelectExam={props} />}/>
                <Route path="/terms-conditions" component={TermsAndConditions} />
                <Route path="/User-information" component={InformationCollect} />
                <Route path="/Payment-page" component={(props)=><PaymentPage onPay={props}/>} />
                <Route path="/ResetPassword" component={PasswordReset} />
                <Route path='*'><Redirect to='/' /></Route>                
            </Switch>
            <FooterSection />
        </Fragment>
    );
}
export default App;