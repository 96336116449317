import React, { useContext, useState, Fragment, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { infoWrapper } from '../InfoAlert';
import * as api from '../API/Api';
import AuthContext from '../store/auth-context';
import StripeContainer from './StripeContainer'
import CheckoutForm from './CheckoutForm'

const Today = new Date();
export const PaymentPage = (props) => {
    const history = useHistory();
    const AuthCtx = useContext(AuthContext);
    const CurrentYear = Today.getFullYear();
    const [cardNumber, setCardNumber] = useState('');
    const [cardName, setCardName] = useState('');
    const [cardExpiryMonth, setCardExpiryMonth] = useState('');
    const [cardExpiryYear, setCardExpiryYear] = useState('');
    const [cardCVV, setCardCVV] = useState('');
    const [CartItems, setCartItems] = useState([]);
    const [TotalCost, setTotalCost] = useState(0);
    const [discountCode, setDiscountCode] = useState('');
    const [discountDetails, setDiscountDetails] = useState(null);
    const [discountAmount, setDiscountAmount] = useState(0);
    
    useEffect(() => {
        ForCartItems();
    }, []);
    
    const ForCartItems = ()=>{
    api.fetchData('/Api/CartItems/PayingForExamsByUserName?user='+AuthCtx.UserName)
    .then( res =>{
        let Cost = 0;
        res.data.map(item => {
        Cost += item.cost;
        });
        setCartItems(res.data);
        setTotalCost(Cost);
    })
    .catch(err=>{
        console.log(err);
    });
    };
    const changeCardName = (e) => { setCardName(e.target.value.toUpperCase()) };
    const changeCardNumber = (e) => {
        if (+e.target.value > -1) {
            setCardNumber(e.target.value);
        }
    };
    const changeExpiryMonth = (e) => { setCardExpiryMonth(e.target.value) };
    const changeExpiryYear = (e) => { setCardExpiryYear(e.target.value) };
    const changeCardCVV = (e) => { setCardCVV(e.target.value) };
    const validateForm = () => {
        if (cardName === '') {
            (async () => await infoWrapper("Please Enter Name on Card!"))();
            return false;
        }else if (cardNumber === '') {
            (async () => await infoWrapper("Please Enter card number!"))();
            return false;
        } else if (cardNumber.length < 16) {
            (async () => await infoWrapper("Please Enter 16 digit card number!"))();
            return false;
        } else if (cardExpiryMonth === '' || cardExpiryMonth === 0) {
            (async () => await infoWrapper("Please choose expiry month!"))();
            return false;
        } else if (cardExpiryYear === '' || cardExpiryYear === 0) {
            (async () => await infoWrapper("Please choose expiry year!"))();
            return false;
        } else if (cardCVV.length !== 3) {
            (async () => await infoWrapper("Please Enter 3 digit CVV number!"))();
            return false;
        } else {
            return true;
        }
    };
    const changeDiscountCode = (e)=>{
        setDiscountCode(e.target.value.toUpperCase());
    }
    const ApplyDiscountCode = (e)=>{
        e.preventDefault();
        if(discountCode !== ''){
            api.fetchData('/api/Discounts/DiscountdetailsByCode?code='+ discountCode)
            .then(res=>{
                let data = res.data;
                if(data !== ""){
                    setDiscountDetails(data);
                    if(data.discountType === "Percentage"){
                        const disAmount = parseFloat((TotalCost*10)/100).toFixed(2);
                        const totalCost = parseFloat(TotalCost-disAmount).toFixed(2);
                        setDiscountAmount(disAmount);
                        setTotalCost(totalCost);
                    }else{
                        setDiscountAmount(data.value);
                        setTotalCost(TotalCost-data.value);
                    }
                }else{
                    (async () => await infoWrapper("Invalid Code. Please use any another code!"))();
                }
                console.log(res.data);
            })
            .catch(err=>{
                console.log(err);
                (async () => await infoWrapper("Invalid Access!"))();
            });
        }else{
            (async () => await infoWrapper("Please Enter Discount Code!"))();
        }
    }

    const routeafterpayment = () => {
        history.push('/');
        AuthCtx.CartCountHandler();
    };
    const submitHandler = (CartItems, totalPrice) => {
        let UserExams = [];
        CartItems.map(item => {            
            let CartExam = {
                UserExamID: 0,
                UserID: 0,
                UserName: AuthCtx.UserName,
                CartItemID: item.cartItemID,
            };
            UserExams.push(CartExam);
        });
        let data = {
            Exams: UserExams,
            DiscountId: discountDetails !== null? discountDetails.discountId : 0
        }
        api.postData(`/api/Payment/SubmitPayment`, data)
        .then(res => {
            // let data = res.data;
            // (async () => await infoWrapper(data.message))();
            AuthCtx.CartCountHandler();
            (async () => await infoWrapper(res.data[0], routeafterpayment))();
        })
        .catch(err => {
            // (async () => await infoWrapper("Data Not valid"))();
            console.log(err.data);
        });
    };
    return (
        <Fragment>
            <section className="content-area" data-aos="fade-up" data-aos-duration="2000"> </section>
            <section className="my-5" data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row" /* style={{margin: "100px auto", overflow: "hidden", padding: "25px"}} */>
                    <div className="col-lg-7 card ">
                        <h1 className="text-center mt-4 card-title">
                            Payment
                        </h1>
                        <div className="card-body">
                            <StripeContainer>
                                    <CheckoutForm
                                        price={TotalCost} CartItems={CartItems}
                                        submitHandler={submitHandler}
                                    />
                            </StripeContainer>
                        </div>
                    </div>
                    <div className='col-lg-5'>
                        <div className="card" style={{marginBottom: "10px"}}>
                            <div className="card-body">
                                <h3 className="mb-4 mt-1 h5 text-center font-weight-bold">Enter Discount Code:</h3>
                                <input type='text'
                                className="form-control mb-4"
                                value={discountCode} 
                                onChange={changeDiscountCode} 
                                placeholder="Enter discount code if any..."
                                disabled={discountDetails !== null}/>
                                <div className="text-center">
                                    <button disabled={discountDetails !== null} className="btn btn-default btn-md btn-rounded" type="submit" onClick={ApplyDiscountCode}>Apply Discount Code</button>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <h3 className="mb-4 mt-1 h5 text-center font-weight-bold">Summary</h3>
                                <hr/>
                                {CartItems.map((item, index) =><Fragment key= {index}>
                                    <dl className="row">
                                        <dd className="col-sm-8">
                                            {item.examName}
                                        </dd>
                                        <dd className="col-sm-4">
                                            ${item.cost}
                                        </dd>
                                        <dd className="col-sm-8">
                                            {item.durationName} ({item.days} Days)
                                        </dd>
                                    </dl>
                                    <hr/>
                                </Fragment>)
                                }
                                { discountDetails !== null && <Fragment>
                                    <dl className="row">
                                        <dd className="col-sm-8">
                                            Discount 
                                        </dd>
                                        <dd className="col-sm-4">
                                            ${discountAmount}
                                        </dd>
                                        <dd className="col-sm-8">
                                            (-)
                                        </dd>
                                    </dl>
                                    <hr/>
                                </Fragment>}
                                <dl className="row">
                                    <dt className="col-sm-8">
                                        Total
                                    </dt>
                                    <dt className="col-sm-4">
                                        ${TotalCost}
                                    </dt>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
        </Fragment>
    )
};
export default PaymentPage;
