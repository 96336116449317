import React, { useState, Fragment, fullWidth,  maxWidth } from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DialogTitle } from './DialogTitle';

export const FormDialogNew = (props) => {
  const [open, setOpen] = useState(props.open);
  const handleClose = () => {
    setOpen(false);
    props.closeDialog();
  };
  return (
  <Dialog  maxWidth="80%" onClose={handleClose} open={open} >
    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {props.title}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        <Fragment>
          {props.children}
        </Fragment>
      </DialogContentText>
    </DialogContent>
  </Dialog>);
}