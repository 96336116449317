import React, { Component } from 'react'
import { confirmWrapper } from '../ConfirmAlert'
import { infoWrapper } from '../InfoAlert'
import * as api from '../API/Api';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import $ from 'jquery';

export class ManageUserRole extends Component {
  static DisplayName = ManageUserRole.name
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.state = {
      UserName: '',
      RoleName: '',
      Administrators: [],
      Employees: []
    }
  }
  componentDidMount(){
    api.fetchData('/Api/UserRole/GetRoleUsers')
    .then(res=>{
      this.setState({
        Administrators : res.data.administrators, Employees: res.data.employees
      });
      $('#dtRoleUsers').DataTable();
      $('.dataTables_length').addClass('bs-select');
    })
  }
  componentDidUpdate(){
    api.fetchData('/Api/UserRole/GetRoleUsers')
    .then(res=>{
      this.setState({
        Administrators : res.data.administrators, Employees: res.data.employees
      });
      $('#dtRoleUsers').DataTable();
      $('.dataTables_length').addClass('bs-select');
    })
  }
  changeUserName = (e)=>{this.setState({UserName: e.target.value})}
  changeRole = (e)=>{this.setState({RoleName: e.target.value})}
  onSubmit(e){
    e.preventDefault();
    let action = e.target.value
    console.log(this.state)
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if(!pattern.test(this.state.UserName)){
      (async () => await infoWrapper("Please enter valid Email address."))();
    }
    else if(this.state.RoleName === ''){
      (async () => await infoWrapper("Please Select the Role."))();
    }
    else{
      const UserRole = {
        UserName : this.state.UserName,
        RoleName : this.state.RoleName
      }
      api.postData(`/Api/UserRole/${action}UserRole`, UserRole)
      .then(res => {
        let data = res.data;
        (async () => await infoWrapper(data.message))();
        this.setState({UserName: '', RoleName: ''})
      })
      .catch( err =>{
        (async () => await infoWrapper("Given Email Id is not a valid user."))();
      })     
    }
  }
  forRemoveAdminRole = async (e) =>{
    e.persist();
    if (await confirmWrapper('Are your sure that you want to delete the selected Role ?')){
      const UserRole = {
        UserName : e.target.value,
        RoleName : "Admin"
      }
      api.postData(`/Api/UserRole/RemoveUserRole`, UserRole)
      .then(res => {
        this.setState({UserName: '', RoleName: ''})
      })
    }
  }
  forRemoveEmployeeRole = async (e) =>{
    e.persist();
    if (await confirmWrapper('Are your sure that you want to delete the selected Role ?')){
      const UserRole = {
        UserName : e.target.value,
        RoleName : "Employee"
      }
      api.postData(`/Api/UserRole/RemoveUserRole`, UserRole)
      .then(res => {
        this.setState({UserName: '', RoleName: ''})
      })
      .catch( err =>{
        (async () => await infoWrapper("Given Email Id is not a valid user."))();
      })
    }
  }
  render() {
    const {UserName, RoleName} = this.state;
    const adminlist = this.state.Administrators.map((a, index)=>{return <tr key={index}>
      <td>{a.userName}</td>
      <td>Administrator</td>
      <td><button className="btn btn-rounded btn-sm btn-default" value={a.userName} type="button" onClick={this.forRemoveAdminRole}>Remove Role</button></td>
    </tr>})
    const emplelist = this.state.Employees.map((e, index)=>{return <tr key={index}>
      <td>{e.userName}</td>
      <td>Employee</td>
      <td><button className="btn btn-rounded btn-sm btn-default" value={e.userName} type="button" onClick={this.forRemoveEmployeeRole}>Remove Role</button></td>
    </tr>})
    return (
      <main data-aos="fade-up" data-aos-duration="2000" className="container my-2">
        <div className="d-flex justify-content-between card-header align-items-center">
          <h5 className="">Manage New Role</h5>
        </div>
        <div className="row">
          <div className="col-md-6">
            <form onSubmit={this.onSubmit}>
              <div className="mb-2 my-3" data-aos="fade-up" data-aos-duration="2000">
                <label htmlFor="email" className="h6">Enter User Email Id</label>
                <input type="email" 
                placeholder="Please enter User Email Id here"                    
                value={UserName}
                onChange={this.changeUserName}
                id="email" className="form-control round" required/>
              </div>
              <div className="my-3" data-aos="fade-up" data-aos-duration="2000">
                <label htmlFor="Role" className="h6">Select the New Role</label>
                <select className="custom-select" value={RoleName} onChange={this.changeRole}>
                  <option value="">--Choose The New Role--</option>
                  <option value="Admin">Administrator</option>
                  <option value="Employee">Employee</option>
                </select>
              </div>
              <div className="my-2">
                <button type="button" className="btn btn-default btn-md" value="Edit" onClick={this.onSubmit}>Assign Role</button>
              </div>
            </form>
          </div>
        </div>
        <div className="d-flex justify-content-between card-header align-items-center">
          <h5 className="">List of Role Users</h5>
        </div>
        <div className="my-3">
          <div className='table-responsive'>
            <table id="dtRoleUsers" className="table table-striped table-bordered table-sm" cellSpacing="0" width="100%" role="grid" aria-describedby="dtRoleUsers_info" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th className="th-sm">User Id
                  </th>
                  <th className="th-sm">Role
                  </th>
                  <th className="th-sm">Action
                  </th>
                </tr>
              </thead>
              <tbody>
              { adminlist}
              { emplelist}
              </tbody>
            </table>
          </div>
        </div>
      </main>
    )
  }
}