import React, {Fragment, useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import { FormDialogNoTitle } from '../../FormDialogNoTitle';
import * as api from '../../API/Api';

export const ViewDetails = (props) => {
  const [orderData, setOrderData] = useState({
      OrderId: props.orderId,
      Date: props.Date,
    Amount: 10,
    ExamName: ' MCSE Finals',
    Duration: '1 month (Expiry: 13-Dec-2021)',
    Status: 'Successful'
  })
    var showdate = new Date();
    var displaytodaysdate = showdate.getDate() + '/' + showdate.getMonth() + '/' + showdate.getFullYear();
  useEffect(() => {
    // Call APPI or get data from props
  }, []);
  const CancleHandler = (e)=>{
    e.preventDefault();
    props.closeHandler();
  };

  return (
  <div className="IdentityCont">
    <div className="custom-header text-center">
      <h4 className="modal-title w-100 font-weight-bold text-default">Order History</h4>
      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={CancleHandler}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div>
      <ul className="list-group list-group-flush mb-3">
        <li className="list-group-item d-flex">
          <div className="col-6">Order Id :</div>
          <div className="col-6">{orderData.OrderId}</div>
        </li>
        <li className="list-group-item d-flex">
                      <div className="col-6">Date :</div>
                      <div className="col-6">{displaytodaysdate}</div>
        </li>
        <li className="list-group-item d-flex">
          <div className="col-6">Amount :</div>
          <div className="col-6">${orderData.Amount} </div>
        </li>
        <li className="list-group-item d-flex">
          <div className="col-6">Exam Name :</div>
          <div className="col-6">{orderData.ExamName}</div>
        </li>
        <li className="list-group-item d-flex">
          <div className="col-6">Duration :</div>
          <div className="col-6">{orderData.Duration}</div>
        </li>
        <li className="list-group-item d-flex">
          <div className="col-6">Status :</div>
          <div className="col-6">{orderData.Status}</div>
        </li>
      </ul>
    </div>
  </div>
  );
};

export const ViewOrderDetails = (props) => {
  return (
    <Fragment>
    {ReactDOM.createPortal(
      <Fragment>
        <FormDialogNoTitle open={props.forDetails} closeDialog={props.forClose}>
          <ViewDetails closeHandler = {props.forClose} orderId={props.orderId}/>
        </FormDialogNoTitle> : <Fragment />
      </Fragment>,
      document.getElementById('popups')
    )}
  </Fragment>
  )
}
export default ViewOrderDetails;