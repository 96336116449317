import * as api from '../API/Api';

export function UpdateUserExam(userExamID, statusId, min, examMode, selectedQuestionCount, subjects) {
    let selectedSubjects = [];
    if (subjects) {
        for (var i = 0; i < subjects.length; i++) {
            if (subjects[i].isSelected)
                selectedSubjects.push({ UserExamID: userExamID, SubjectID: subjects[i].SubjectId });
        }
    }
    if (!examMode)
        examMode = '-1';
    if (!min)
        min = '-1';
    if (!selectedQuestionCount)
        selectedQuestionCount = '0';
    //return new Promise((resolve, reject) => {
    api.postData(`/Api/UserExams/UpdateUserExamStatus?id=` + userExamID + '&&statusId=' + statusId.toString() + '&&timeLeft=' + min.toString() + '&&examMode=' + examMode + '&&selectedQuestionCount=' + selectedQuestionCount.toString(), selectedSubjects)
        .then(response => {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
            //reject(error);
        });
    //});
}