import React, {useState, useEffect} from 'react';
import * as api from '../API/Api';
import { infoWrapper } from '../InfoAlert';

export const AddressForm = (props) => {
  const [FirstName, setFirstName] = useState('');
  const [LastName, setLastName] = useState('');
  const [BeNotified, setBeNotified] = useState(true);
  const [Apartment, setApartment] = useState('');
  const [Street, setStreet] = useState('');
  const [Town, setTown] = useState('');
  const [State, setState] = useState();
  const [PINCode, setPINCode] = useState('');
  const [States, setStates] = useState([]);
  const [Countries, setCountries] = useState([]);
  const [vid, setVid] = useState('');

  useEffect(() => {
    api.fetchData('api/Countries/CountriesListWithStates').then(response => {
      let data = response.data
      let countries =[];
      data.map(country=>{
        let vid = Math.floor((Math.random() * 10000) + 9000);
        countries[vid]={vid:vid, 
          CountryId:country.countryId,
          Name:country.name,
          Code:country.code,
          States:country.states
        }
      })
      setCountries(countries);
    }).catch(error=> {console.log(error);});
  }, [])

  const ValidateForm = ()=>{
    let isValid =true;
    if (props.email === "undefined" || props.email === '') {
      (async () => await infoWrapper("Un Identified Accesss. Please go to cart Items!"))();
      isValid = false;
    }
    else if(FirstName === ''){
      (async () => await infoWrapper("Please enter First Name!"))();
      isValid = false;
    }
    else if(LastName === ''){
      (async () => await infoWrapper("Please enter Last Name!"))();
      isValid = false;
    }
    else if(Street === ''){
      (async () => await infoWrapper("Please enter Street Name!"))();
      isValid = false;
    }
    else if(Apartment === ''){
      (async () => await infoWrapper("Please enter Apartment or suite Name!"))();
      isValid = false;
    }
    else if(Town === ''){
      (async () => await infoWrapper("Please enter Town Name!"))();
      isValid = false;
    }
    else if(State === '' || State === undefined){
      (async () => await infoWrapper("Please select respective state from the countries!"))();
      isValid = false;
    }
    else if(PINCode === ''){
      (async () => await infoWrapper("Please enter PIN Code!"))();
      isValid = false;
    }
    return isValid;
  };
  
  const handleSubmit = (e) =>{
    e.preventDefault();
    if (ValidateForm()) {
      const addressData = {
        Email: props.email,
        FirstName: FirstName,
        LastName: LastName,
        BeNotified: BeNotified,
        Street: Street,
        Apartment: Apartment,
        Town: Town,
        StateId: State,
        PINCode: PINCode
      }
      console.log(addressData);
      // Saving address
      api.postData('/Api/UserInfo/AddorUpdateUserAddressInfo', addressData)
      .then(res => {           
        (async () => await infoWrapper(res.data.message))();
        if(res.data.status === "Success" || res.data.status === "Updated"){
          props.onNewAddress();
        }
        else{
          (async () => await infoWrapper("Please check your personal details."))();
        }
      }).catch(err => {
          (async () => await infoWrapper(err.message))();
      });
    }
  };
  const changeFN = (e)=>{
    if(e.target.value.length <= 15){
      setFirstName(e.target.value);
    }else{
      (async () => infoWrapper("The maximum characters should not exceed more than 15."))();
    }
  };
  const changeLN = (e)=>{
    if(e.target.value.length <= 15){
      setLastName(e.target.value);
    }else{
      (async () => infoWrapper("The maximum characters should not exceed more than 15."))();
    }
  };
  const changeBeNotified = ()=>{ setBeNotified(!BeNotified)};
  const changeStreet = (e)=>{
    if(e.target.value.length <= 100){
      setStreet(e.target.value);
    }else{
      (async () => infoWrapper("The maximum characters should not exceed more than 100."))();
    }
  };
  const changeApartment = (e)=>{
    if(e.target.value.length <= 100){
      setApartment(e.target.value);
    }else{
      (async () => infoWrapper("The maximum characters should not exceed more than 100."))();
    }
  };
  const changeTown = (e)=>{
    if(e.target.value.length <= 50){
      setTown(e.target.value);
    }else{
      (async () => infoWrapper("The maximum characters should not exceed more than 50."))();
    }
  };
  const changeState = (e)=>{ setState(+e.target.value)};
  const changeCountry = (e)=>{ 
    if(e.target.value > 0)
    { let country = Countries[e.target.value];
      setVid(e.target.value);
      setStates(country.States);
      setState('');
    }
    else{
      setVid('');
      setStates([]);
      setState('');
    }
  };
  
  const changePINCode = (e)=>{ setPINCode(e.target.value)};
  const CountriesList = Countries.map(obj => <option key={obj.vid} value={obj.vid}>{obj.Name}</option>)
  const StatesList = States.map(obj => <option key={obj.stateId} value={obj.stateId}>{obj.name}</option>)

  return (
    <div className="card">
      <div className="card-body">
        <form onSubmit={handleSubmit} method="post">
          <div className="row">
            <div className="col-md-6 mb-4">
              <label htmlFor="firstName" className="">First name</label>
              <input type="text" id="firstName" className="form-control"
              placeholder="First Name"
              value={FirstName}
              onChange={changeFN} required/>
                      </div>
            <div className="col-md-6 mb-2">
                          <label htmlFor="lastName" className="">Last name</label>
              <input type="text" id="lastName" className="form-control"
              placeholder="Last Name"                    
              value={LastName}
              onChange={changeLN} required/>
            </div>
          </div>
          <label htmlFor="address" className="">Street</label>
          <input type="text" id="address" className="form-control mb-4" 
          placeholder="1234 Main St..."
          value={Street}
          onChange={changeStreet} required/>
          <label htmlFor="address-2" className="">Apartment or suite</label>
          <input type="text" id="address-2" className="form-control mb-4"
          placeholder="Apartment, Suite, etc."
          value={Apartment}
          onChange={changeApartment} required/>
          <label htmlFor="address-3" className="">Suburb/Town</label>
          <input type="text" id="address-3" className="form-control mb-4"
          placeholder="Suburb/Town"
          value={Town}
          onChange={changeTown} required/>
          <div className="row">
            <div className="col-lg-4 col-md-12 mb-4">
              <label htmlFor="country">Country/Region</label>
              <select className="custom-select d-block w-100" id="country" value={vid} onChange={changeCountry}>
                <option value="">Choose Country...</option>
                {CountriesList}
              </select>
              <div className="invalid-feedback">
                Please select a valid country.
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <label htmlFor="state">State/territory</label>
              <select className="custom-select d-block w-100" id="state" value={State} onChange={changeState}>
                <option value="">Choose State...</option>
                {StatesList}
              </select>
              <div className="invalid-feedback">
                Please provide a valid state.
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <label htmlFor="zip">Zip</label>
              <input type="text" className="form-control" id="zip" 
              placeholder="zip code" 
              value={PINCode}
              onChange={changePINCode}
              type="number" min="0" step="1" required/>
              <div className="invalid-feedback">
                Zip code required.
              </div>
            </div>
          </div>
          <hr/>
          <div className="mb-1 mx-3">
            <input 
              type="checkbox" 
              className="form-check-input filled-in" 
              id="subscribeNewsletter"
              checked={BeNotified} onChange={changeBeNotified}/>
            <label className="form-check-label" htmlFor="subscribeNewsletter">
              Please check to receive information and special offers about PracMed products and services through email/phone/sms/post.
            </label>
          </div>
          <hr/>
          <div className="text-center">
            <button className="btn btn-default btn-rounded" type="submit" onClick={handleSubmit}>Save Address</button>
          </div>
        </form>
      </div>
    </div>
  )
};

export default AddressForm;