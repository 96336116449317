import React, { Component, Fragment, useState } from 'react'
import { confirmWrapper } from '../ConfirmAlert'
import { infoWrapper } from '../InfoAlert'
import * as api from '../API/Api';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import $ from 'jquery';

export class AddorUpdateSubject extends Component {
  static DisplayName = AddorUpdateSubject.name
  constructor(props){
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.state = {
      Action:'',
      SubjectID:'',
      Code:'',
      SubjectName:'',
      Status: '',
      ischecked: true
    }
  }
  componentDidMount(){
    const id = this.props.id
    if(id > 0){
      api.fetchData('/Api/Subjects/SubjectdetailsById?id='+id)
      .then(res => {
        let data = res.data
        if (data.status ==='Deactivated'){
          this.setState({ischecked: false})
        }
        else{
          this.setState({ischecked: true})
        }
        this.setState({Action: 'Update', SubjectID: data.subjectID, Code: data.code, SubjectName: data.subjectName, Status: data.status})
      })
      .catch(error=>{
        console.log(error); 
      })
    }
    else{
      this.setState({Action: 'Create', SubjectID: 0, Code: '', SubjectName: '', Status: 'Activated'})
    } 
  }
  onSubmit(e){
    e.preventDefault()
    const subject = {
      SubjectID: this.state.SubjectID,
      Code:this.state.Code,
      SubjectName:this.state.SubjectName,
      Status: this.state.Status
    }
    api.postData('/Api/Subjects/AddorUpdateSubject', subject)
    .then(res => {
      // if(this.state.SubjectID === 0)
      //   (async () => await infoWrapper("New Subject Added Successfully."))();
      // else
      //   (async () => await infoWrapper("Data Updated Successfully."))();
      this.props.closeAddorEdit();
    })
    .catch(error=>{
      (async () => await infoWrapper("Error while adding Subject."))();
    })
  }
  onCancel(e){
    e.preventDefault()
    this.props.closeAddorEdit();
  }
  changeName = (e)=>{
    if(e.target.value.length <= 50){
      this.setState({ SubjectName : e.target.value})
    }else{
      (async () => infoWrapper("The maximum characters should not exceed more than 50."))();
    }
  }
  changeCode = (e)=>{
    if(e.target.value.length <= 15){
      this.setState({ Code : e.target.value})
    }else{
      (async () => infoWrapper("The maximum characters should not exceed more than 15."))();
    }
  }
  changeStatus = (e)=>{ 
    if(this.state.ischecked){this.setState({ Status: 'Deactivated', ischecked: false})}
    else{this.setState({ Status: 'Activated', ischecked: true})}
  }
  render() {
    const {Action, Code, SubjectName, ischecked} = this.state;
    return (
      <Fragment>
        <div className="row d-flex justify-content-between card-header align-items-center">
            <h5 className="">{Action} Subject</h5>
        </div>
        <div className="mb-5" data-aos="fade-up" data-aos-duration="2000">
          <form action="" method="post" onSubmit={this.onSubmit}>
            <div className="my-4" data-aos="fade-up" data-aos-duration="2000">
              <label htmlFor="subject-code" className="h6">Subject Code</label>
              <input className="form-control round"
                id="subject-code"
                value={Code}
                onChange={this.changeCode}
                type="text" required />
            </div>
            <div className="my-4" data-aos="fade-up" data-aos-duration="2000">
              <label htmlFor="subject-name" className="h6">Subject Name</label>
              <input className="form-control round"
                id="subject-name"
                value={SubjectName}
                onChange={this.changeName}
                type="text" required />
            </div>
            <div className="my-4" data-aos="fade-up" data-aos-duration="2000">
              <input type="checkbox"
              checked={ischecked}             
              onChange={this.changeStatus}/> Active.
            </div>
            <div className="my-2">
              <button type="submit" className="btn btn-default btn-md" onClick={this.onSubmit}>{Action}</button>
              <button type="reset" className="btn btn-default btn-md" onClick = {this.onCancel}>Cancel</button>
            </div>
          </form>
        </div>
      </Fragment>
    )
  }
}

const TableRow = (props)=>{
  const rowID = "Row_" + props.obj.subjectID;

  const DeleteSubject = async () => {
    if (await confirmWrapper('Are you sure that you want to delete the record ?')){
      api.deleteData('api/Subjects/DeleteSubject?id=' + props.obj.subjectID)
      .then(res => {
        if (res.data.status === 'Delete') {
            // (async () => infoWrapper('Record deleted successfully!!'))();
            document.getElementById("Row_" + props.obj.subjectID).remove();
        }
      })
      .catch(error=>{
        (async () => await infoWrapper("Error while deleting Subject."))();
      })
    }
  }
  const EditSubject = (e)=>{
    e.preventDefault();
    props.forEdit(props.obj.subjectID)
  };
  return (
    <tr id={rowID}>
      <td>
        {props.obj.code}
      </td>
      <td>
        {props.obj.subjectName}
      </td>
      <td>
        {props.obj.status}
      </td>
      <td>
        <button title="Edit" type="button" onClick={EditSubject} className="btn-floating btn-sm btn-default m-1">
          <i className="fas fa-pen text-white"></i>
        </button>
        <button title="Delete" type="button" onClick={DeleteSubject} className="btn-floating btn-sm btn-danger m-1">
          <i className="fas fa-trash text-white"></i>
        </button>
      </td>
    </tr>
  );
}

export class SubjectsList extends Component {
  static DisplayName = SubjectsList.name
  constructor(props){
    super(props)
    this.state = { Subjects: []}
  }
  componentDidMount(){
    //Get Subjects List
    api.fetchData('api/Subjects/SubjectsList')
    .then(response => {
      let data = response.data;
      this.setState({ Subjects: data });
      $('#dtSubjectsList').DataTable();
      $('.dataTables_length').addClass('bs-select');
    })  
    .catch(error=>{
      console.log(error); 
    })
  }
  onAddSubject = ()=>{
    this.props.forAddorEdit(0)
  }
  forEditSubject = (id)=>{
    this.props.forAddorEdit(id)
  }
  render() {
    const Subjectslist = this.state.Subjects.map(Subject => <TableRow obj={Subject} key={Subject.subjectID} forEdit={this.forEditSubject}/>)
    return (
      <Fragment>
        <div className="row d-flex justify-content-between card-header align-items-center">
          <h5 className="">Subjects</h5>
            <button className="btn btn-default btn-md btn-rounded" onClick={this.onAddSubject}>Add Subject</button>
        </div>
        { this.state.Subjects.length >0 ?
          <div className="row">
            <div className="col-sm-12" className="table-responsive">
              <table id="dtSubjectsList" className="table table-striped table-bordered table-sm" cellSpacing="0" width="100%" role="grid" aria-describedby="dtSubjectsList_info" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th className="th-sm">Subject Code</th>
                    <th className="th-sm">Subject Name</th>
                    <th className="th-sm">Status</th>
                    <th className="th-sm">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Subjectslist}
                </tbody>
              </table>
            </div>
          </div>:<p>No Data found to Display</p>
        }
      </Fragment>
    )
  }
}

const Subject=()=> {
  const [isAddorEdit, setIsAddorEdit] = useState(false);
  const [addorEditId, setAddorEditId] = useState(0);

  const onAddOrEdit = (id)=>{
    setIsAddorEdit(true)
    setAddorEditId(id)
  };
  const forList = ()=>{
    setIsAddorEdit(false)
  }

  return (
    <Fragment>
      { !isAddorEdit && <SubjectsList forAddorEdit={onAddOrEdit}/> }
      { isAddorEdit && <AddorUpdateSubject id={addorEditId} closeAddorEdit={forList}/>}
    </Fragment>
  )
};
export default Subject;