import React, { useState, Fragment, useEffect } from 'react';
import axios from 'axios';
import { infoWrapper } from '../InfoAlert';

export const Register = (props) => {
    const [email, setEmail] = useState('');
    const [notValidEmail, setNotValidEmail] = useState('');
    const [password, setPassowrd] = useState('');
    const [notValidPassword, setNotValidPassword] = useState('');
    const [confPassword, setConfPassowrd] = useState('');
    const [notValidConfPassword, setNotValidConfPassword] = useState('');

    const emailChangeHandler = (e) => {
        if(e.target.value.length <= 30){
            setEmail(e.target.value);
          }else{
            (async () => infoWrapper("The maximum characters should not exceed more than 30."))();
        }
        if (email !== '') {
            setNotValidEmail('');
        }
    };
    const passwordChangeHandler = (e) => {
        if(e.target.value.length <= 20){
            setPassowrd(e.target.value);
          }else{
            (async () => infoWrapper("The maximum characters should not exceed more than 20."))();
        }
        if (password !== '') {
            setNotValidPassword('');
        }
    };
    const ConfPasswordChangeHandler = (e) => {
        if(e.target.value.length <= 20){
            setConfPassowrd(e.target.value);
        }else{
            (async () => infoWrapper("The maximum characters should not exceed more than 20."))();
        }
        if (confPassword !== '') {
            setNotValidConfPassword('');
        }
    };
    useEffect(() => {
        const identifier = setTimeout(() => {
            if (confPassword !== "") {
                if (password !== confPassword) {
                    setNotValidConfPassword("The password and confirmation password do not match.");
                }
            }
        }, 1000);
        return () => {
            clearTimeout(identifier);
        };
    }, [confPassword, password]);
    const validateForm = () => {
        let isValid = true;
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (email === '') {
            setNotValidEmail("Please enter Email.");
            isValid = false;
        }
        else if (email !== '' && !pattern.test(email)) {
            isValid = false;
            setNotValidEmail("Please enter valid Email.");
        }
        if (password === '') {
            setNotValidPassword("Please eneter password.");
            isValid = false;
        }
        else if (password !== '' && password.trim().length < 8) {
            setNotValidPassword("The Password Must Contain 8 or More Characters.");
            isValid = false;
        }
        if (confPassword === '') {
            setNotValidConfPassword("Please eneter confirm password.");
            isValid = false;
        }
        else if (password !== confPassword) {
            setConfPassowrd('');
            setNotValidConfPassword("The password and confirmation password do not match.");
            isValid = false;
        }
        return isValid;
    };
    const submitHandler = (e) => {
        e.preventDefault();
        if (email === '' && password === '' && confPassword === '') {
            setNotValidEmail("Please enter Email.");
            setNotValidPassword("Please eneter password.");
            setNotValidConfPassword("Please eneter confirm password.");
        } else if (validateForm()) {
            console.log("Email: " + email + ", Password: " + password);
            let User = {
                Username: email,
                Email: email,
                Password: password
            }
            sendRegisterRequest(User);
        }
    };
    const sendRegisterRequest = async (User) => {
        axios.post('/api/AuthManagement/Register', User)
            .then(res => {
                console.log(res.data);
                if (res.data.status === "Success") {
                    (async () => await infoWrapper(res.data.message))();
                    props.forClose();
                } else {
                    (async () => await infoWrapper(res.data.message))();
                }
            })
            .catch(err => {
                console.error(err);
                (async () => await infoWrapper("Invalid Request."))();
            })
    };
    const onLoginHandler = (e) => {
        e.preventDefault();
        props.onLogin();
    };
    const onClose = (e) => {
        e.preventDefault();
        props.forClose();
    }
    return (
        <Fragment>
            <div id="modalRegisterForm" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                aria-hidden="true">
                <div>
                    <div className="custom-header text-center">
                        <h4 className="modal-title w-100 font-weight-bold text-default">Sign up</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" onClick={onClose}>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body mx-1">
                        <form onSubmit={submitHandler}>

                            <div className="md-form" style={{textAlign:"left"}}>
                                <i className="fas fa-envelope prefix grey-text"></i>
                                <input type="email" id="defaultForm-email" className="form-control" value={email} onChange={emailChangeHandler} />
                                <label htmlFor="defaultForm-email">Email Address</label>
                                <span className="help-block text-danger" style={{paddingLeft:"40px"}}>{notValidEmail}</span>
                            </div>
                            <div className="md-form" style={{textAlign:"left"}}>
                                <i className="fas fa-lock prefix grey-text"></i>
                                <input type="password" id="defaultForm-pass" className="form-control" value={password} onChange={passwordChangeHandler} />
                                <label htmlFor="defaultForm-pass">Password</label>
                                <span className="help-block text-danger" style={{paddingLeft:"40px"}}>{notValidPassword}</span>
                            </div>
                            <div className="md-form" style={{textAlign:"left"}}>
                                <i className="fas fa-lock prefix grey-text"></i>
                                <input type="password" id="defaultForm-confirmPass" className="form-control" value={confPassword} onChange={ConfPasswordChangeHandler} />
                                <label htmlFor="defaultForm-confirmPass">Confirm password</label>
                                <span className="help-block text-danger" style={{paddingLeft:"40px"}}>{notValidConfPassword}</span>
                            </div>
                            <div className="text-center">
                                <button className="btn btn-default btn-rounded my-4" type="submit" onClick={submitHandler}>Register</button>

                                <p>Already a member?&nbsp;
                                    <a href="" className="text-default" onClick={onLoginHandler} data-toggle="modal" data-dismiss="modal">Login</a>
                                </p>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Register;