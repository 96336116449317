import React, { Fragment } from 'react';

export const Features  = () => {
  return (
    <Fragment>
      <section className="content-area" data-aos="fade-up" data-aos-duration="2000">
        <h1 className="text-center pt-3 text-default h1">Features &amp; Benefits</h1>
      </section>
      <main data-aos="fade-up" data-aos-duration="2000" className="container">
        <section className="p-md-3 mx-md-5">
          <div className="row d-flex justify-content-between align-items-center">
            <div className="col-md-8 mb-4 text-justify">
              <p className="pt-2">
                PracMed is the new and innovative Australian based medical exam resource provider.
              </p>
              <p className="pt-2">
                PracMed provides high quality question and answer content that is 
                aimed at all levels of medicine from medical students to junior doctors, 
                basic trainees or even practising doctors wanting to change medical career paths.
              </p>
              {/* <p className="pt-2">
                Welcome to our first stage website! For now we are getting things off the ground with a General Practice
                focused website. We would appreciate your feedback about the website about what you would like to see
                added or changed.
              </p>
              <p className="pt-2">
                Currently the website is suited to any doctors that are planning on sitting the GP Entry exams in 2016.
              </p> */}
            </div>
            <div className="col-md-4 col-lg-4 d-flex justify-content-center mb-md-0 mb-5">
              <i className="fa fa-laptop-medical text-default fa-10x"></i>
            </div>
          </div>
          {/* <p className="h2 text-center text-dark mt-4 mb-3">Features</p> */}
          <div className="row pt-3" id='featuresPageItems'>
            <div className="col-md-4 mx-auto mb-5">
              <h5 className="font-weight-bold mb-3">
                <i className="far fa-clock text-default pr-2"></i> Timer
              </h5>
              <p className="mb-lg-0">
                Allows you to track your performance with time limits.
              </p>
            </div>
            <div className="col-md-4 mx-auto mb-5">
              <h5 className="font-weight-bold mb-3">
                <i className="fas fa-route text-default pr-2"></i> Score Tracking
              </h5>
              <p className="mb-lg-0">
                Allows you to track your mark as you go as well as give a final score.
              </p>
            </div>
            <div className="col-md-4 mx-auto mb-5">
              <h5 className="font-weight-bold mb-3">
                <i className="fas fa-brain text-default pr-2"></i> Instant Knowledge
              </h5>
              <p className="mb-md-0">
                Instant review of responses following answer submission and section 
                completion for efficient knowledge acquisition.
              </p>
            </div>
            <div className="col-md-4 mx-auto mb-5">
              <h5 className="font-weight-bold mb-3">
                <i className="fas fa-expand-arrows-alt text-default pr-2"></i> Knowledge Stretch
              </h5>
              <p className="mb-md-0">
                Questions delivered at random across all chosen topics.
              </p>
            </div>
            <div className="col-md-4 mx-auto mb-5">
              <h5 className="font-weight-bold mb-3">
                <i className="fas fa-angle-left text-default"></i>
                <i className="fas fa-angle-right text-default pr-2"></i>Easy Navigation
              </h5>
              <p className="mb-md-0">
                Well set out navigation tool that allows you to skip questions and review 
                previous questions with ease.
              </p>
            </div>
          {/* </div> */}
          {/* <div className="row pt-3"> */}
            <div className="col-md-4 mx-auto mb-5">
              <h5 className="font-weight-bold mb-3">
                <i className="far fa-flag text-default pr-2"></i> Flags
              </h5>
              <p className="mb-lg-0">
                Question flagging will allow you to revisit questions for further learning, 
                or to complete them at a later stage of study.
              </p>
            </div>
            <div className="col-md-4 mx-auto mb-5">
              <h5 className="font-weight-bold mb-3">
                <i className="far fa-sticky-note text-default pr-2"></i> Notes
              </h5>
              <p className="mb-lg-0">
                A text box placed below each question and answer allows individual 
                users to write own revision notes.
              </p>
            </div>
            <div className="col-md-4 mx-auto mb-5">
              <h5 className="font-weight-bold mb-3">
                <i className="fas fa-diagnoses text-default pr-2"></i> Exam Mode
              </h5>
              <p className="mb-md-0">
                Test your exam skills by setting a time limit for the number of questions 
                in order to prepare you for your medical examinations.
              </p>
            </div>
            {/* <div className="col-md-4 mx-auto mb-5">
              <h5 className="font-weight-bold mb-3">
                <i className="fas fa-people-carry text-default pr-2"></i> Overall Performance Against Your Peers
              </h5>
              <p className="mb-md-0">
                Keeping track of your own performance is important but this will allow you to keep track of your
                performance against the rest of the exam resource users.</p>
            </div> */}
            <div className="col-md-4 mx-auto mb-5">
              <h5 className="font-weight-bold mb-3">
                <i className="fas fa-chart-line text-default pr-2"></i>Performance Analysis
              </h5>
              <p className="mb-md-0">
                Keeps track of individual user performance and identifies individual strengths and weaknesses for targeted revision.
              </p>
            </div>
            <div className="col-md-4 mx-auto mb-5">
              <h5 className="font-weight-bold mb-3">
                <i className="fas fa-people-carry text-default pr-2"></i>Australian made
              </h5>
              <p className="mb-md-0">
                Our questions and answers are written by practising Australian doctors 
                and verified by specialists in their fields.
              </p>
            </div>
            <div className="col-md-4 mx-auto mb-5">
              <h5 className="font-weight-bold mb-3">
              <i class="fas fa-calendar-check text-default pr-2"></i>Up to date
              </h5>
              <p className="mb-md-0">
                Our questions and answers are based on current Australian guidelines.
              </p>
            </div>
            <div className="col-md-4 mx-auto mb-5"></div>
          </div>
        </section>
      </main>
    </Fragment>
  )
};