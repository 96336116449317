import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { DemoExamPracticeMode } from './DemoExamPracticeMode';
import DemoExamReport from './DemoExamReport';
import { infoWrapper } from '../InfoAlert';

export class ExamsDemo extends Component {
  constructor(props) {
    super(props)
    let id = props.onSelectExam.location.examId;
    if(id === undefined || id === null){ // task for page reload or refresh
      let examDetails  = JSON.parse(localStorage.getItem('demoExam'));
      if(examDetails === null || examDetails === undefined){
        this.state = {
          action: 'Invalid',
          examId: '', 
          examName: '',
        }
      }else{
        this.state = {
          action: examDetails.action,
          examId: examDetails.examId, 
          examName: examDetails.examName,
          selectedQuestions: [],
          totalNoOfQuestions: 0,
          selectedNoOfQuestions: 0,
          report: ''
        }
      }
    }else{
      localStorage.setItem('demoExam', JSON.stringify({ action: 'start', examId: id, examName:props.onSelectExam.location.examName}));
      this.state = {
        action: 'start',
        examId: id, 
        examName: props.onSelectExam.location.examName,
        selectedQuestions: [],
        totalNoOfQuestions: 0,
        selectedNoOfQuestions: 0,
        report: ''
      }
    }
  }
  componentDidMount(){
    if(this.state.examId !== '' && this.state.examId !== null && this.state.examId !== undefined ){
      this.getExamQuestions(this.state.examId);
    }
  }
  getExamQuestions = (id) =>{
    axios.get('/Api/ExamsDemo/getExamQuestionsList?id='+ id)
    .then(res=>{
      console.log(res.data);
      let Questions = [];
      res.data.map((question, index) => {
        let vid = index;
        Questions[vid] = { vid: vid, 
          questionID: question.questionID,
          AnswerValue: '',
          isAnswered: false,
          isAnswerCorrect: false,
          notes: ""
        }
      });
      this.setState({selectedQuestions:Questions, totalNoOfQuestions:Questions.length });
      console.log(res.data);
    })
    .catch(err=>{
      console.log("data not found");
    });
  }

  startDemo = () =>{
    if(this.state.totalNoOfQuestions === 0 ){
      (async () => infoWrapper('No Questions Available fo the Exam!'))();
    }else if(this.state.selectedNoOfQuestions === 0){
      (async () => infoWrapper('Please Choose the Questions for Exam Demo!'))();
    }else if(this.state.totalNoOfQuestions < this.state.selectedNoOfQuestions){
      this.setState({action:'ExamDemoView', selectedNoOfQuestions: this.state.totalNoOfQuestions });
    }else{
      this.setState({action:'ExamDemoView'});      
    }
  }
  onEndExamDemo = (reportData) =>{
    this.setState({action:'Report', report: reportData});
    console.log(reportData);
  }
  selectedQuestionsChangeHandle = (e)=>{
    this.setState({selectedNoOfQuestions: +e.target.value});
  }
  
  render() {
    const {action, examId, examName, selectedQuestions, selectedNoOfQuestions, report, totalNoOfQuestions}  = this.state;
    return (<section className="banner-area" data-aos="fade-up" data-aos-duration="2000">
    <div className="container">
    { action === 'start' && <div>
        <div className="my-3">
          <h4 className="text-default">{examName}</h4>
          <p>Description about demo of the exams here!</p>
        </div>
        <div className="card">
          <ul className="list-group list-group-flush options">
            <li className="list-group-item mode-exam">
              Choose Questions
            </li>
            <li className="list-group-item">
              <div className="custom-control custom-radio">
              <select className="" name="sQ" value={selectedNoOfQuestions} onChange={this.selectedQuestionsChangeHandle}>
                <option value='0'>--Choose no of Questions--</option>
                {totalNoOfQuestions === 0 && <option value='0'>No Questions Found for this Exam.</option>}
                {totalNoOfQuestions >= 5 && <option value='5'>5 Questions</option>}
                {totalNoOfQuestions >= 10 && <option value='10'>10 Questions</option>}
                {totalNoOfQuestions >= 15 && <option value='15'>15 Questions</option>}
                {totalNoOfQuestions < 5 && <option value={totalNoOfQuestions}>{totalNoOfQuestions} Questions</option>}
              </select>
              </div>
            </li>
          </ul>
        </div>
        <button className='btn btn-primary btn-rounded' onClick={this.startDemo}>Start Exam</button>
      </div>
    }
    { action === 'ExamDemoView' && <div>
        <div className="my-3">
          <h4 className="text-default">{examName}</h4>
          <DemoExamPracticeMode ExamID={examId} ExamName={examName} questions={selectedQuestions} noOfQuestionSelected={selectedNoOfQuestions} endExam={this.onEndExamDemo} />
        </div>
      </div>
    }
    { action === 'Report' && <div>
        <div className="my-3">
          <h4 className="text-default">{examName}</h4>
          <DemoExamReport total={selectedNoOfQuestions} reportData={report} UserExamID={examId} />
        </div>
        <Link className="btn btn-primary btn-md btn-rounded" to="/home" >Go to Home</Link>
        <Link className='btn btn-primary btn-md btn-rounded' to={{pathname:'/exam-duration', examId: examId, image: ''}}>Buy Now</Link>
      </div>
    }
    { action === 'Invalid' && <div>
        <div className="my-3">
          <h4 className="text-default">Please Choose the exam from popular exams in Home!</h4>
          <Link className="btn btn-primary btn-md btn-rounded" to="/home" >Back to Home</Link>
        </div>
      </div>
    }
    </div>
  </section>);
  }
}
