import React, { Component, Fragment } from 'react';
import * as api from './API/Api';
import { Link } from 'react-router-dom';

export class BannerSection extends Component {
  static displayName = BannerSection.name;
  constructor(props) {
    super(props)
  
    this.state = {
      showSerach: false,
      searchText: '',
      ActiveExams: [],
      FilteredExams:[]       
    }
  }
  componentDidMount() {
  api.fetchData('/Api/Exams/ActiveExamsList')
    .then(res => {
      this.setState({ ActiveExams: res.data, FilteredExams: res.data});
    })
    .catch(err => {
      console.log(err)
    });
  }
  showPopupHandler = () => {
    this.setState({ showSerach: true})
  }
  hidePopupHandler = () => {
    this.setState({ showSerach: false})
  }
  searchingExams = () =>{
    console.log("ok");
  }
  searchHandler = (e)=>{
    if(e.target.value.length > 0 && e.target.value.length < 3){
      this.setState({ searchText: e.target.value, FilteredExams: this.state.ActiveExams });
      if(!this.state.showSerach){
        this.setState({ showSerach: true});
      }
    }
    else if(e.target.value.length >= 3){
      let filteredExams = this.state.ActiveExams.filter(exam => exam.examName.toLowerCase().includes(e.target.value.toLowerCase()) === true);
      this.setState({ searchText: e.target.value, FilteredExams: filteredExams });
      if(!this.state.showSerach){
        this.setState({ showSerach: true});
      }
    }
    else{
      this.setState({ searchText: e.target.value, showSerach: false });
    }
  }
  render () {
    const { showSerach, searchText, FilteredExams } = this.state;
    return (
        <section className="banner-area" data-aos="fade-up" data-aos-duration="2000">
             <div className="container">
                 <div className="row align-items-center">
                     <div className="col-lg-5">
                        <div className="banner-main" data-aos="fade-up" data-aos-duration="2000">
                             <div className="banner-content">
                                 <h1>Exams Made <span>Easy</span></h1>
                                 <p>PracMed is a medical revision resource that has been created to help you pass your exams.</p>
                             </div>
                            <div className="search-box" data-aos="fade-up" data-aos-duration="2000">
                                <form action="" onSubmit={this.searchingExams}>
                                  <input type="search" name="" placeholder="Find my exam..." id="searchInput" value={searchText} onChange={this.searchHandler} />
                                  <button><img className="ser-img" src="assets/images/search.png" alt="img" /></button>
                                </form>
                                {showSerach && <div onMouseLeave={this.hidePopupHandler} className="report-question-popup-inner">
                                  <div className="dropdown-primary">
                                    {FilteredExams.length > 0 ?
                                      FilteredExams.map((item, index) => {
                                        let slNo = index + 1;
                                        return <Link className="dropdown-item" key={slNo} to={{ pathname: '/exam-duration', examId: item.examID }}>{item.examName}</Link>
                                      })
                                    : <p>No exams found.</p>}
                                  </div>
                                </div>}
                            </div>
                          </div>
                     </div>
                     <div className="col-lg-7 bnr-mobile-bg">
                         <div className="banner-img" data-aos="fade-up" data-aos-duration="2000">
                             <img src="assets/images/banner.png" alt="img" />
                         </div>
                     </div>
                 </div>
             </div>
         </section>
    );
  }
}