import React, { Component, Fragment, fullWidth,  maxWidth } from 'react';
import Dialog from "@material-ui/core/Dialog";
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DialogTitle } from './DialogTitle';

//const YourDialog = ({ show, proceed, confirmation, options }) => {
export class FormDialog extends Component {
    static DisplayName = FormDialog.name
    
    constructor(props) {   
        super(props)
        this.state = {
            open: props.open,
            title: props.title
        }
    }
    
    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
        this.props.closeDialog();
    };

    render() {
        return (
            <Dialog fullWidth={fullWidth}
                maxWidth={maxWidth} onClose={this.handleClose} open={this.state.open} >
                <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                    {this.state.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Fragment>
                            {this.props.children}
                        </Fragment>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

//YourDialog.propTypes = {
//    show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
//    proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
//    confirmation: PropTypes.string,  // arguments of your confirm function
//    options: PropTypes.object        // arguments of your confirm function
//}

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
//export default confirmable(YourDialog);