import React, { Fragment, useState } from 'react';
import { BannerSection } from './BannerSection';
import { QuestionBankSection } from './QuestionBankSection';
import { SpecialOfferSection } from './SpecialOfferSection';
import { PopulerExamSection } from './PopulerExamSection';
import { ConfirmEmail } from './AccountManagement/ConfirmEmail';

export const Home = () => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    const email = params.get('email');
    return (
        <Fragment>
            {token !== "" && email !== "" && <ConfirmEmail token={token} email={email} />}
            <BannerSection />
            <QuestionBankSection />
            <PopulerExamSection />
            <SpecialOfferSection />
        </Fragment>
    );
}
