import React, { useState, useContext, useEffect } from 'react';
import AuthContext from  '../../store/auth-context';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import $ from 'jquery';
import * as api from '../../API/Api';
import ViewOrderDetails from './ViewOrderDetails';

export const PurchaseHistory = () => {
  const authCtx = useContext(AuthContext);
  const [purchaseRecords, setPurchaseRecords] = useState([]);
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [viewDetailsOrderId, setViewDetailsOrderId] = useState(0);
  useEffect(() => {
    // call API for getting records;
    // $('#dtPuchaseRecords').DataTable();
    // $('.dataTables_length').addClass('bs-select');
    return () => {
      // clear local variable data;
    }
  }, []);
  const onViewOrderDetails = (e)=>{
    e.preventDefault();
    setViewDetailsOrderId(+e.target.value);
    setShowDetailsPopup(true);
  };
  const closeViewOrderDetails = ()=>{
    setShowDetailsPopup(false);
  }

  return (
    <section className="mb-5" data-aos="fade-up" data-aos-duration="2000">
      <div className="container">
        <div className="my-3 d-flex justify-content-between">
          <h4 className="text-default">Purchase History</h4>
        </div>
        <div className="my-3 d-flex justify-content-between">
          <div className="table-responsive">  
            <table id="dtPuchaseRecords" className="table table-striped table-bordered table-sm" cellSpacing="0" width="100%" role="grid" aria-describedby="dtPuchaseRecords_info" style={{ width: "95%" }}>
              <thead>
                <tr>
                  <th className="th-sm">Order Id</th>
                  <th className="th-sm">Exam Name</th>
                  <th className="th-sm">Payment Date</th>
                  <th className="th-sm">Amount</th>
                  <th className="th-sm">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>32232</td>
                  <td>MCSE Finals</td>
                  <td>10-Dec-2021</td>
                  <td> $10 </td>
                  <td>Successful</td>
                  <td><button type='button' className='button-link' title="Order Details" value='32232' onClick={onViewOrderDetails}>View Details</button></td>
                </tr>
                {/* display List */}
              </tbody>
            </table>
          </div>
        </div>
        {showDetailsPopup && <ViewOrderDetails forDetails={showDetailsPopup} orderId={viewDetailsOrderId} forClose={closeViewOrderDetails}/>}
      </div>
    </section>
  )
}
export default PurchaseHistory;