import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import Dialog from "@material-ui/core/Dialog";
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DialogTitle } from './DialogTitle';

const YourDialog = ({ show, proceed, confirmation, callback }) => {
    const customproceed = (e) => {
        proceed(e);
        if (callback) {
            callback();
        }
    };
    return <Dialog onClose={() => customproceed(false)} open={show}>
        <DialogTitle id="customized-dialog-title" onClose={() => customproceed(false)}>
            Information
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {confirmation}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => customproceed(false)} color="primary">
                OK
          </Button>
           
        </DialogActions>
    </Dialog>
}

YourDialog.propTypes = {
    show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
    confirmation: PropTypes.string,  // arguments of your confirm function
    options: PropTypes.object        // arguments of your confirm function
}

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
export default confirmable(YourDialog);