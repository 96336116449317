import React, {Fragment} from 'react';
import { ChangePassword } from './ChangePassword';
import { ExamDetails } from './ExamDetails';
import { UsersData } from './UsersData';
import { ManageUserRole } from './ManageUserRole';
import { TakeExam } from '../ExamRevision/TakeExam';
import PersonalData from './PersonalData';
import Exams from '../MasterPages/Exam';
import Subject from '../MasterPages/Subject';
import ExamQuestionGroup from '../MasterPages/ExamQuestionGroup';
import MedSchool from '../MasterPages/MedSchool';
import Grade from '../MasterPages/Grade';
import Duration from '../MasterPages/Duration';
import States from '../MasterPages/States';
import Country from '../MasterPages/Country';
import Year from '../MasterPages/Year';
import QuestionBank from '../MasterPages/QuestionBank';
import Tag from '../MasterPages/Tags';
import ExamDuration from '../MasterPages/ExamDuration';
import ExamSubject from '../MasterPages/ExamSubject';
import PurchaseHistory from './PurchaseHistory/PurchaseHistory';
import Discount from '../MasterPages/Discount';

export class ProfileMenu extends React.Component {
  static displayName = ProfileMenu.name;
  constructor(props) {
    super(props);
    let dp = JSON.parse(sessionStorage.getItem('dp'));
    if(dp === null || dp === undefined){
      dp = {
        User: props.user,
        UserType: props.userType,
        displayPage: 'ExamDetails',
        isExamTaking: false,
        Exam: '',
        action: '',
        isRestartExam: false,
        isViewExam: false,
        isResumeExam: false
      };
      sessionStorage.setItem('dp', JSON.stringify(dp));
    }
    this.state = dp;
  }
  changePage =(e)=>{
    let dp = this.state;
    this.setState({
      displayPage: e.target.id
    });
    dp.displayPage = e.target.id;
    sessionStorage.setItem('dp', JSON.stringify(dp));
  }
  takeExam = (obj)=>{
    let dp = this.state;
    this.setState({isExamTaking: true, 
    Exam: obj.exam, 
    isRestartExam: obj.isRestartExam, 
    isViewExam: obj.isViewExam, 
    isResumeExam : obj.isResumeExam});
    dp.isExamTaking = true;
    dp.Exam =  obj.exam;
    dp.isRestartExam = obj.isRestartExam;
    dp.isViewExam = obj.isViewExam;
    dp.isResumeExam  = obj.isResumeExam;
    sessionStorage.setItem('dp', JSON.stringify(dp));
  }
  closeExamRevision = ()=>{
    let dp = this.state;
    this.setState({displayPage: 'ExamDetails', isExamTaking: false, Exam: ''});
    dp.displayPage = 'ExamDetails';
    dp.isExamTaking = false;
    dp.Exam = '';
    sessionStorage.setItem('dp', JSON.stringify(dp));
  }
  changeAction = (e) =>{
    let dp = this.state;
    this.setState({action: e.target.id});
    dp.action = e.target.id;
    sessionStorage.setItem('dp', JSON.stringify(dp));
  }
  componentWillUnmount(){
    sessionStorage.removeItem('dp');
  }
  
  render() {
    const {displayPage, User, UserType, isExamTaking, Exam, isRestartExam, isViewExam, isResumeExam} = this.state;
    return (
    <Fragment> 
            {isExamTaking ? <TakeExam closeExamRevision={this.closeExamRevision} exam={Exam} isRestartExam={isRestartExam} isViewExam={isViewExam} isResumeExam={isResumeExam} />:
      <Fragment>
        <section className="content-area" data-aos="fade-up" data-aos-duration="2000">
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-dark default-color">
              <button className="navbar-toggler" 
                type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                  <li className={`nav-item ${displayPage === 'ExamDetails'?'active':''}`}>
                    <a className="nav-link" href="" data-toggle="modal" id="ExamDetails" onClick={this.changePage}>
                      Exam Details {displayPage === 'ExamDetails'?<span className="sr-only">(current)</span>:'' }
                    </a>
                  </li>
                  <li className={`nav-item ${displayPage === 'PersonalDetails'?'active':''}`}>
                    <a className="nav-link" href="" data-toggle="modal" id="PersonalDetails" onClick={this.changePage}>
                      Personal Details {displayPage === 'PersonalDetails'?<span className="sr-only">(current)</span>:'' }
                    </a>
                  </li>
                  <li className={`nav-item ${displayPage === 'ChangePassword'?'active':''}`}>
                    <a className="nav-link" href="" data-toggle="modal" id="ChangePassword" onClick={this.changePage}>
                      Change Password {displayPage === 'ChangePassword'?<span className="sr-only">(current)</span>:'' }
                    </a>
                  </li>
                  <li className={`nav-item ${displayPage === 'PurchaseHistory'?'active':''}`}>
                    <a className="nav-link" href="" data-toggle="modal" id="PurchaseHistory" onClick={this.changePage}>
                    Purchase History {displayPage === 'PurchaseHistory'?<span className="sr-only">(current)</span>:'' }
                    </a>
                  </li>
                  {(UserType === "Admin" || UserType === "Employee") && <li className={`nav-item ${displayPage === 'MastersDataEntry'?'active':''}`}>
                    <a className="nav-link" href="" data-toggle="modal" id="MastersDataEntry" onClick={this.changePage}>
                      Reference Data {displayPage === 'MastersDataEntry'?<span className="sr-only">(current)</span>:'' }
                    </a>
                  </li>}
                  {UserType === "Admin"  &&<li className={`nav-item ${displayPage === 'ManageUserRole'?'active':''}`}>
                    <a className="nav-link" href="" data-toggle="modal" id="ManageUserRole" onClick={this.changePage}>
                      Manage Role {displayPage === 'ManageUserRole'?<span className="sr-only">(current)</span>:'' }
                    </a>
                  </li>}
                  {UserType === "Admin"  &&<li className={`nav-item ${displayPage === 'UsersData'?'active':''}`}>
                    <a className="nav-link" href="" data-toggle="modal" id="UsersData" onClick={this.changePage}>
                      Users List {displayPage === 'UsersData'?<span className="sr-only">(current)</span>:'' }
                    </a>
                  </li>}
                </ul>
              </div>
            </nav>
            {displayPage === 'MastersDataEntry' && <Fragment>
              <nav id="sidebarMenu" className="collapse d-lg-none collapse">
                <div className="list-group list-group-flush mx-3">
                  <button type="button" className={`list-group-item list-group-item-action btn btn-default ${this.state.action === 'Exam'? 'active':''}`} id="Exam" onClick={this.changeAction}>Exams</button>
                  <button type="button" className={`list-group-item list-group-item-action btn btn-default ${this.state.action === 'QuestionBank'? 'active':''}`} id="QuestionBank" onClick={this.changeAction}>Question Bank</button>
                  {/* <button type="button" className={`list-group-item list-group-item-action btn btn-default ${this.state.action === 'ExamSubjects'? 'active':''}`} id="ExamSubjects" onClick={this.changeAction}>Exam Subjects</button> */}
                  <button type="button" className={`list-group-item list-group-item-action btn btn-default ${this.state.action === 'ExamDuration'? 'active':''}`} id="ExamDuration" onClick={this.changeAction}>Exam Duration</button>
                  <button type="button" className={`list-group-item list-group-item-action btn btn-default ${this.state.action === 'Subject'? 'active':''}`} id="Subject" onClick={this.changeAction}>Subjects</button>
                  <button type="button" className={`list-group-item list-group-item-action btn btn-default ${this.state.action === 'Duration'? 'active':''}`} id="Duration" onClick={this.changeAction}>Duration</button>
                  <button type="button" className={`list-group-item list-group-item-action btn btn-default ${this.state.action === 'MedSchool'? 'active':''}`} id="MedSchool" onClick={this.changeAction}>Medical Schools</button>
                  <button type="button" className={`list-group-item list-group-item-action btn btn-default ${this.state.action === 'Grade'? 'active':''}`} id="Grade" onClick={this.changeAction}>Grades</button>
                  <button type="button" className={`list-group-item list-group-item-action btn btn-default ${this.state.action === 'Country'? 'active':''}`} id="Country" onClick={this.changeAction}>Countries</button>
                  <button type="button" className={`list-group-item list-group-item-action btn btn-default ${this.state.action === 'States'? 'active':''}`} id="States" onClick={this.changeAction}>States</button>
                  <button type="button" className={`list-group-item list-group-item-action btn btn-default ${this.state.action === 'Year'? 'active':''}`} id="Year" onClick={this.changeAction}>Years</button>
                </div>
              </nav>
              </Fragment>
            }
          </div>
        </section>
        <Fragment>
          { displayPage === 'ExamDetails' && <ExamDetails User={User} takeExam={this.takeExam}/> }
          { displayPage === 'PersonalDetails'&& <PersonalData User={User} /> }
          { displayPage === 'ChangePassword' && <ChangePassword/> }
          { displayPage === 'PurchaseHistory' && <PurchaseHistory/> }
          { displayPage === 'MastersDataEntry' && (UserType === "Admin" || UserType === "Employee") && <Fragment>
            <main data-aos="fade-up" data-aos-duration="2000" className="container my-2">
              <div className="row">
                <div className="col-lg-3 d-none d-lg-block">
                  <div className="list-group list-group-flush">
                    <button type="button" className={`list-group-item list-group-item-action btn btn-default ${this.state.action === 'Exam'? 'active':''}`} id="Exam" onClick={this.changeAction}>Exams</button>
                    <button type="button" className={`list-group-item list-group-item-action btn btn-default ${this.state.action === 'QuestionBank'? 'active':''}`} id="QuestionBank" onClick={this.changeAction}>Question Bank</button>
                    {/* <button type="button" className={`list-group-item list-group-item-action btn btn-default ${this.state.action === 'ExamSubjects'? 'active':''}`} id="ExamSubjects" onClick={this.changeAction}>Exam Subjects</button> */}
                    <button type="button" className={`list-group-item list-group-item-action btn btn-default ${this.state.action === 'ExamDuration'? 'active':''}`} id="ExamDuration" onClick={this.changeAction}>Exam Duration</button>
                    <button type="button" className={`list-group-item list-group-item-action btn btn-default ${this.state.action === 'Subject'? 'active':''}`} id="Subject" onClick={this.changeAction}>Subjects</button>
                    <button type="button" className={`list-group-item list-group-item-action btn btn-default ${this.state.action === 'Duration'? 'active':''}`} id="Duration" onClick={this.changeAction}>Duration</button>
                    <button type="button" className={`list-group-item list-group-item-action btn btn-default ${this.state.action === 'Discount'? 'active':''}`} id="Discount" onClick={this.changeAction}>Discounts</button>
                    <button type="button" className={`list-group-item list-group-item-action btn btn-default ${this.state.action === 'MedSchool'? 'active':''}`} id="MedSchool" onClick={this.changeAction}>Medical Schools</button>
                    <button type="button" className={`list-group-item list-group-item-action btn btn-default ${this.state.action === 'Grade'? 'active':''}`} id="Grade" onClick={this.changeAction}>Grades</button>
                    <button type="button" className={`list-group-item list-group-item-action btn btn-default ${this.state.action === 'Country'? 'active':''}`} id="Country" onClick={this.changeAction}>Countries</button>
                    <button type="button" className={`list-group-item list-group-item-action btn btn-default ${this.state.action === 'States'? 'active':''}`} id="States" onClick={this.changeAction}>States</button>
                    <button type="button" className={`list-group-item list-group-item-action btn btn-default ${this.state.action === 'Year'? 'active':''}`} id="Year" onClick={this.changeAction}>Years</button>
                  </div>
                </div>
                <div className="col-lg-9">
                  {this.state.action === 'Exam' && <Exams/>}
                  {this.state.action === 'ExamQuestionsGroup' && <ExamQuestionGroup/>}
                  {this.state.action === 'QuestionBank' && <QuestionBank/>}
                  {this.state.action === 'ExamSubjects' && <ExamSubject/>}
                  {this.state.action === 'ExamTags' && <Fragment>Exams Tags Under Progress</Fragment>}
                  {this.state.action === 'ExamDuration' && <ExamDuration/>}
                  {this.state.action === 'Subject' && <Subject/>}
                  {this.state.action === 'Tags' && <Tag/>}
                  {this.state.action === 'Duration' && <Duration/>}
                  {this.state.action === 'Discount' && <Discount/>}
                  {this.state.action === 'MedSchool' && <MedSchool/>}
                  {this.state.action === 'Grade' && <Grade/>}
                  {this.state.action === 'Country' && <Country/>}
                  {this.state.action === 'States' && <States/>}
                  {this.state.action === 'Year' && <Year/>}
                  {this.state.action === '' && <Fragment>Manage Reference Data</Fragment>}
                </div>
              </div>
            </main>
          </Fragment>}
          { displayPage === 'ManageUserRole' && <ManageUserRole/> }
          { displayPage === 'UsersData' && UserType === "Admin" && <UsersData/> }
        </Fragment>
      </Fragment>}
    </Fragment>)
  }
}