import React, {useState, useEffect} from 'react';
import * as api from '../API/Api';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import $ from 'jquery';

export const UsersData  = ()=>{
  const [Users, setUSERS] = useState([]);
  useEffect(()=>{
    api.fetchData('/Api/Users/GetUsers')
    .then(res => {
      setUSERS(res.data);
      $('#dtAllUsers').DataTable();
      $('.dataTables_length').addClass('bs-select');
    })
    .catch(err=>{
      console.log("Error while getting data")
    })
  },[]);

  return (
    <main data-aos="fade-up" data-aos-duration="2000" className="container my-2">
      <div className="d-flex justify-content-between card-header align-items-center">
        <h5 className="">List of Registered users</h5>
      </div>
      <div className='table-responsive'>
        <table id="dtAllUsers" className="table table-striped table-bordered table-sm" cellSpacing="0" width="100%" role="grid" aria-describedby="dtAllUsers_info" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th className="th-sm">Sl.No </th>
              <th className="th-sm">User Email ID</th>
              <th className="th-sm">Role Name</th>
            </tr>
          </thead>
          <tbody>
            {Users.map((user, index)=> {
              let SlNo = index +1;
              return <tr key={index}>
                <td>{SlNo}</td>
                <td>{user.userName}
                </td><td>{user.roleName}</td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
    </main>
  );
}

export default UsersData;