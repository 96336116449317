import axios from 'axios';
import React, { useState, useEffect } from 'react';
import * as api from '../API/Api';

let logoutTimer;
let RefreshTokenTimer;

const AuthContext = React.createContext({
  UserName: '',
  UserType: '',
  isLoggedIn: false,
  CartCount: 0,
  login: () => {},
  logout: () => {},
  CartCountHandler: () =>{}
});

export const AuthContextProvider = (props) => {
  const [userName, setUserName] = useState(null);
  const [userType, setUserType] = useState(null);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const [cartCount, setCartCount] = useState(0);

  useEffect(()=>{
    axios.get('/api/AuthLoginInfo/isLoggedIn')
    .then(res =>{
      setUserName(res.data.user);
      setUserType(res.data.userType);
      setUserIsLoggedIn(res.data.isLoggedIn);
    })
    .catch(err=>{
      console.log(err);
    })
    if(userIsLoggedIn){
      RefreshTokenTimer = setTimeout(RefreshToken, 20000);
    }else{
      clearTimeout(RefreshTokenTimer);
    }
  },[userIsLoggedIn]);

  useEffect(()=>{
    axios.get('/Api/CartItems/CartItemsCount')
    .then( res =>{
      setCartCount(res.data);
    })
    .catch(err=>{
      console.log(err);
    })
  },[cartCount, userIsLoggedIn]);

  const RefreshToken = () =>{
    axios.get('/Api/AuthManagement/RefreshToken')
    .then( res =>{
      if(res.status === 200){
        RefreshTokenTimer = setTimeout(RefreshToken, 10000);
      }else{
        clearTimeout(RefreshTokenTimer);
      }
    })
    .catch(err=>{
      console.log(err);
    })
  }

  const logoutHandler =() => {
    axios.get('/api/AuthLoginInfo/LogoutHandler')
    .then(res =>{
      setUserIsLoggedIn(res.data.isLoggedIn);
      setUserName(res.data.user);
      setUserType(res.data.userType);
      window.location.replace("/#");
    })
    .catch(err=>{
      console.log(err);
    });
    setUserIsLoggedIn(false);
    clearTimeout(RefreshTokenTimer);
  };
  const loginHandler = () => {
    setUserIsLoggedIn(true);
  };
  const CartCountHandler = ()=>{
    setCartCount(-1);
  };
  const contextValue = {
    UserName: userName,
    UserType: userType,
    isLoggedIn: userIsLoggedIn,
    CartCount: cartCount,
    login: loginHandler,
    logout: logoutHandler,
    CartCountHandler: CartCountHandler
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
