import React, { Component, Fragment, useState } from 'react'
import { confirmWrapper } from '../ConfirmAlert'
import { infoWrapper } from '../InfoAlert'
import * as api from '../API/Api';

export class AddorUpdateTag extends Component {
  static DisplayName = AddorUpdateTag.name
  constructor(props){
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.state = {
      Action:'',
      TagID:'',
      TagTitle:'',
      Status: '',
      ischecked: true
    }
  }
  componentDidMount(){
    const id = this.props.id
    if(id > 0){
      api.fetchData('/Api/Tags/TagdetailsById?id='+id)
      .then(res => {
        let data = res.data
        if (data.status ==='Deactivated'){
          this.setState({ischecked: false})
        }
        else{
          this.setState({ischecked: true})
        }
        this.setState({Action: 'Update', TagID: data.tagID, TagTitle: data.tagTitle, Status: data.status})
        console.log(data)
      })
    }
    else{
      this.setState({Action: 'Create', TagID: 0, TagTitle:'', Status: 'Activated'})
    }    
  }
  onSubmit(e){
    e.preventDefault()
    const Tag = {
      TagID: this.state.TagID,
      TagTitle:this.state.TagTitle,
      Status: this.state.Status
    }
    api.postData('/Api/Tags/AddorUpdateTag', Tag)
    .then(res => {
      if(this.state.TagID === 0)
        (async () => await infoWrapper("New Tag Added Successfully."))();
      else
        (async () => await infoWrapper("Data Updated Successfully."))();
      this.props.closeAddorEdit();
    })
    .catch(error=>{
      console.log(error); 
    })
  }
  changeTitle = (e)=>{ this.setState({ TagTitle : e.target.value})}
  changeStatus = (e)=>{ 
    if(this.state.ischecked){this.setState({ Status: 'Deactivated', ischecked: false})}
    else{this.setState({ Status: 'Activated', ischecked: true})}
  }
  onCancel(e){
    e.preventDefault()
    this.props.closeAddorEdit();
  }
  render() {
    const {Action, TagTitle, ischecked} = this.state;
    return (
      <div>
        {/* <h5 className="reference-data-form-heading">{Action} Tag</h5> */}
        <form className="reference-data-form" onSubmit={this.onSubmit}>
          <div>
            <label htmlFor="title">Tag Title</label>
            <input className="text-box single-line" 
            placeholder="Please enter Tag Title here"                    
            value={TagTitle}
            onChange={this.changeTitle}
            type="text" required/>
          </div>
          <div>
            <input type="checkbox"
             checked={ischecked}             
             onChange={this.changeStatus}/> Active.
          </div>
          <div className="buttn-align">
            <button className="btnSubmit" type="submit">{Action}</button>
            <button className="btnSubmit" onClick = {this.onCancel} type="reset">Cancel</button>
          </div>
        </form>
      </div>
    )
  }
}

const TableRow = (props)=>{
  const rowID = "Row_" + props.obj.tagID;

  const DeleteTag = async () => {
    if (await confirmWrapper('Are you sure that you want to delete the record ?')){
      api.deleteData('api/Tags/DeleteTag?id=' + props.obj.tagID)
        .then(res => {
            if (res.data.status === 'Delete') {
                (async () => infoWrapper('Record deleted successfully!!'))();
                document.getElementById("Row_" + props.obj.tagID).remove();
            }
        })
    }
  };
  const EditTag = (e)=>{
    e.preventDefault();
    props.forEdit(props.obj.tagID)
  };
  return (
    <tr id={rowID}>
      <td>
        {props.obj.tagTitle}
      </td>
      <td>
        {props.obj.status}
      </td>
      <td>
        <button type="button" onClick={EditTag} className="btn btn-success">Edit</button>|
        <button type="button" onClick={DeleteTag} className="btn btn-danger">Delete</button>  
      </td>  
    </tr>  
  );
}

class TagsList extends Component {
  static DisplayName = TagsList.name
  constructor(props){
    super(props)
    this.state = { Tags: []}
  }
  componentDidMount(){
    //Get Tags List
    api.fetchData('api/Tags/TagsList')
    .then(response => {
      let data = response.data
      this.setState({ Tags: data })
    })  
    .catch(function (error) {  
      console.log(error);  
    })
  }
  onAddTag = ()=>{
    this.props.forAddorEdit(0)
  }
  onEditTag = (id)=>{
    this.props.forAddorEdit(id)
  }
  
  render() {
    const Tagslist = this.state.Tags.map(tag => <TableRow obj={tag} key={tag.tagID} forEdit={this.onEditTag}/>)
    return (
      <div>
        <button className="btn btn-success" onClick={this.onAddTag}>Add Tag</button>
        { this.state.Tags.length >0 ?
          <table className="table table-striped" style={{ marginTop: 10 }}>
            <thead>
              <tr>
                <th>Tag Title</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
            {Tagslist}
            </tbody>
          </table>:<p>No Data found to Display</p>
        }
      </div>
    )
  }
};

const Tag=()=> {
  const [isAddorEdit, setIsAddorEdit] = useState(false);
  const [addorEditId, setAddorEditId] = useState(0);

  const onAddOrEdit = (id)=>{
    setIsAddorEdit(true)
    setAddorEditId(id)
  };
  const forList = ()=>{
    setIsAddorEdit(false)
  }

  return (
    <Fragment>
      <div>  
        <h4 className="text-headding">Tag</h4> 
      </div><hr/>
      { !isAddorEdit && <TagsList forAddorEdit={onAddOrEdit}/> }
      { isAddorEdit && <AddorUpdateTag id={addorEditId} closeAddorEdit={forList}/>}
    </Fragment>
  )
};
export default Tag