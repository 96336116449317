import React, { useState, Fragment } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { infoWrapper } from './InfoAlert';
import { FormDialogNoTitle } from './FormDialogNoTitle';

export const SpecialOfferSection = () =>{
    const [showSignUP, setShowSignUP] = useState(false);

    const showSignUPForm = ()=>{
        setShowSignUP(true);
    }
    const closeSignUPForm = () =>{
        setShowSignUP(false);
    }
    return (
    <section className="special-offer-area" data-aos="fade-up" data-aos-duration="2000">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="special-content" data-aos="fade-up" data-aos-duration="2000">
                        <h3>Sign Up For Special offers</h3>
                        <p>Join our mailing list today to get access to our latest exam news,revision hints and tips and offers.</p>
                        <div className="hover-one active-p populer-btn">
                            <a href="" data-toggle="modal" onClick={showSignUPForm}>Sign Up</a>
                            {showSignUP && <SignUPFormPopUP Active={showSignUP} Close={closeSignUPForm}/>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}

export const SignUPFormPopUP = (props) => {
    return (
    <Fragment>
      {ReactDOM.createPortal(
          <Fragment>
          <FormDialogNoTitle open={props.Active} closeDialog={props.Close}>
              <div className="IdentityCont">
                  <SignUPForm Close={props.Close} />
              </div>
          </FormDialogNoTitle> : <Fragment />
      </Fragment>, document.getElementById('popups')
      )}
    </Fragment>
    )
}

const SignUPForm = (props) =>{
    const [email, setEmail] = useState('');
    const [notValidEmail, setNotValidEmail] = useState('');

    const emailChangeHandler = (e) => {
        setEmail(e.target.value);
        if (notValidEmail !== '') {
            setNotValidEmail('');
        }
    };
    const validateForm = () => {
        let isValid = true;
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (email === '') {
            setNotValidEmail("Please enter Email.");
            isValid = false;
        }
        else if (email !== '' && !pattern.test(email)) {
            isValid = false;
            setNotValidEmail("Please enter valid Email.");
        }
        return isValid;
    };
    const handleClose = (e)=> {
        e.preventDefault();
        props.Close();
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if(validateForm()){
            axios.post('/Api/NewsLetterSubscriptions/SignUpNewsLetters?email='+ email)
            .then(res => {
                (async () => await infoWrapper(res.data))();
            })
            .catch(error => {
                console.log(error);
            });
            props.Close();
        }
    }
    return (
        <Fragment>
            <div className="custom-header text-center">
                <h4 className="modal-title w-100 font-weight-bold text-default">Sign Up for Special Offers</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <form action="" onSubmit={handleSubmit}>
                    <div className="md-form" style={{textAlign:"left"}}>
                        <i className="fas fa-envelope prefix grey-text"></i>
                        <input type="email" id="contactus-email" className="form-control" value={email} onChange={emailChangeHandler} />
                        <label htmlFor="contactus-email">Email Address</label>
                        <span className="help-block text-danger" style={{paddingLeft:"40px"}}>{notValidEmail}</span>
                    </div>
                    <div className="text-center">
                        <button className="btn btn-default btn-rounded my-4" type="submit" onClick={handleSubmit}>Sign Up</button>
                    </div>
                </form>
            </div>
        </Fragment>
    )
}