import React, { useContext, Fragment } from 'react';
import { BannerSection } from './BannerSection';
import { QuestionBankSection } from './QuestionBankSection';
import { SpecialOfferSection } from './SpecialOfferSection';
import { PopulerExamSection } from './PopulerExamSection';
import { ProfileMenu } from './Profile/ProfileMenu';
import AuthContext from './store/auth-context';

export const Profile = () => {
    const authCtx = useContext(AuthContext);

    if (authCtx.isLoggedIn && authCtx.UserType != null && authCtx.UserName != null) {
        return (
            <ProfileMenu user={authCtx.UserName} userType={authCtx.UserType} />
        );
    } else {        
        return (
            <Fragment>
                <BannerSection />
                <QuestionBankSection />
                <PopulerExamSection />
                <SpecialOfferSection />
            </Fragment>
        );
    }
}
