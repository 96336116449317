import React, { useState } from 'react'
import Login from './Login'
import Register from './Register'
import ForgetPassword from './ForgetPassword';
import ResendEmailConfirm from './ResendEmailConfirm'

export const LoginNavigation = (props) => {
    const [action, setAction] = useState('Login');
    const onLogin = () => {
        setAction('Login')
    };
    const onRegister = () => {
        setAction('Register')
    };
    const onForgetPwd = () => {
        setAction('ForgetPassword')
    };
    const onReSendEmail = () => {
        setAction('ResendEmail')
    };

    return (
        <div className="IdentityCont">
            {action === 'Login' && <Login onRegister={onRegister} onForgetPwd={onForgetPwd} onReSendEmail={onReSendEmail} forClose={props.forClose} />}
            {action === 'Register' && <Register onLogin={onLogin} forClose={props.forClose} />}
            {action === 'ForgetPassword' && <ForgetPassword onLogin={onLogin} onRegister={onRegister} forClose={props.forClose} onReSendEmail={onReSendEmail} />}
            {action === 'ResendEmail' && <ResendEmailConfirm onLogin={onLogin} forClose={props.forClose} />}
        </div>
    );
};

export default LoginNavigation;