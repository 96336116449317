import React, { Component } from 'react';

export class QuestionBankSection extends Component {
  static displayName = QuestionBankSection.name;  
  render () {
    return (
        <section className="question-bank-area" data-aos="fade-up" data-aos-duration="2000">
             <div className="container">
                 <div className="row choose-row">
                    {/* single item */}
                     <div className="col-md-6 col-lg-4 single-question-bank" data-aos="fade-up" data-aos-duration="2000">
                         <div className="question-bank-main">
                             <img src="assets/images/m1.png" alt="img" />
                             <h2>Question Bank From Experts</h2>
                             <p>Question Bank created by doctors and updated frequently</p>
                         </div>
                     </div>
                     {/* single item */}
                     <div className="col-md-6 col-lg-4 single-question-bank" data-aos="fade-up" data-aos-duration="2000">
                         <div className="question-bank-main">
                             <img src="assets/images/m2.png" alt="img" />
                             <h2>How We Help</h2>
                             <p>How PracMed is helping you pass your exams?</p>
                         </div>
                     </div>
                     {/* single item */}
                     <div className="col-md-6 col-lg-4 single-question-bank" data-aos="fade-up" data-aos-duration="2000">
                         <div className="question-bank-main">
                             <img src="assets/images/m3.png" alt="img" />
                             <h2>Why Choose Us?</h2>
                             <p>Flexible and Cost effective subscription plans and mock demos</p>
                         </div>
                     </div>
                 </div>
             </div>
         </section>
    );
  }
}