import React, { useState, useContext, Fragment } from 'react';
import axios from 'axios';
import { infoWrapper } from '../InfoAlert';
import AuthContext from '../store/auth-context';

export const Login = (props) => {
    const [email, setEmail] = useState('');
    const [notValidEmail, setNotValidEmail] = useState('');
    const [password, setPassowrd] = useState('');
    const [notValidPassword, setNotValidPassword] = useState('');
    const [remember, setRemember] = useState(false);
    const authCtx = useContext(AuthContext);

    const emailChangeHandler = (e) => {
        if(e.target.value.length <= 30){
            setEmail(e.target.value);
          }else{
            (async () => infoWrapper("The maximum characters should not exceed more than 30."))();
        }
        if (email !== '') {
            setNotValidEmail('');
        }
    };
    const passwordChangeHandler = (e) => {
        if(e.target.value.length <= 20){
            setPassowrd(e.target.value);
          }else{
            (async () => infoWrapper("The maximum characters should not exceed more than 20."))();
        }
        if (password !== '') {
            setNotValidPassword('');
        }
    };
    const rememberChangeHandler = () => {
        setRemember(remember ? false : true);
    };
    const validateForm = () => {
        let isValid = true;
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (email === '') {
            setNotValidEmail("Please enter Email.");
            isValid = false;
        }
        else if (email !== '' && !pattern.test(email)) {
            isValid = false;
            setNotValidEmail("Please enter valid Email.");
        }
        if (password === '') {
            setNotValidPassword("Please eneter password.");
            isValid = false;
        }
        else if (password !== '' && password.trim().length < 8) {
            setNotValidPassword("The Password Must Contain 8 or More Characters.");
            isValid = false;
        }
        return isValid;
    };
    const submitHandler = (e) => {
        e.preventDefault();
        if (email === '' && password === '') {
            setNotValidEmail("Please enter Email.");
            setNotValidPassword("Please eneter password.");
        } else if (validateForm()) {
            let User = {
                Email: email,
                Password: password
            }
            sendLoginRequest(User);
        }
    };
    const sendLoginRequest = async (User) => {
        axios.post('/api/AuthManagement/Login', User)
            .then(res => {
                if (res.data.status !== "Fail") {
                    if (res.data.status === "Merged") {
                        (async () => await infoWrapper("Cart Items Added Successfully!"))();
                    }
                    authCtx.login();
                    props.forClose();
                }
                else {
                    (async () => await infoWrapper(res.data.message))();
                }
            })
            .catch(err => {
                (async () => await infoWrapper("Invalid Request!"))();
            });
    };
    const forRegister = (e) => {
        e.preventDefault();
        props.onRegister();
    };
    const forForgetPassword = (e) => {
        e.preventDefault();
        props.onForgetPwd();
    };
    const forResendEmail = (e) => {
        e.preventDefault();
        props.onReSendEmail();
    };
    const onClose = (e) => {
        e.preventDefault();
        props.forClose();
    }
    return (
        <Fragment>


            <div id="modalLoginForm" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                aria-hidden="true">
                <div>
                    <div className="custom-header text-center">
                        <h4 className="modal-title w-100 font-weight-bold text-default">Login</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" onClick={onClose}>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body mx-1">
                        <form onSubmit={submitHandler}>
                            <div className="md-form" style={{textAlign:"left"}}>
                                <i className="fas fa-envelope prefix grey-text"></i>
                                <input type="email" id="defaultForm-email" className="form-control" value={email}
                                    onChange={emailChangeHandler} required />
                                <label htmlFor="defaultForm-email">Email Address</label>
                                <span className="help-block text-danger" style={{paddingLeft:"40px"}}> {notValidEmail}</span>
                            </div>
                            <div className="md-form" style={{textAlign:"left"}}>
                                <i className="fas fa-lock prefix grey-text"></i>
                                <input type="password" id="defaultForm-pass" className="form-control" value={password}
                                    onChange={passwordChangeHandler} />
                                <label htmlFor="defaultForm-pass">Password</label>
                                <span className="help-block text-danger" style={{paddingLeft:"40px"}}>{notValidPassword}</span>
                            </div>
                            <div className="d-flex justify-content-around">
                                <div>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="defaultLoginFormRemember"
                                            value={remember} onChange={rememberChangeHandler} />
                                        <label className="custom-control-label" htmlFor="defaultLoginFormRemember">Remember me</label>
                                    </div>
                                </div>
                                <div>
                                    <a href="" onClick={forForgetPassword}>Forgot password?</a>
                                </div>
                            </div>

                            <div className="text-center">
                                <button className="btn btn-default btn-rounded my-4" onClick={submitHandler} type="submit">Log in</button>

                                <p>Not a member?&nbsp;
                                    <a href="#modalRegisterForm" className="text-default" href="#modal2" data-toggle="modal"
                                        data-dismiss="modal" onClick={forRegister} >Register</a>
                                </p>
                            </div>

                        </form>
                        <div>
                            <br />
                            <a href="" onClick={forResendEmail}>Resend Email Confirmation</a>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Login;