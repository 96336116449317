import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { infoWrapper } from '../InfoAlert';

export const PasswordReset = () => {
  const [password, setPassowrd] = useState('');
  const [notValidPassword, setNotValidPassword] = useState('');
  const [confPassword, setConfPassowrd] = useState('');
  const [notValidConfPassword, setNotValidConfPassword] = useState('');
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');
  const email = params.get('email');

  const passwordChangeHandler = (e)=>{
    if(e.target.value.length <= 20){
      setPassowrd(e.target.value);
    }else{
      (async () => infoWrapper("The maximum characters should not exceed more than 20."))();
    }
    if(password !== ''){
      setNotValidPassword('');
    }
    if(confPassword !== ''){
      setNotValidConfPassword("The new password and confirmation password do not match.");
    }
  };
  const ConfPasswordChangeHandler = (e)=>{
    if(e.target.value.length <= 20){
      setConfPassowrd(e.target.value);
    }else{
      (async () => infoWrapper("The maximum characters should not exceed more than 20."))();
    }
    if(confPassword !== ''){
      setNotValidConfPassword('');
    }
  };
  useEffect(()=>{
    const identifier = setTimeout(() => {
      if(password !== confPassword){
        setNotValidConfPassword("The password and confirmation password do not match.");
      }
    }, 1000);

    return () => {
      clearTimeout(identifier);
    };
  },[confPassword]);
  const validateForm = ()=>{
    let isValid = true;
    if(password === ''){
      setNotValidPassword("Please eneter password.");
      isValid = false;
    }
    else if(password !== '' && password.trim().length < 8){
      setNotValidPassword("The Password Must Contain 8 or More Characters.");
      isValid = false;
    }else if(confPassword === ''){
      setNotValidConfPassword("Please eneter confirm password.");
      isValid = false;
    }
    else if(password !== confPassword){
      setConfPassowrd('');
      setNotValidConfPassword("The password and confirmation password do not match.");
      isValid = false;
    }
    return isValid;
  };
  const submitHandler = (e)=>{
    e.preventDefault();
    if(password === '' && confPassword === ''){
      setNotValidPassword("Please eneter password.");
      setNotValidConfPassword("Please eneter confirm password.");
    }else if(validateForm()){
      let User = {
        Email: email,
        Token: token,
        NewPassword: password
      }
      sendPassordResetRequest(User);
    }
  };
  const sendPassordResetRequest = async (User) => {
    axios.post('/api/AuthManagement/ResetPasswordByToken', User )
    .then(res => {
      const data = res.data;
      (async () => await infoWrapper(data.message))();
    })
    .catch( err => {
      (async () => await infoWrapper("Invalid Request!"))();
    });
  };
  return (
    <section className="banner-area" data-aos="fade-up" data-aos-duration="2000">
      <div className="container">
        <hr/>
        <div className="my-3 d-flex justify-content-between">
          <h4 className="text-default">Reset Password</h4>
        </div>
        <div className="card">
          <div className="card-body">
            <form  onSubmit={submitHandler}>
              <div className="md-form" style={{paddingBottom: "10px", width:"40%"}}>
                <i className="fas fa-lock prefix grey-text"></i>
                <input type="password" 
                  id="resetForm-pass"
                  className="form-control" 
                  value={password} 
                  onChange={passwordChangeHandler}/>
                <label htmlFor="resetForm-pass">Enter new password</label>
                <span className="text-danger" style={{paddingLeft:"40px"}}>{notValidPassword}</span>
              </div>
              <div className="md-form" style={{paddingBottom: "10px", width:"40%"}}>
                <i className="fas fa-lock prefix grey-text"></i>
                <input type="password" 
                  id="resetForm-confirmPass" 
                  className="form-control"
                  value={confPassword} 
                  onChange={ConfPasswordChangeHandler}/>
                <label htmlFor="resetForm-confirmPass">Confirm new password</label>
                <span className="text-danger" style={{paddingLeft:"40px"}}>{notValidConfPassword}</span>
              </div>
              <div className="text-center" style={{paddingBottom: "10px", width:"40%"}}>
                <button className="btn btn-default btn-rounded" onClick={submitHandler} type="submit" >Reset Password</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
};
export default PasswordReset;