import React, { Component } from 'react';
import { ContactUs } from './ContactUs';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';

export class FooterSection extends Component {
  static displayName = FooterSection.name;
  constructor(props) {
    super(props);

    this.state = {
        ShowContactUsForm: false
    };        
}
    handleShowPopup = () => {this.setState({ShowContactUsForm: true})}
    handleClosePopup = () => {this.setState({ShowContactUsForm: false})}

    render () {
        const { ShowContactUsForm } = this.state;
        return (
            <section className="footer-area" data-aos="fade-up" data-aos-duration="2000">
                <div className="container">
                    <div className="row footer-first-row" data-aos="fade-up" data-aos-duration="2000">
                        <div className="col-md-6 col-lg-5">
                            <div className="footer-left">
                                <div className="single-footer-content" data-aos="fade-up" data-aos-duration="2000">
                                    <h4>PracMed</h4>
                                    <ul>
                                        <li><a href="/features">Feature</a></li>
                                        <li><a href="/">Pricing</a></li>
                                        <li><a href="/">Exams</a></li>
                                    </ul>
                                </div>
                                <div className="single-footer-content" data-aos="fade-up" data-aos-duration="2000">
                                    <h4>Company</h4>
                                    <ul>
                                        <li><a href="/aboutus">About Us</a></li>
                                        <li>
                                            <a href="" data-toggle="modal" data-target="#contactusModal" onClick={this.handleShowPopup}>Contact Us</a>
                                            {ShowContactUsForm && <ContactUs active={ShowContactUsForm} forClose={this.handleClosePopup}></ContactUs>}
                                        </li>
                                        <li><NavLink title="Terms & Conditions" tag={Link} to="/terms-conditions">Terms &amp; Conditions</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1 col-lg-3"></div>
                        <div className="col-md-5 col-lg-4">
                            <div className="footer-right" data-aos="fade-up" data-aos-duration="2000">
                                <div className="footer-bg-web">
                                    {/* <a href="/"><img src="assets/images/footer.png" alt="img"/></a> */}
                                    <NavLink title="PracMed Home" tag={Link} to="/home"><img src="assets/images/logo.png" alt="img" /></NavLink>
                                </div>
                                {/* <div className="avaible-footer" data-aos="fade-up" data-aos-duration="2000">
                                    <h4>Available For:</h4>
                                    <ul>
                                        <li><a href="/"><img className="goolapp1" src="assets/images/a2.png" alt="img"/></a></li>
                                        <li><a href="/"><img className="goolapp2" src="assets/images/a1.png" alt="img"/></a></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>                  
                    <div className="bdr" data-aos="fade-up" data-aos-duration="2000"></div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-btm">
                                <div className="footer-btm-left">
                                    <p>Copyrights © 2021 PracMed, All rights reserved.</p>
                                </div>
                                <div className="footer-btm-right">
                                    <ul>
                                        <li><a href="#"><i className="fa fa-instagram"></i></a></li>
                                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}