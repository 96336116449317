import React, {Component, Fragment } from 'react';
import { confirmWrapper } from '../ConfirmAlert';
import { infoWrapper } from '../InfoAlert';
import * as api from '../API/Api';
import AuthContext from '../store/auth-context';
import { Link } from 'react-router-dom';

class ChooseDuration extends Component{
  constructor(props) {
    super(props)
  
    this.state = {  
      DurationVid: this.props.filtetredDuration.examDurationId,
      Durations: this.props.durations
    }
  }
  onChangeDuration = (e)=>{
    e.preventDefault()
    let duration;
    let id = +e.target.value
    this.props.durations.forEach(dr=>{
      if(dr.examDurationId === id){
        duration = dr
      }
    })
    this.props.forChangeDuration(duration)
    this.setState({DurationVid:e.target.value})
  }
  render(){
    const {DurationVid, Durations }=this.state
    if(Durations.length === 0){
      return <p>Found No Durations</p>
    }
    else
    {
      return <select className="custom-select d-block w-100" name = "duration" value = {DurationVid} onChange={this.onChangeDuration}>
      {Durations.map((dr,index)=> 
      <option key = {index+9} value={dr.examDurationId}>{index+1} - {dr.durationName} - {dr.days} Days</option>)}
      </select>
    }     
  } 
}

class PopularExamView extends Component {  
  static displayName = PopularExamView.name;
  constructor(props) {  
    super(props);  
    const [item]= this.props.obj.examDurations
    this.onClickCart = this.onClickCart.bind(this);
    this.state = {
      UserID : this.props.userid,
      UserName: this.props.user,
      ExamID : this.props.obj.examID,
      SelectedDuration: item,
      Cost: item.cost,
      Days: item.days,
      ExamDurations: this.props.obj.examDurations
    }
  }
  
  async onClickCart (e){
    e.preventDefault();
    const CartItem = {
      CartItemID : 0,
      UserName : this.state.UserName,
      ExamDurationId : this.state.SelectedDuration.examDurationId
    }
    if (await confirmWrapper('Are your sure that you want to Add this Exam to Cart?')){
      const Request = {
        UserName: this.state.UserName,
        ExamID: this.state.ExamID
      };
      api.postData('/Api/CartItems/IsExamActive', Request)
      .then(async (res)=>{
        if(res.data === true){
          if (await confirmWrapper('Exam already active, do you want to extend subscription?')){
            this.AddExamToCart(CartItem);
          }
        }
        else{
          this.AddExamToCart(CartItem);
        }
      })
    }
  }
  AddExamToCart = (CartItem)=>{
    api.postData('/Api/CartItems/AddCartItem', CartItem)
    .then(res => {
      let data = res.data;
      (async () => await infoWrapper(data.message))();
      this.props.CartHandler()
    })
    .catch( err =>{
      (async () => await infoWrapper("Data Not valid"))();
    })
  }
  onChangeDuration =(dr)=>{
    this.setState({SelectedDuration: dr, Cost: dr.cost, Days:dr.days})
  }
  render() {
    const {SelectedDuration, ExamDurations, Cost} = this.state;
    return (
      <div className="col-md-4">
        <div className="card">
          <div className="card-body">
            <p className="card-title"><b>{this.props.obj.examName}</b></p>
            <p className="card-text">{this.props.obj.examDesc}</p>
            <br/>
            <p className="card-text">Choose Your Duration:</p>
            <ChooseDuration filtetredDuration={SelectedDuration} durations={ExamDurations} forChangeDuration={this.onChangeDuration}/>
            <div className="card-header h6">
              <b>Price : </b> ${Cost}
            </div>
            <div className="text-center populer-btn">
              <button className="btn btn-default btn-md btn-rounded" type="button" onClick={this.onClickCart}>Add to Cart</button>
            </div>
          </div>
        </div>
      </div>
    );  
  }  
}

class UserExamsView extends Component {  
  static displayName = UserExamsView.name;
  constructor(props) {  
    super(props);  
    this.state = {
      UserID : this.props.userid,
      UserName: this.props.user,
      ExamID : this.props.obj.examID,
      UserExamID: this.props.obj.userExamID,
      ExamStatus: this.props.obj.status
    }
  }
  TakeExam = ()=>{
    const Exam = {
      UserExamID: this.props.obj.userExamID,
      ExamID : this.props.obj.examID,
      ExamName : this.props.obj.examName,
        StatusId: this.props.obj.statusId,
        SelectedQuestionCount: this.props.obj.selectedQuestionCount,
        ExamMode: this.props.obj.examMode,
        TimeLeft: this.props.obj.timeLeft,
        SelectedSubjects: this.props.obj.selectedSubjects
    }
    this.props.takeExam({ exam: Exam });
  }
    ViewExam = () => {
        const Exam = {
            UserExamID: this.props.obj.userExamID,
            ExamID: this.props.obj.examID,
            ExamName: this.props.obj.examName,
            StatusId: this.props.obj.statusId,
            SelectedQuestionCount: this.props.obj.selectedQuestionCount,
            ExamMode: this.props.obj.examMode,
            TimeLeft: this.props.obj.timeLeft,
            SelectedSubjects: this.props.obj.selectedSubjects
        }
        this.props.takeExam({ exam: Exam, isViewExam: true});
    }
    ResumeExam = () => {
        const Exam = {
            UserExamID: this.props.obj.userExamID,
            ExamID: this.props.obj.examID,
            ExamName: this.props.obj.examName,
            StatusId: this.props.obj.statusId,
            SelectedQuestionCount: this.props.obj.selectedQuestionCount,
            ExamMode: this.props.obj.examMode,
            TimeLeft: this.props.obj.timeLeft,
            SelectedSubjects: this.props.obj.selectedSubjects
        }
        this.props.takeExam({ exam: Exam, isResumeExam: true });
    }
    RestartExam = () => {
        const Exam = {
            UserExamID: this.props.obj.userExamID,
            ExamID: this.props.obj.examID,
            ExamName: this.props.obj.examName,
            StatusId: this.props.obj.statusId,
            SelectedQuestionCount: this.props.obj.selectedQuestionCount,
            ExamMode: this.props.obj.examMode,
            TimeLeft: this.props.obj.timeLeft,
            SelectedSubjects: this.props.obj.selectedSubjects
        }
        this.props.takeExam({ exam: Exam, isRestartExam: true});
    }
  render() { 
    return (
      <div className="card my-3">
        <div className="card-body d-md-flex justify-content-between">
          <div>
            <p className="card-title"><b>{this.props.obj.examName}</b><span className="card-text"> {this.props.obj.status}</span></p>
            <p className="card-text">{this.props.obj.examDesc}</p>
          </div>
          <div>
                    {this.props.obj.status == "New" ?
                        <button className="btn btn-default btn-md btn-rounded" type="button" onClick={this.TakeExam}>Take Exam</button> : 
                        <button className="btn btn-default btn-md btn-rounded" type="button" onClick={this.ViewExam}>View Exam</button>
                    }
                    {this.props.obj.status == "In Progress" ?
                            <button className="btn btn-default btn-md btn-rounded" type="button" onClick={this.ResumeExam}>Resume Exam</button> : <Fragment />
                    }
                    {
                        (this.props.obj.status == "In Progress" || this.props.obj.status == "Completed") ?
                            <button className="btn btn-default btn-md btn-rounded" type="button" onClick={this.RestartExam}>{this.props.obj.status == "Completed" ? "Restart Exam" : "Start Over"}</button>
                            : <Fragment />
                    }
            <Link className="btn btn-info btn-md btn-rounded" to={{pathname:'/exam-duration', examId: this.props.obj.examID}}>Extend Subscription</Link>
          </div>
        </div>
        <div className="card-footer text-muted">
          Expires on: {this.props.obj.endDate}
        </div>
      </div>
    );  
  }  
}

export class ExamDetails extends React.Component {
  static displayName = ExamDetails.name;
  constructor(props) {
    super(props)
    this.state = {
      isAuthenticated : false,
      UserName: props.User,
      UserID: 0,
      UserExams:[],
      PopularExams:[]
    }
  }
  componentDidMount(){
    api.fetchData(`/Api/UserExams/UserExamsByUserName`)
    .then(response => {
        let data = response.data;
      this.setState({ UserExams: data })
    })  
    .catch(function (error) {  
      console.log(error);  
    })
    api.fetchData(`/Api/UserExams/PopularExamsByUserName`)
    .then(response => {
      let data = response.data;
      this.setState({ PopularExams: data })
    })  
    .catch(function (error) {  
      console.log(error);  
    })
  }
  render() {
    const {UserName, UserID, UserExams, PopularExams} = this.state;     
    return (
      <section className="" data-aos="fade-up" data-aos-duration="2000">
        <div className="container">
          <div className="my-3">
            <h4 className="text-default">My Exams</h4>
          </div>
          {UserExams.length>0 ?
            UserExams.map((exam, index)=> { return <UserExamsView obj={exam} key={index + 999} user ={UserName} userid ={UserID} takeExam={this.props.takeExam}/>})
            :<div className="card my-3">
              <div className="card-body d-md-flex justify-content-between">
                <div>
                  <p className="card-title"><b>Not yet Subscribed.</b></p>
                </div>
              </div>
            </div>
          }
        </div>
        <div className="container my-5">
          <div className="my-3">
            <h4 className="text-default">Most Popular Exams </h4>
          </div>
            {PopularExams.length>0?
              <div className="row">
                <AuthContext.Consumer>
                  { value => PopularExams?.map(exam => { return <PopularExamView CartHandler = {value.CartCountHandler} obj={exam} key={exam.examID + 99} user ={UserName} userid ={UserID} onBuyExam = {this.forByExam}/>})}
                </AuthContext.Consumer>
              </div>
              :<div className="card my-3">
                <div className="card-body d-md-flex justify-content-between">
                  <div>
                    <p className="card-title"><b>No Active Exams Available.</b></p>
                  </div>
                </div>
              </div>
            }
        </div>
      </section>
    )
  }
}

{/* <section className="mb-5" data-aos="fade-up" data-aos-duration="2000">
  <div className="container">
    <div id="cont">
      My Exams
    </div>
    {UserExams.length>0 ?
      <table className="table table-striped" style={{ marginTop: 10 }}>  
        <thead>  
          <tr>
            <th>Exam Name</th>  
            <th>Exam Description</th>  
            <th>Exam Start Date</th>  
            <th>Exam End Date</th>
          </tr>  
        </thead>  
        <tbody>
          {UserExams.map(exam => { return <UserExamsView obj={exam} key={exam.examID} user ={UserName} userid ={UserID} takeExam={this.props.takeExam}/>})}
        </tbody>  
      </table>
      :<div>
        <br/><br/><br/>
        <p>No Exams available.</p>
        <br/>
      </div>
    }
    <div id="cont">
      Most Popular Exams
    </div>
    <div>
      {PopularExams.length>0?
        <table className="table table-striped" style={{ marginTop: 10 }}>  
          <thead>  
            <tr>
              <th>Exam Name</th>  
              <th>Exam Description</th>  
              <th>Durations</th>
              <th>Price</th> 
            </tr>  
          </thead>  
          <tbody>
          <AuthContext.Consumer>
          { value => PopularExams.map(exam => { return <PopularExamView CartHandler = {value.CartCountHandler} obj={exam} key={exam.examID} user ={UserName} userid ={UserID} onBuyExam = {this.forByExam}/>})}
          </AuthContext.Consumer>
          </tbody> 
        </table>
        :<div>
          <br/><br/><br/>
          <p>No Active Exams available.</p>
          <br/>
        </div>
      }
    </div>
  </div>
</section> */}
{/* <tr>
    <td>  
      {this.props.obj.examName}  
    </td>  
    <td>  
      {this.props.obj.examDesc}  
    </td>  
    <td>  
      <ChooseDuration filtetredDuration={SelectedDuration} durations={ExamDurations} forChangeDuration={this.onChangeDuration}/>
    </td>
    <td>
      <p>${Cost}</p>
    </td>
    <td>  
      <div>
        <button className="btn btn-success" onClick={this.onClickCart}>Add to Cart</button>
      </div>
    </td> 
    <td>
      <div>
        <button  className="btn btn-success btnSubmit1"  onClick={this.onBuyNow}>Buy Now</button>
      </div>
    </td>
  </tr> */}