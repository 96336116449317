import React, {useState, useEffect, Fragment} from 'react';
import * as api from '../API/Api';
import ExaminationView from './ExaminationView';
import ChooseNoOfQuestions from './ChooseNoOfQuestions';
import ExamReport from './ExamReport';
import { infoWrapper } from '../InfoAlert'
import ExaminationTestMode from './ExaminationTestMode';
import { UpdateUserExam } from './UserExam';

export class TakeExam extends React.Component {
    static displayName = TakeExam.name;
    constructor(props) {
        super(props)
        console.log(props);
        this.state = {
            subMenuOption: '',
            examQuestions: [],
            selectedQuestions: [],
            totalNoOfQuestions: 0,
            selectedNoOfQuestions: 0,
            minutes: 0,
            examStarted: false,
            report: undefined,
            examMode: ''
        }
    }
  onChangeMenu = async (e) => {
    e.persist();
    let id = e.target.id;
    if(this.state.examStarted){
      (async () => infoWrapper('Please Click on End Exam!'))();
    }
    else{
        this.setState({ subMenuOption: id });
    }
  };
  examRevisionCloseHandler = async (e)=>{
    // e.preventDefault();
    if(this.state.examStarted){
      (async () => infoWrapper('Please Click on End Exam if you want to Close Exam Revision!!'))();
    }
    else{
      this.props.closeExamRevision();
    }
  };
    componentDidMount(){
    api.fetchData('/Api/UserExams/GetUserExamQuestions?id=' + this.props.exam.UserExamID)
      .then(res => {
      let questionList = res.data;
      let Questions = [];
      let SubjectIds = [];
      questionList.map((examQuestion, index) => {
        let vid = index;
        Questions[vid] = { vid: vid, 
          ExamQuestionID: examQuestion.examQuestionID,
          SubjectId: examQuestion.subjectId,
          SubjectIncluded: examQuestion.subjectIncluded,
          AnswerValue: '',
          userExamQuestionID: examQuestion.userExamQuestion == null ? 0 : examQuestion.userExamQuestion.userExamQuestionID,
          isAnswered: examQuestion.userExamQuestion == null ? false : examQuestion.userExamQuestion.isAnswered,
          isAnswerCorrect: examQuestion.userExamQuestion == null ? false : examQuestion.userExamQuestion.isAnswerCorrect,
          notes: examQuestion.userExamQuestion == null ? "" : examQuestion.userExamQuestion.notes
        }
        SubjectIds[index] = {SubjectId: examQuestion.subjectId};
      });
      var UniqueSubjects = SubjectIds.filter((elem, index )=>{
        const _elem = JSON.stringify(elem); 
        return index === SubjectIds.findIndex(obj=> JSON.stringify(obj) === _elem)
      });
      SubjectIds = UniqueSubjects.filter( sub => sub.SubjectId !== null);
      const totalQuestions = questionList.length;
          this.setState({ totalNoOfQuestions: totalQuestions });
          this.setState({ examQuestions: Questions });
         
          if (this.props.isResumeExam) {
              if (this.props.exam.SelectedSubjects == null || this.props.exam.SelectedSubjects.length == 0) {
                  this.onStartExam(this.props.exam.SelectedQuestionCount, this.props.exam.TimeLeft, this.props.exam.ExamMode.toString());
              } else {
                  this.onStartExamSubjectWise(this.props.exam.SelectedSubjects, this.props.exam.SelectedQuestionCount, this.props.exam.TimeLeft, this.props.exam.ExamMode.toString());
              }
              this.setState({ subMenuOption: 'QuestionView' });
          }
          else if (this.props.isViewExam) {
              let reportData = this.getReportData((this.props.exam.SelectedSubjects == null || this.props.exam.SelectedSubjects.length == 0) ? undefined : this.props.exam.SelectedSubjects);
              this.setState({ selectedNoOfQuestions: this.props.exam.SelectedQuestionCount, report: reportData, examStarted: false, subMenuOption: 'MyPerformance' });
          }
          else
              this.setState({ subMenuOption: 'ChooseQuestions' });
          
    })
    .catch(error=>{
      console.log(error); 
    })

  }

    getReportData = (Subjects) => {
        let filterQuestions = this.getFilterQuestions(0, Subjects);
        let notAnswered = 0;
        let correct = 0;
        let notCorrect = 0;
        Object.entries(filterQuestions).map(([index, question]) => {
            if (!question.isAnswered) {
                notAnswered += 1;
            }
            else {
                if (question.isAnswerCorrect) {
                    correct += 1;
                } else {
                    notCorrect += 1;
                }
            }
        });
        let reportData = {
            Correct: correct,
            NotCorrect: notCorrect,
            NotAnsweed: notAnswered,
            Score: ((correct / this.props.exam.SelectedQuestionCount) * 100).toFixed(0),
            ExamQuestions: filterQuestions
        }
        return reportData;
    }

    getFilterQuestions = (sq, Subjects) => {
        let filterQuestions = {};
        let index = 0;
        if (Subjects) {
            for (let j = 0; j < this.state.examQuestions.length; j++) {
                let q = this.state.examQuestions[j]
                let subj;
                for (let i = 0; i < Subjects.length; i++) {
                    subj = Subjects[i];
                    if (subj.isSelected || (subj.isSelected === undefined)) {
                        if ((subj.SubjectId === 0 || subj.subjectID === 0) && (q.SubjectIncluded === false)) {
                            filterQuestions[index] = {
                                vid: index,
                                ExamQuestionID: q.ExamQuestionID,
                                SubjectId: q.SubjectId,
                                SubjectIncluded: q.SubjectIncluded,
                                userExamQuestionID: q.userExamQuestionID,
                                isAnswered: q.isAnswered,
                                isAnswerCorrect: q.isAnswerCorrect,
                                notes: q.notes
                            };
                            index++;
                            break;
                        }
                        else if ((q.SubjectId === subj.SubjectId || q.SubjectId === subj.subjectID) && q.SubjectIncluded) {
                            filterQuestions[index] = {
                                vid: index,
                                ExamQuestionID: q.ExamQuestionID,
                                SubjectId: q.SubjectId,
                                SubjectIncluded: q.SubjectIncluded,
                                userExamQuestionID: q.userExamQuestionID,
                                isAnswered: q.isAnswered,
                                isAnswerCorrect: q.isAnswerCorrect,
                                notes: q.notes
                            };
                            index++;
                            break;
                        }
                    }
                }
            }
        }
        else {
            for (let j = 0; j < sq; j++) {
                let q = this.state.examQuestions[j];
                filterQuestions[index] = {
                    vid: index,
                    ExamQuestionID: q.ExamQuestionID,
                    SubjectId: q.SubjectId,
                    SubjectIncluded: q.SubjectIncluded,
                    userExamQuestionID: q.userExamQuestionID,
                    isAnswered: q.isAnswered,
                    isAnswerCorrect: q.isAnswerCorrect,
                    notes: q.notes
                };
                index++;
            }
        }
        return filterQuestions;
    }
  onStartExam = (sq, min, mode)=>{
    this.clearAnswers();
    UpdateUserExam(this.props.exam.UserExamID, 2, min, mode, sq);
    let filterQuestions = this.getFilterQuestions(sq);
    this.setState({ subMenuOption: 'QuestionView', examMode: mode, selectedNoOfQuestions: sq, selectedQuestions: filterQuestions, minutes: min, examStarted: true});
  };

  onStartExamSubjectWise = (Subjects, sq, min, mode)=>{
    this.clearAnswers();
    if (!this.props.isResumeExam)
        UpdateUserExam(this.props.exam.UserExamID, 2, min, mode, sq, Subjects);
    let filterQuestions = this.getFilterQuestions(0, Subjects);
    console.log(filterQuestions);
    this.setState({ subMenuOption: 'QuestionView', examMode: mode, selectedNoOfQuestions: sq, selectedQuestions: filterQuestions, minutes: min, examStarted: true });
  };
  clearAnswers = (statusId) => {
        if (this.props.isRestartExam && this.props.exam.StatusId != 1) {
            let index = 0;
            while (this.state.examQuestions[index] != undefined) {
                this.state.examQuestions[index].isAnswered = false;
                this.state.examQuestions[index].isAnswerCorrect = false;
                index++;
            }
        }
    };
  onEndExam = (reportData)=>{
    UpdateUserExam(this.props.exam.UserExamID, 3);
      this.setState({ report: reportData, examStarted: false, subMenuOption: 'MyPerformance' });
  };

    render() {
        const {
            subMenuOption,
            examQuestions,
            selectedQuestions,
            totalNoOfQuestions,
            selectedNoOfQuestions,
            minutes,
            examStarted,
            report,
            examMode } = this.state;
      return(
        <Fragment>
            <section className="content-area" data-aos="fade-up" data-aos-duration="2000">
                <div className="container">
                    <div className="alert alert-success alert-dismissible fade show default-color" role="alert">
                        <strong className="text-white">{this.props.exam.ExamName || 'NO Exam Selected'} - Revision</strong>
                    </div>
                    <nav className="navbar navbar-expand-lg navbar-dark default-color">
                        <button className="navbar-toggler"
                            type="button" data-toggle="collapse" data-target="#navbarNav"
                            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav">
                                <li className={`nav-item ${subMenuOption === 'ChooseQuestions' ? 'active' : ''}`}>
                                    <a className="nav-link" href="" data-toggle="modal" id="ChooseQuestions" onClick={this.onChangeMenu}>
                                        Choose Questions {subMenuOption === 'ChooseQuestions' ? <span className="sr-only">(current)</span> : ''}
                                    </a>
                                </li>
                                <li className={`nav-item ${subMenuOption === 'MyPerformance' ? 'active' : ''}`}>
                                    <a className="nav-link" href="" data-toggle="modal" id="MyPerformance" onClick={this.onChangeMenu}>
                                        My Reports {subMenuOption === 'MyPerformance' ? <span className="sr-only">(current)</span> : ''}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </section>
            <section className="" data-aos="fade-up" data-aos-duration="2000">
                <div className="container">
                    {/* <div className="alert alert-success alert-dismissible fade show" role="alert">
            <strong className="alert-link">{this.props.exam.ExamName || 'NO Exam Selected'}</strong>
            <button type="button" className="close" aria-label="Close" title="Close Exam Revision" id="closeExam" onClick={examRevisionCloseHandler}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div> */}
                </div>
                {subMenuOption === 'ChooseQuestions' && <ChooseNoOfQuestions total={totalNoOfQuestions} startExam={this.onStartExam} startExamSubWise={this.onStartExamSubjectWise} examId={this.props.exam.ExamID} />}
                {subMenuOption === 'MyPerformance' && <ExamReport total={selectedNoOfQuestions} reportData={report} UserExamID={this.props.exam.UserExamID} closeRevision={this.examRevisionCloseHandler} />}
                {
                    subMenuOption === 'QuestionView' && examMode === '0' && <ExaminationView ExamID={this.props.exam.ExamID} UserExamID={this.props.exam.UserExamID} ExamName={this.props.exam.ExamName} questions={selectedQuestions} noOfQuestionSelected={selectedNoOfQuestions} min={minutes} endExam={this.onEndExam} />
                }
                {subMenuOption === 'QuestionView' && examMode === '1' && <ExaminationTestMode ExamID={this.props.exam.ExamID} UserExamID={this.props.exam.UserExamID} ExamName={this.props.exam.ExamName} questions={selectedQuestions} noOfQuestionSelected={selectedNoOfQuestions} min={minutes} endExam={this.onEndExam} />}
            </section>
            </Fragment>
        )
    }
}