import React, {Fragment, useState, useContext} from 'react';
import ReactDOM from 'react-dom';
import AuthContext from '../store/auth-context';
import { infoWrapper } from '../InfoAlert';
import * as api from '../API/Api';
import { FormDialogNoTitle } from '../FormDialogNoTitle';

const FormData = (props)=>{
  const [commentText, setCommentText] = useState('');
  const authCtx = useContext(AuthContext);

  const onCommentTextChange = (e)=>{
    setCommentText(e.target.value);
  }
  const SubmitHandler = (e)=>{
    e.preventDefault();
    if(commentText.trim().length > 19){
      let QueryData = {
        UserId: authCtx.UserName,
        ExamName: props.ExamName,
        QuestionCode: props.QuestionCode,
        CommentText: commentText
      }
      api.postData('/Api/Contactus/ReportQuestion', QueryData)
      .then(res=>{
        (async () => await infoWrapper(res.data))();
      })
      .catch(err=>{
        console.log(err);
      })
      props.closeHandler();
    }
    else{
      (async () => await infoWrapper("The Query must contain minimum 20 Characters."))();
    }
  };
  const CancleHandler = (e)=>{
    e.preventDefault();
    console.log("Cancled");
    props.closeHandler();
  };
  return(
    <div className="IdentityCont">
      <div className="custom-header text-center">
        <h4 className="modal-title w-100 font-weight-bold text-default">Report Question</h4>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={CancleHandler}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form className="FormDiv">
        <div>
          <label name="UserId"><b>From: </b>{authCtx.UserName}</label>
        </div>
        <div>
          <label name="UserId"><b>Exam Name: </b>{props.ExamName}</label>
        </div>
        <div>
          <label name="QuestionId"><b>Question Code: </b>{props.QuestionCode} </label>
        </div>
        <div>
          <label htmlFor="CommentText" name="Comment"><b>Comments:</b></label>
          <textarea cols="20" rows="2"
          value={commentText} 
          onChange={onCommentTextChange}
          id="CommentText"/>
        </div>
        <div className="text-center">
          <button className="btn btn-default btn-rounded" onClick={SubmitHandler}>Submit</button>
        </div>
      </form>
    </div>
  );
}

export const ReportQuestion = (props) => {
  return (
  <Fragment>
    {ReactDOM.createPortal(
      <Fragment>
        <FormDialogNoTitle open={props.report} closeDialog={props.forClose}>
          <FormData closeHandler = {props.forClose} QuestionCode={props.QuestionCode} ExamName={props.ExamName}/>
        </FormDialogNoTitle> : <Fragment />
      </Fragment>,
      document.getElementById('popups')
    )}
  </Fragment>
  );
};

export default ReportQuestion;