import React, { Component, Fragment } from 'react'
import { ContactUs } from './ContactUs';
export class AboutUs extends Component {
    static displayName = AboutUs.name;
    constructor(props) {
        super(props);

        this.state = {
            ShowContactUsForm: false
        };
    }
    handleShowPopup = () => { this.setState({ ShowContactUsForm: true }) }
    handleClosePopup = () => { this.setState({ ShowContactUsForm: false }) }
    render() {
        const { ShowContactUsForm } = this.state;
    return (
      <Fragment>
        <section className="content-area" data-aos="fade-up" data-aos-duration="2000">
          <h1 className="text-center pt-3 text-default h1">About Us</h1>
        </section>
        <main data-aos="fade-up" data-aos-duration="2000" className="container">
          {/* <h1 className="text-center text-default h1">About PracMed</h1> */}
          <p>PracMed is a new, high quality medical education resource for junior 
            doctors and medical students in Australia.</p>
          <div className="my-3 text-justify">
            <p>Established in 2015, we are a Melbourne based company that is set to become 
              one of Australia’s best medical practice exam providers.</p>
            <p>The idea of PracMed arose when a practising doctor could not find an 
              Australian resource to help them prepare for their General Practice entry exams.</p>
            <p>From this, they felt obligated to get a team together to create a resource 
              for Australian graduates, Australian medical students and for any 
              international doctors interested in practicing medicine in Australia.</p>
            <p>PracMed aims to make medical exam study easier.</p>
          </div>
          <div className="mb-3">
            <h1 className="text-center pt-5">What do we offer you?</h1>
            <div className="my-3 text-justify">
              <p className="pt-2">PracMed is here to give you a head start on your medical education and 
                career to give you the best chance of success. Whether you are in 
                medical school or a professional of medicine, PracMed has you covered 
                to prepare you for your studies and exams.</p>
              <p className="pt-2">Being an Australian company, we offer you high quality practice 
                examination questions that are written by practising Australian doctors 
                and verified by specialists in their fields. Our questions and answers are 
                based on current Australian guidelines and are aligned with current 
                curricula, whether it be medical school or specialty college training programs.</p>
              <p className="pt-2">PracMed is designed to give you a dynamic educational program that 
                can be tailored to the individual user to give you the best opportunity to 
                consolidate your learning, and therefore succeed.</p>
              <p className="pt-2">We provide our users with detailed performance analyses in addition to 
                many other innovative features and benefits on offer. We are committed 
                to providing our users with a high quality resource and thus constantly 
                update our services and listen to and apply user feedback.</p>
              <p className="pt-2">If you have any feedback regarding this resource, the team would 
                greatly appreciate you contact us.</p>
              <p className="pt-2">Please note: This service is not associated with any of the Royal 
                Australian &amp; New Zealand Colleges and has no guidance by them.</p>
                    </div>
                    <div className="touchwithus">
                        <a href="" data-toggle="modal" data-target="#contactusModal" onClick={this.handleShowPopup} >GET IN TOUCH WITH US</a>
                     {ShowContactUsForm && <ContactUs active={ShowContactUsForm} forClose={this.handleClosePopup}></ContactUs>}
                    </div>
                </div>       
            </main>    
      </Fragment>
    )
  }
}