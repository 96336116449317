import React, { Component, Fragment, useState } from 'react'
import { confirmWrapper } from '../ConfirmAlert'
import { infoWrapper } from '../InfoAlert'
import * as api from '../API/Api';

export class AddorUpdateExamQuestionGroup extends Component {
  static DisplayName = AddorUpdateExamQuestionGroup.name
  constructor(props){
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.state = {
      Action:'',
      ExamQuestionGroupID:'',
      ExamID:'',
      QuestionID:'',
      ExamsData: [],
      QuestionBanksData: []
    }
  }
  componentDidMount(){
    const id = this.props.id
    if(id > 0){
      api.fetchData('/Api/ExamQuestionGroups/ExamQuestionGroupdetailsById?id='+id)
      .then(res => {
        let data = res.data
        this.setState({Action: 'Update', ExamQuestionGroupID: data.examQuestionGroupID, ExamID: data.examID, QuestionID: data.questionID})
        console.log(data)
      })
      .catch(error=>{
        console.log(error); 
      })
    }
    else{
      this.setState({Action: 'Create', ExamQuestionGroupID: 0, ExamID:'', QuestionID: ''})
    }
    // Getting Exams List
    api.fetchData('api/Exams/ExamsList')
    .then(response => {
      let data = response.data
      console.log(data)
      this.setState({ ExamsData: data })
    })  
    .catch(function (error) {  
      console.log(error);  
    })
    // Getting Questions List
    api.fetchData('api/QuestionBanks/QuestionBanksList')
    .then(response => {
      let data = response.data
      console.log(data)
      this.setState({ QuestionBanksData: data })
    })  
    .catch(function (error) {  
      console.log(error);  
    })
  }
  onSubmit(e){
    e.preventDefault()
    const ExamQuestionGroup = {
      ExamQuestionGroupID: this.state.ExamQuestionGroupID,
      ExamID:this.state.ExamID,
      QuestionID:this.state.QuestionID
    }
    api.postData('/Api/ExamQuestionGroups/AddorUpdateExamQuestionGroup', ExamQuestionGroup)
    .then(res => {
      if(this.state.ExamQuestionGroupID === 0)
        (async () => await infoWrapper("New ExamQuestionGroup Added Successfully."))();
      else
        (async () => await infoWrapper("Data Updated Successfully."))();
        this.props.closeAddorEdit();
    })
  }
  changeExam = (e)=>{ this.setState({ ExamID : e.target.value})}
  changeQuestion = (e)=>{ this.setState({ QuestionID : e.target.value})}
  onCancel(e){
    e.preventDefault()
    this.props.closeAddorEdit();
  }
  render() {
    const {Action, ExamID, QuestionID} = this.state;
    const ExamIDOptions = this.state.ExamsData.map(opt => <option value={opt.examID} key={opt.examID}>{opt.examCode}</option>)
    const QuestionIDOptions = this.state.QuestionBanksData.map(opt => <option value={opt.questionID} key={opt.questionID}>{opt.questionCode}</option>)
    return (
      <div>
        {/* <h5 className="reference-data-form-heading">{Action} Exam Question Group</h5> */}
        <form className="reference-data-form" onSubmit={this.onSubmit}>
          <div>
            <label htmlFor="code">Exam Code</label>
            <select name="Schoolname" value={ExamID} onChange={this.changeExam}>
              <option value="">Please Select Exam Code...</option>
              {ExamIDOptions}
          </select>
          </div>
          <div>            
            <label htmlFor="name">Question Code</label>
            <select name="Schoolname" value={QuestionID} onChange={this.changeQuestion}>
              <option value="">Please Select Question Code...</option>
              {QuestionIDOptions}
          </select>
          </div>
          <div className="buttn-align">
            <button className="btnSubmit" type="submit">{Action}</button>
            <button className="btnSubmit" onClick = {this.onCancel} type="reset">Cancel</button>
          </div>
        </form>
      </div>
    )
  }
}

const TableRow = (props)=>{
  const rowID = "Row_" + props.obj.examQuestionGroupID;

  const DeleteExamQuestionGroup = async () => {
    if (await confirmWrapper('Are you sure that you want to delete the record ?')){
      api.deleteData('api/ExamQuestionGroups/DeleteExamQuestionGroup?id=' + props.obj.examQuestionGroupID)
      .then(res => {
        if (res.data.status === 'Delete') {
          (async () => infoWrapper('Record deleted successfully!!'))();
          document.getElementById("Row_" + props.obj.examQuestionGroupID).remove();
        }
      })
    }
  };
  const EditExamQuestionGroup = (e)=>{
    e.preventDefault();
    props.forEdit(props.obj.examQuestionGroupID)
  };
  return (
    <tr id={rowID}>
      <td>
        {props.obj.examQuestionGroupID}
      </td>
      <td>
        {props.obj.examID}
      </td>
      <td>
        {props.obj.questionID}
      </td>
      <td>
        <button type="button" onClick={EditExamQuestionGroup} className="btn btn-success">Edit</button>|
        <button type="button" onClick={DeleteExamQuestionGroup} className="btn btn-danger">Delete</button>  
      </td>
    </tr>
  );
};

export class ExamQuestionGroupsList extends Component {
  static DisplayName = ExamQuestionGroupsList.name
  constructor(props){
    super(props)
    this.state = { ExamQuestionGroups: []}
  }
  componentDidMount(){
    //Get ExamQuestionGroups List
    api.fetchData('api/ExamQuestionGroups/ExamQuestionGroupsList')
    .then(response => {
      let data = response.data
      console.log(data)
      this.setState({ ExamQuestionGroups: data })
    })  
    .catch(function (error) {  
      console.log(error);  
    })
  }
  onAddExamQuestionGroup = ()=>{
    this.props.forAddorEdit(0)
  }
  onEditExamQuestionGroup = (id)=>{
    this.props.forAddorEdit(id)
  }
  render() {
    const ExamQuestionGroupslist = this.state.ExamQuestionGroups.map(ExamQuestionGroup => <TableRow obj={ExamQuestionGroup} key={ExamQuestionGroup.examQuestionGroupID} forEdit={this.onEditExamQuestionGroup}/>)
    return (
      <div>
        <button className="btn btn-success" onClick={this.onAddExamQuestionGroup}>Add Exam Question Group</button>
        { this.state.ExamQuestionGroups.length >0 ?
          <table className="table table-striped" style={{ marginTop: 10 }}>
            <thead>  
              <tr>
                <th>ExamQuestionGroup ID</th>  
                <th>Exam Id</th>  
                <th>Question Id</th>  
              </tr>  
            </thead>  
            <tbody>
            {ExamQuestionGroupslist}
            </tbody>
          </table>:<p>No Data found to Display</p>
        }
      </div>
    )
  }
}

const ExamQuestionGroup=()=> {
  const [isAddorEdit, setIsAddorEdit] = useState(false);
  const [addorEditId, setAddorEditId] = useState(0);

  const onAddOrEdit = (id)=>{
    setIsAddorEdit(true)
    setAddorEditId(id)
  };
  const forList = ()=>{
    setIsAddorEdit(false)
  }

  return (
    <Fragment>
      <div>  
        <h4 className="text-headding">Exam Question Group</h4> 
      </div><hr/>
      { !isAddorEdit && <ExamQuestionGroupsList forAddorEdit={onAddOrEdit}/> }
      { isAddorEdit && <AddorUpdateExamQuestionGroup id={addorEditId} closeAddorEdit={forList}/>}
    </Fragment>
  )
};
export default ExamQuestionGroup;