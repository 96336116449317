import React, { Component, Fragment } from 'react';
import { Route } from 'react-router';
import { ApplicationPaths, ProfileActions } from './ApiAuthorizationConstants';
import {ProfileMenu} from '../Profile/ProfileMenu';

export default class AppRoutes extends Component {
  render () {
    return(
      <Fragment>          
          <Route path={ApplicationPaths.GetMyProfile} render={() => profileAction(ProfileActions.GetMyProfile)} />          
      </Fragment>);
  }
}

function profileAction(name){
  return (
    <Fragment>
      <div className="headerMargin" />
      <ProfileMenu action={name}/>
    </Fragment>  
  );
}