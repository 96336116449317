import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { infoWrapper } from '../InfoAlert';
import LoginNavigation from '../AccountManagement/LoginNavigation';
import { FormDialogNoTitle } from '../FormDialogNoTitle';

export const RegistrationForm = (props)=> {
  const [email, setEmail] = useState('');
  const [notValidEmail, setNotValidEmail] = useState('');
  const [password, setPassowrd] = useState('');
  const [notValidPassword, setNotValidPassword] = useState('');
  const [confPassword, setConfPassowrd] = useState('');
  const [notValidConfPassword, setNotValidConfPassword] = useState('');
  const [isRegistered, setIsRegistered] = useState(false);
  const [confirmString, setConfirmString] = useState('');
  const [notValidConfirmString, setNotValidConfirmString] = useState('');
  const [ShowPopup, setShowPopup] = useState(false);

  const emailChangeHandler = (e)=>{
    if(e.target.value.length <= 30){
      setEmail(e.target.value);
    }else{
      (async () => infoWrapper("The maximum characters should not exceed more than 30."))();
    }
    if(email !== ''){
      setNotValidEmail('');
    }
  };
  const passwordChangeHandler = (e)=>{
    if(e.target.value.length <= 20){
      setPassowrd(e.target.value);
    }else{
      (async () => infoWrapper("The maximum characters should not exceed more than 20."))();
    }
    if(password !== ''){
      setNotValidPassword('');
    }
  };
  const ConfPasswordChangeHandler = (e)=>{
    if(e.target.value.length <= 20){
      setConfPassowrd(e.target.value);
    }else{
      (async () => infoWrapper("The maximum characters should not exceed more than 20."))();
    }
    if(confPassword !== ''){
      setNotValidConfPassword('');
    }
  };
  const handleShowPopup = () => {
    setShowPopup(true);
  }
  const handleClosePopup = () => {
    setShowPopup(false);        
  }
  useEffect(()=>{
    const identifier = setTimeout(() => {
      if(confPassword !== '' && password !== confPassword){
        setNotValidConfPassword("The password and confirmation password do not match.");
      }
    }, 1000);

    return () => {
      clearTimeout(identifier);
    };
  },[confPassword, password]);
  const validateForm = ()=>{
    let isValid = true;
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if(email === ''){
      setNotValidEmail("Please enter Email.");
      isValid = false;
    }
    else if(email !== '' && !pattern.test(email) ){
      isValid = false;
      setNotValidEmail("Please enter valid Email.");
    }
    if(password === ''){
      setNotValidPassword("Please eneter password.");
      isValid = false;
    }
    else if(password !== '' && password.trim().length < 8){
      setNotValidPassword("The Password Must Contain 8 or More Characters.");
      isValid = false;
    }
    if(confPassword === ''){
      setNotValidConfPassword("Please eneter confirm password.");
      isValid = false;
    }
    else if(password !== confPassword){
      setConfPassowrd('');
      setNotValidConfPassword("The password and confirmation password do not match.");
      isValid = false;
    }
    return isValid;
  };
  const submitHandler = (e)=>{
    e.preventDefault();
    if(email === '' && password === '' && confPassword === ''){
      setNotValidEmail("Please enter Email.");
      setNotValidPassword("Please eneter password.");
      setNotValidConfPassword("Please eneter confirm password.");
    }else if(validateForm()){
      console.log("Email: " + email + ", Password: " + password);
      let User = {
        Username: email,
        Email: email,
        Password: password
      }
      sendRegisterRequest(User);
    }
  };
  const sendRegisterRequest = async (User) => {
    axios.post('/api/AuthManagement/RegisterNew', User )
    .then(res => {
      console.log(res.data);
      if(res.data.status === "Success"){
        (async () => await infoWrapper(res.data.message))();
        setIsRegistered(true);
      }else{
        (async () => await infoWrapper(res.data.message))();
      }
    })
    .catch(err =>{
      console.error(err);
      (async () => await infoWrapper("Invalid Request."))();
    })
  };
  const ConfirmSubmitHandler = (e) =>{
    e.preventDefault();
    if(confirmString === ''){
      setNotValidConfirmString("Please enter confirmation string.")
    }
    else{
      const ConfirmEmail = {
        Email : email,
        Token : confirmString
      };
      axios.post('/api/AuthManagement/EmailConfirmation', ConfirmEmail )
      .then(res => {
        if(res.data.status === "Success"){
          props.onRegister(email);
          (async () => await infoWrapper("Your Email has been confirmed successfully. Please enter billing address."))();
        }else{
          (async () => await infoWrapper(res.data.message))();
        }
      })
      .catch( err => {
        console.log(err);
      });
    }
  };
  const confirmStringHandler = (e) =>{
    setConfirmString(e.target.value);
    setNotValidConfirmString('');
  };
  return (
    <div className="card">
      <div className="card-body">
        <form onSubmit={submitHandler}>
          <div className="md-form" style={{paddingBottom: "10px"}}>
            <i className="fas fa-envelope prefix grey-text"></i>
            <input 
              type="email"
              id="registerForm-email" 
              className="form-control" 
              value={email} 
              onChange={emailChangeHandler} disabled={isRegistered}/>
            <label htmlFor="registerForm-email">Email Address</label>
            <span className="text-danger" style={{paddingLeft:"40px"}}>{notValidEmail}</span>
          </div>
          <div className="md-form" style={{paddingBottom: "10px"}}>
            <i className="fas fa-lock prefix grey-text"></i>
            <input type="password" 
              id="registerForm-pass" 
              className="form-control" 
              value={password} 
              onChange={passwordChangeHandler} disabled={isRegistered}/>
            <label htmlFor="registerForm-pass">Password</label>
            <span className="text-danger" style={{paddingLeft:"40px"}}>{notValidPassword}</span>
          </div>
          <div className="md-form" style={{paddingBottom: "10px"}}>
            <i className="fas fa-lock prefix grey-text"></i>
            <input type="password" 
              id="registerForm-confirmPass" 
              className="form-control"
              value={confPassword} onChange={ConfPasswordChangeHandler} disabled={isRegistered}/>
            <label htmlFor="registerForm-confirmPass">Confirm password</label>
            <span className="text-danger" style={{paddingLeft:"40px"}}>{notValidConfPassword}</span>
          </div>
          <div className="text-center">
            <button className="btn btn-default btn-rounded" onClick={submitHandler} type="submit" disabled={isRegistered}>Register</button>
          </div>
        </form>
        {/* { !isRegistered && <div>*If already Registered? Please click on login.</div>} */}
        {!isRegistered && <div>
          <a href="" data-toggle="modal"  /* data-target="#modalLoginForm" */ onClick={handleShowPopup}>If already Registered? Please login!</a>            
                  {ShowPopup ?
                      <FormDialogNoTitle title="Question Description" open={ShowPopup} closeDialog={handleClosePopup}>
                          <LoginNavigation forClose={handleClosePopup} />
                      </FormDialogNoTitle> : <Fragment />
            }
                   
        </div>}
        {isRegistered && <form onSubmit={ConfirmSubmitHandler}>
          <div className="md-form" style={{paddingBottom: "10px"}}>
            <i className="fas fa-envelope prefix grey-text"></i>
            <input 
              type="text"
              id="registerConfirmString"
              className="form-control" 
              value={confirmString} 
              onChange={confirmStringHandler} />
            <label htmlFor="registerConfirmString">Email Confirmation String</label>
            <span className="text-danger" style={{paddingLeft:"40px"}}>{notValidConfirmString}</span>
          </div>
          <div className="text-center">
            <button className="btn btn-default btn-rounded" onClick={ConfirmSubmitHandler} type="submit" disabled={!isRegistered}>Submit</button>
          </div>
        </form>}
      </div>
    </div>
  );
};

export default RegistrationForm;