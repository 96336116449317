import React, { useState, useEffect, Fragment } from 'react';
import { Doughnut } from 'react-chartjs-2';
import ReactHtmlParser from 'react-html-parser';
import * as api from '../API/Api';

const DemoExamReport = (props) => {
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  useEffect(() => {
    if(props.reportData !== undefined){
      setIsDataAvailable(true);
    }
  }, [props.reportData])
  
  return (
    <div className="container">
      <div className="card">
        <div className="card-body">
          {isDataAvailable && <Fragment>
            <div className="row">
              <div className="col-md-6 my-3">
                <Doughnut 
                data={{
                  labels: ['Correct', 'Wrong', 'Not Answered'],
                  datasets: [
                    {
                      label: '# of Questions',
                      data: [props.reportData.Correct, props.reportData.NotCorrect, props.reportData.NotAnsweed],
                      backgroundColor: [
                        '#46BFBD',
                        '#F7464A',
                        '#FDB45C',
                      ],
                      hoverBackgroundColor: ["#5AD3D1", "#FF5A5E", "#FFC870"],
                      borderColor: [
                        '#46BFBD',
                        '#F7464A',
                        '#FDB45C',
                      ],
                      borderWidth: 1,
                    },
                  ]
                }} 
                options={{
                  responsive: true,
                  title:{
                    display:true,
                    text:'Exam Report',
                    fontSize:20
                  },
                  legend:{
                    display:true,
                    position:'right'
                  },
                  maintainAspectRatio: false 
                }}/>
              </div>
              <div className="col-md-6 my-3">
                <div className="card">
                  <div className="card-header h4">
                    Your Score: {props.reportData.Score}%
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">Total Questions given for the demo exam: {props.total}</li>
                    <li className="list-group-item">Attempted Questions: {(+props.reportData.Correct) + (+props.reportData.NotCorrect)}</li>
                    <li className="list-group-item">Correct: {props.reportData.Correct}</li>
                    <li className="list-group-item">Wrong: {props.reportData.NotCorrect}</li>
                    <li className="list-group-item">Not Answered: {props.reportData.NotAnsweed}</li>
                  </ul>
                </div>
              </div>
            </div>
            <br/>
            {props.reportData.ExamQuestions !== undefined && <TestModeExamResult Questions={props.reportData.ExamQuestions} UserExamID={props.UserExamID} noOfQuestions={props.total}/>
            }
            </Fragment>
          }
          {!isDataAvailable && <div className="my-2"> <p>Exam Not Started.</p> </div>}
        </div>
      </div>
    </div>
  )
}

export default DemoExamReport;

export const TestModeExamResult = (props) =>{
  const ExamQuestions = props.Questions;
  const noOfQuestions = props.noOfQuestions;
  const [activeQuestion, setActiveQuestion] = useState(ExamQuestions[0]);
  const [questionData, setQuestionData] = useState('');
  useEffect(() => {
    if(activeQuestion.ExamQuestionID > 0){
      api.fetchData('/Api/UserExams/GetUserExamQuestion?questionid=' + activeQuestion.ExamQuestionID + '&&userexamid=' + props.UserExamID)
      .then(res=>{
        let [question] = res.data;
        setQuestionData(question);
      })
      .catch(err=>{
        console.log(err);
      });
    }
  }, [activeQuestion])
  const onNavigation = (e) =>{
    e.preventDefault();
    let index = +e.target.value;
    let question = ExamQuestions[index];
    console.log(question);
    setActiveQuestion(question);
  }
  return(
    <div className="row">
      <div className="col-md-8 col-lg-9">
        <div className="row">
        {questionData !== '' && <RadioQuestionView index={activeQuestion.vid} Question={questionData} answer={activeQuestion.AnswerValue} isAnswered={activeQuestion.isAnswered}/>}
        </div>
      </div>
      <div className="col-md-4 col-lg-3 text-center">
        <div className="card my-2">
          <h5 className="card-header card-header-color">Questions Revision</h5>
          <div className="card-body" style={{ height:"300px", overflowY:"scroll", overflowX:"hidden" }} id="style-3">
            <nav aria-label="Page navigation example">
              <ul className="pagination pagination-circle pg-default row">
                {
                  Object.entries(ExamQuestions).map((question, index)=> {
                  let qNo  = index + 1;
                  if(qNo <= noOfQuestions){
                    return <li key={qNo} className={question.isAnswered?"page-item active col-2 col-md-4 col-lg-3 my-1":"page-item col-2 col-md-4 col-lg-3 my-1"}>
                    <button type="button" className={`${question.isAnswered?(question.isAnswerCorrect?"page-link bg-success":" page-link bg-danger"):"page-link"}`} value={index} onClick={onNavigation}>{qNo}</button>                    
                  </li>
                  }
                })}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};
const RadioQuestionView = ({ index, Question, answer, isAnswered }) => {
  return (
    <Fragment>
      <div className="col-12 my-2">
        <div className="card">
          <div vid={`${index}_QText`} className="card-header">
            {index+1}. {Question.title}
          </div>
          <div vid={`${index}_Desc`} className="card-body">
            {ReactHtmlParser(Question.description)}
          </div>
        </div>
      </div>
      <div className="col-12 my-2">
        <div className="card ">
          <div className="card-header">
            Options
          </div>
          <ul className="list-group list-group-flush options">
            {
              Object.entries(Question.options)
              .map(([key1, value]) => {
                return (
                  <li key={key1} className={(isAnswered && value.isAnswer)?'list-group-item examination-view-option-bg':'list-group-item'}>
                    <div vid={key1} className="custom-control custom-radio" >
                      <input type="radio" className="custom-control-input" id={`defaultGroupExample_${key1}`} name="options" checked={answer === value.questionBankOptionID ?true:false} readOnly /* value={value.questionBankOptionID} *//>
                      <label className="custom-control-label" htmlFor={`defaultGroupExample_${key1}`}>{`${value.optionText}. `} </label>
                      {isAnswered && value.isAnswer && <i className="far fa-check-circle" aria-hidden="true" style={{ fontSize:"25px", color:"green"}}></i>}
                      {isAnswered && (value.isAnswer === false) && answer === value.questionBankOptionID && <i className="far fa-times-circle" aria-hidden="true" style={{fontSize:"25px", color:"red"}}></i>}
                      {!isAnswered && value.isAnswer && <i className="far fa-check-circle" aria-hidden="true" style={{ fontSize:"25px", color:"green"}}></i>}
                    </div>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
      <div className="col-12 my-2">
        <div className="card ">
            <div className="card-header">
              Explanation
            </div>
            <div className="card-body">
                {ReactHtmlParser(Question.explanation)}
            </div>
        </div>
      </div>
    </Fragment>
  )
};