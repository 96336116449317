import React, {useEffect, useContext, useState, Fragment} from 'react';
import AuthContext from  '../store/auth-context';
import * as api from '../API/Api';
import { infoWrapper } from '../InfoAlert';

export const ChangePassword = () => {
  const authCtx = useContext(AuthContext);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [notValidOP, setNotValidOP] = useState('');
  const [notValidNP, setNotValidNP] = useState('');
  const [notValidCP, setNotValidCP] = useState('');

  const oldPasswordChangeHandler = (e)=>{
    setOldPassword(e.target.value);
    if(oldPassword !== ''){
      setNotValidOP('');
    }
  };
  const newPasswordChangeHandler = (e)=>{
    setNewPassword(e.target.value);
    if(newPassword !== ''){
      setNotValidNP('');
    }
    if(confirmPassword !== ''){
      setNotValidCP("The new password and confirmation password do not match.");
    }
  };
  const confirmPasswordChangeHandler = (e)=>{
    setConfirmPassword(e.target.value);
    if(confirmPassword !== ''){
      setNotValidCP('');
    }
  };
  useEffect(()=>{
    const identifier = setTimeout(() => {
      if(newPassword !== confirmPassword){
        setNotValidCP("The new password and confirmation password do not match.");
      }
    }, 1000);
    return () => {
      clearTimeout(identifier);
    };
  },[confirmPassword]);
  const valiadteForm = ()=>{
    let isValid = true;
    if(oldPassword === ''){
      setNotValidOP("Please Enter Current Password!");
      isValid = false;
    }else if(newPassword === ''){
      setNotValidNP("Please Enter New Password!");
      isValid = false;
    }else if(newPassword === oldPassword){
      setNotValidNP("The new Password can't be the same current password!");
      isValid = false;
    }else if(newPassword.trim().length < 8){
      setNotValidNP("The Password Must Contain 8 or More Characters.");
      isValid = false;
    }else if(newPassword !== confirmPassword){
      setNotValidCP("The new password and confirmation password do not match.");
      isValid = false;
    }
    return isValid;
  };
  const handleSubmit = (e)=>{
    e.preventDefault();
    if(oldPassword === '' && newPassword === '' && confirmPassword === ''){
      setNotValidOP("Please Enter Current Password!");
      setNotValidNP("Please Enter New Password!");
      setNotValidCP("Please Enter Confirm Password!");
    }else if(valiadteForm()){
      let User = {
        Email: authCtx.UserName,
        OldPassword: oldPassword,
        NewPassword: newPassword
      }
      sendChangePasswordRequest(User);
    }
  }
  const sendChangePasswordRequest = async (User) => {
    api.postData('/api/AuthManagement/ChangePassword', User )
    .then(res => {
      const data = res.data;
      (async () => await infoWrapper(data.message))();
    })
    .catch( err => {
      (async () => await infoWrapper("Invalid Request!"))();
    });
  };
  return (
    <section className="mb-5" data-aos="fade-up" data-aos-duration="2000">
      <div className="container">
        <div className="my-3 d-flex justify-content-between">
          <h4 className="text-default">Change Password</h4>
          {/* <p className="mr-5 h5">{authCtx.UserName}</p> */}
        </div>
        <div className="row justify-content-center">
          <div className="col-md-7">
            <form onSubmit={handleSubmit} method="post">
              <div className="my-4" data-aos="fade-up" data-aos-duration="2000">
                <label htmlFor='CurrentPassword'>Current Password</label>
                <input 
                  type='password' 
                  className = "form-control round"
                  placeholder = "Please enter current password!"
                  id = 'CurrentPassword' 
                  value = {oldPassword}
                  onChange = {oldPasswordChangeHandler}/>
                <span className="text-danger">{notValidOP}</span>
              </div>
              <div className="my-4" data-aos="fade-up" data-aos-duration="2000">
                <label htmlFor='NewPassword'>New Password</label>
                <input 
                  type='password' 
                  className = "form-control round"
                  placeholder = "Please enter new password!"
                  id = 'NewPassword' 
                  value = {newPassword}
                  onChange = {newPasswordChangeHandler}/>
                <span className="text-danger">{notValidNP}</span>
              </div>
              <div className="my-4" data-aos="fade-up" data-aos-duration="2000">
                <label htmlFor='ConfirmPassword'>Confirm Password</label>
                <input 
                  type='password' 
                  className = "form-control round"
                  placeholder = "Please confirm new password!"
                  id = 'ConfirmPassword' 
                  value = {confirmPassword}
                  onChange = {confirmPasswordChangeHandler}/>
                <span className="text-danger">{notValidCP}</span>
              </div>
              <div className="text-center my-4">
                <button type="submit" className="btn btn-default btn-rounded" onClick={handleSubmit}>Update Password</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ChangePassword;