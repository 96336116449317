import React, { Component, Fragment, useState } from 'react'
import { confirmWrapper } from '../ConfirmAlert'
import * as api from '../API/Api';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import $ from 'jquery';
import { infoWrapper } from '../InfoAlert';

export class AddorUpdateYear extends Component {
  static DisplayName = AddorUpdateYear.name
  constructor(props){
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.state = {
      Action:'',
      YearId:'',
      Name:'',
      Code:''
    }
  }
  componentDidMount(){
    const id = this.props.id
    if(id > 0){
      api.fetchData('/Api/Years/YeardetailsById?id='+id)
      .then(res => {
        let data = res.data
        this.setState({Action: 'Update', YearId: data.yearId, Name: data.name, Code: data.code})
      })
      .catch(error=>{
        console.log(error); 
      })
    }
    else{
      this.setState({Action: 'Create', YearId: 0, Name: '', Code:''})
    }    
  }
  onSubmit(e){
    e.preventDefault()
    const Year = {
      YearId: this.state.YearId,
      Name:this.state.Name,
      Code:this.state.Code      
    }
    api.postData('/Api/Years/AddorUpdateYear', Year)
    .then(res => {
      // if(this.state.YearId === 0)
      //   (async () => await infoWrapper("New Year Added Successfully."))();
      // else
      //   (async () => await infoWrapper("Data Updated Successfully."))();
      this.props.closeAddorEdit();
    })
    .catch(error=>{
      console.log(error); 
    })
  }
  changeName = (e)=>{
    if(e.target.value.length <= 50){
      this.setState({ Name : e.target.value})
    }else{
      (async () => infoWrapper("The maximum characters should not exceed more than 50."))();
    }
  }
  changeCode = (e)=>{
    if(e.target.value.length <= 15){
      this.setState({ Code : e.target.value})
    }else{
      (async () => infoWrapper("The maximum characters should not exceed more than 15."))();
    }
  }
  onCancel(e){
    e.preventDefault()
    this.props.closeAddorEdit();
  }
  render() {
    const {Action, Name, Code} = this.state;
    return (
      <Fragment>
        <div className="row d-flex justify-content-between card-header align-items-center">
          <h5 className="">{Action} Year</h5>
        </div>
        <div className="mb-5" data-aos="fade-up" data-aos-duration="2000">
          <form action="" method="post" onSubmit={this.onSubmit}>
            <div className="my-4" data-aos="fade-up" data-aos-duration="2000">
              <label htmlFor="year-code" className="h6">Year Code</label>
              <input className="form-control round"
                id="year-code"
                value={Code}
                onChange={this.changeCode}
                type="text" required />
            </div>
            <div className="my-4" data-aos="fade-up" data-aos-duration="2000">
              <label htmlFor="year-name" className="h6">Year Name</label>
              <input className="form-control round"
                id="year-name"
                value={Name}
                onChange={this.changeName}
                type="text" required />
            </div>
            <div className="my-2">
              <button type="submit" className="btn btn-default btn-md" onClick={this.onSubmit}>{Action}</button>
              <button type="reset" className="btn btn-default btn-md" onClick = {this.onCancel}>Cancel</button>
            </div>
          </form>
        </div>
      </Fragment>
    )
  }
}

const TableRow = (props)=>{
  const rowID = "Row_" + props.obj.yearId;

  const DeleteYear = async () => {
    if (await confirmWrapper('Are you sure that you want to delete the record ?')){
      api.deleteData('api/Years/DeleteYear?id=' + props.obj.yearId)
      .then(res => {
        if (res.data.status === 'Delete') {
          // (async () => infoWrapper('Record deleted successfully!!'))();
          document.getElementById("Row_" + props.obj.yearId).remove();
        }
      })
    }
  };
  const EditYear = (e)=>{
    e.preventDefault();
    props.forEdit(props.obj.yearId)
  };
  return (
    <tr id={rowID}>  
      <td>
        {props.obj.code}  
      </td>
      <td>
        {props.obj.name}  
      </td>
      <td>
        <button title="Edit" type="button" onClick={EditYear} className="btn-floating btn-sm btn-default m-1">
          <i className="fas fa-pen text-white"></i>
        </button>
        <button title="Delete" type="button" onClick={DeleteYear} className="btn-floating btn-sm btn-danger m-1">
          <i className="fas fa-trash text-white"></i>
        </button>
      </td>
    </tr>  
  ); 
};

class YearsList extends Component {
  static DisplayName = YearsList.name
  constructor(props){
    super(props)
    this.state = { Years: []}
  }
  componentDidMount(){
    api.fetchData('api/Years/YearsList')
    .then(response => {
      let data = response.data;
      this.setState({ Years: data });
      $('#dtYearsList').DataTable();
      $('.dataTables_length').addClass('bs-select');
    })  
    .catch(error=>{
      console.log(error); 
    })
  }
  onAddYear = ()=>{
    this.props.forAddorEdit(0)
  }
  forEditYear = (id)=>{
    this.props.forAddorEdit(id)
  }
  
  render() {
    const Yearslist = this.state.Years.map(Year => <TableRow obj={Year} key={Year.yearId} forEdit={this.forEditYear}/>)
    return (
      <Fragment>
        <div className="row d-flex justify-content-between card-header align-items-center">
          <h5 className="">Years</h5>
            <button className="btn btn-default btn-md btn-rounded" onClick={this.onAddYear}>Add Year</button>
        </div>
        { this.state.Years.length >0 ?
        <div className="row">
          <div className="col-sm-12" className="table-responsive">
            <table id="dtYearsList" className="table table-striped table-bordered table-sm" cellSpacing="0" width="100%" role="grid" aria-describedby="dtYearsList_info" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th className="th-sm">Year Code</th>
                  <th className="th-sm">Year Name</th>
                  <th className="th-sm">Action</th>
                </tr>
              </thead>
              <tbody>
                {Yearslist}
              </tbody>
            </table>
          </div>
        </div>:<p>No Data found to Display</p>
        }
      </Fragment>
    )
  }
}

const Year=()=> {
  const [isAddorEdit, setIsAddorEdit] = useState(false);
  const [addorEditId, setAddorEditId] = useState(0);

  const onAddOrEdit = (id)=>{
    setIsAddorEdit(true)
    setAddorEditId(id)
  };
  const forList = ()=>{
    setIsAddorEdit(false)
  }

  return (
    <Fragment>
      { !isAddorEdit && <YearsList forAddorEdit={onAddOrEdit}/> }
      { isAddorEdit && <AddorUpdateYear id={addorEditId} closeAddorEdit={forList}/>}
    </Fragment>
  )
};
export default Year;