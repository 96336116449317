import React, { Component, Fragment, useState } from 'react'
import { confirmWrapper } from '../ConfirmAlert'
import { infoWrapper } from '../InfoAlert'
import * as api from '../API/Api';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import $ from 'jquery';

export class AddorUpdateExamDuration extends Component {
  static DisplayName = AddorUpdateExamDuration.name
  constructor(props){
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.state = {
      Action:'',
      ExamDurationId:'',
      ExamID: '',
      DurationId: '',
      Cost: 0,
      Exams: [],
      Durations: [],
      ExamCode: '',
      DurationCode: ''
    }
  }
  componentDidMount(){
    const id = this.props.id
    if(id > 0){
      api.fetchData('/Api/ExamDurations/ExamDurationdetailsById?id='+id)
      .then(res => {
        let data = res.data
        this.setState({Action: 'Update', ExamDurationId: data.examDurationId, ExamID: data.examID, DurationId: data.durationId, Cost: data.cost})
      })
      .catch(error=>{
        console.log(error); 
      })
    }
    else{
      this.setState({Action: 'Create', ExamDurationId: 0, ExamID: '', DurationId: '', cost: 0 })
    }
    // Getting Exams List
    api.fetchData('api/Exams/ExamsList')
    .then(response => {
      let data = response.data
      this.setState({ Exams: data })
    })  
    .catch(error=>{
      console.log(error); 
    }) 
    // Getting Durations List
    api.fetchData('api/Durations/DurationsList')
    .then(response => {
      let data = response.data
      this.setState({ Durations: data })
    })  
    .catch(error=>{
      console.log(error); 
    })   
  }
  onSubmit(e){
    e.preventDefault()
    if(this.state.ExamID === '0'){
      (async () => await infoWrapper("Please select the Exam Code."))();
    }
    else if(this.state.DurationId === '0'){
      (async () => await infoWrapper("Please Assign the Duration Code."))();
    }
    else if(this.state.Cost <= '0' || isNaN(this.state.Cost)  ){
      (async () => await infoWrapper("The Price should be positive value and Not allowed negative values or Text"))();
    }
    else{
      const ExamDuration = {
        ExamDurationId: this.state.ExamDurationId,
        ExamID: this.state.ExamID,
        DurationId: this.state.DurationId,
        Cost: this.state.Cost
      }
      api.postData('/Api/ExamDurations/AddorUpdateExamDuration', ExamDuration)
      .then(res => {
        // if(res.data.status === "Success")
        //   (async () => await infoWrapper("New Exam Duration Added Successfully."))();
        // else{
        //   if(res.data.status === "Duplicate")
        //     (async () => await infoWrapper("Exam Duration Alraedy Existed."))();
        //   else
        //     (async () => await infoWrapper("Data Updated Successfully."))();
        // }          
        this.props.closeAddorEdit();
      })
    }
  }
  changeExamID = (e) =>{ this.setState({ ExamID: e.target.value})}
  changeDurationId = (e)=>{ this.setState({ DurationId : e.target.value})}
  changeCost = (e)=>{
    let p = +e.target.value
    if(e.target.value == '' || (p > -0.01 && p <= 9999.99)){
        this.setState({ Cost : e.target.value})
    }else{
      (async () => infoWrapper("The price should be in between 0.01 to 9999.99 only."))();
    }
  }
  
  onCancel(e){
    e.preventDefault()
    this.props.closeAddorEdit();
  }
  
  render() {
    const {Action, ExamID, DurationId, Cost} = this.state;
    const examOptions = this.state.Exams.map(exopt => <option value={exopt.examID}>{exopt.examName}</option>)
    const duraOptions = this.state.Durations.map(duopt => <option value={duopt.durationId}>{duopt.name}</option>)
    return (
      <Fragment>
      <div className="row d-flex justify-content-between card-header align-items-center">
          <h5 className="">{Action} Exam Duration</h5>
      </div>
      <div className="mb-5" data-aos="fade-up" data-aos-duration="2000">
        <form action="" method="post" onSubmit={this.onSubmit}>
          <div className="my-2" data-aos="fade-up" data-aos-duration="2000">
            <label htmlFor="exam-name" className="h6">Select Exam</label>
            <select className="custom-select" value={ExamID} onChange={this.changeExamID}>
              <option value='0'>--Choose Exam Name--</option>
              {examOptions}
            </select>
          </div>
          <div className="my-2" data-aos="fade-up" data-aos-duration="2000">
            <label htmlFor="exam-duration" className="h6">Assign Duration</label>
            <select className="custom-select" value={DurationId} onChange={this.changeDurationId}>
              <option value='0'>--Choose Duration--</option>
              {duraOptions}
            </select>
          </div>
          <div className="mb-2" data-aos="fade-up" data-aos-duration="2000">
            <label htmlFor="exam-price" className="h6">Price</label>
            <input className="form-control round"
              id="exam-price"
              value={Cost}
              onChange={this.changeCost}
              type="number" min={0} max={9999} step={0.01} required />
          </div>
          <div className="my-2">
            <button type="submit" className="btn btn-default btn-md" onClick={this.onSubmit}>{Action}</button>
            <button type="reset" className="btn btn-default btn-md" onClick = {this.onCancel}>Cancel</button>
          </div>
        </form>
      </div>
    </Fragment>
    )
  }
}

const TableRow = (props)=>{
  const rowID = "Row_" + props.obj.examDurationId;

  const DeleteExamDuration = async () => {
    if (await confirmWrapper('Are you sure that you want to delete the record ?')){
      api.deleteData('api/ExamDurations/DeleteExamDuration?id=' + props.obj.examDurationId)
      .then(res => {
        if (res.data.status === 'Delete') {
          // (async () => infoWrapper('Record deleted successfully!!'))();
          document.getElementById("Row_" + props.obj.examDurationId).remove();
        }
        else {
          (async () => infoWrapper(res.data.message))();
        }
      })
      .catch( err=>{
          console.log(err.data);
      });
    }
  }
  const EditExamDuration = (e)=>{
    e.preventDefault();
    props.forEdit(props.obj.examDurationId)
  };
  return (
    <tr id={rowID}>
      <td>
        {props.obj.examName}
      </td>
      <td>
        {props.obj.durationName}
      </td>
      <td>
        ${props.obj.cost}
      </td>
      <td>
        <button title="Edit" type="button" onClick={EditExamDuration} className="btn-floating btn-sm btn-default m-1">
          <i className="fas fa-pen text-white"></i>
        </button>
        <button title="Delete" type="button" onClick={DeleteExamDuration} className="btn-floating btn-sm btn-danger m-1">
          <i className="fas fa-trash text-white"></i>
        </button>
      </td>
    </tr>
  );
}

class ExamDurationsList extends Component {
  static DisplayName = ExamDurationsList.name
  constructor(props){
    super(props)
    this.state = { ExamDurations: []}
  }
  componentDidMount(){
    //Get Exam Durations List with Names insted of Exam Id & Duration Id
    api.fetchData('api/ExamDurations/ExamDurationsListWithNames')
    .then(response => {
      let data = response.data;
      this.setState({ ExamDurations: data });
      $('#dtExamSubjects').DataTable();
      $('.dataTables_length').addClass('bs-select');
    })  
    .catch(error=>{
      console.log(error); 
    })
  }
  onAddExamDuration = ()=>{
    this.props.forAddorEdit(0)
  }
  onEditExamDuration = (id)=>{
    this.props.forAddorEdit(id)
  }
  
  render() {
    const ExamDurationslist = this.state.ExamDurations.map(examduration => <TableRow obj={examduration} key={examduration.examDurationId} forEdit={this.onEditExamDuration}/>)
    return (
      <Fragment>
        <div className="row d-flex justify-content-between card-header align-items-center">
          <h5 className="">Exam Durations</h5>
            <button className="btn btn-default btn-md btn-rounded" onClick={this.onAddExamDuration}>Add Exam Duration</button>
        </div>
        { this.state.ExamDurations.length >0 ?
          <div className="row">
            <div className="col-sm-12" className="table-responsive">
              <table id="dtExamSubjects" className="table table-striped table-bordered table-sm" cellSpacing="0" width="100%" role="grid" aria-describedby="dtExamSubjects_info" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th className="th-sm">Exam Name</th>
                    <th className="th-sm">Duration</th>
                    <th className="th-sm">Price</th>
                    <th className="th-sm">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {ExamDurationslist}
                </tbody>
              </table>
            </div>
          </div>:<p>No Data found to Display</p>
        }
      </Fragment>
    )
  }
}
const ExamDuration=()=> {
  const [isAddorEdit, setIsAddorEdit] = useState(false);
  const [addorEditId, setAddorEditId] = useState(0);

  const onAddOrEdit = (id)=>{
    setIsAddorEdit(true)
    setAddorEditId(id)
  };
  const forList = ()=>{
    setIsAddorEdit(false)
  }

  return (
    <Fragment>
      { !isAddorEdit && <ExamDurationsList forAddorEdit={onAddOrEdit}/> }
      { isAddorEdit && <AddorUpdateExamDuration id={addorEditId} closeAddorEdit={forList}/>}
    </Fragment>
  )
};
export default ExamDuration