import React, { Component, Fragment } from 'react';
import { infoWrapper } from '../InfoAlert';
import Timer from '../ExamRevision/Timer';
import ReactHtmlParser from 'react-html-parser';
import * as api from '../API/Api';
// import ReportQuestion from './ReportQuestion';
import { confirmWrapper } from '../ConfirmAlert';

export class DemoExamPracticeMode extends Component {
  constructor(props) {
    super(props)
    const [obj] = props.questions
    this.state = {
      examQuestions : props.questions,
      activeQuestion : obj,
      questionIndex : 0,
      totalQuestions: props.noOfQuestionSelected,
      CorrectAnswers: 0,
      WrongAnswers:0,
      NotAnswered:props.noOfQuestionSelected,
      Question: {},
      AnswerValue:'',
      BoolAnswer: '',
      reportQuestion: false,
      ScorePercentage: 0,
      Notes: '',
      minutes: 15
    }
  }
  componentDidMount(){
    this.getQuestionData(this.state.activeQuestion.questionID);
  };
  getQuestionData = (id)=>{
    api.fetchData('/Api/ExamsDemo/GetExamQuestionData?id='+id)
    .then(res=>{
      let question = res.data;
      this.setState({Question: question})
    })
    .catch(err=>{
      console.log(err);
    })
  };
  onPreviousQuestion = ()=>{
    if(this.state.questionIndex > 0){
      let index = this.state.questionIndex - 1;
      let preQuestion = this.state.examQuestions[index];
      this.getQuestionData(preQuestion.questionID);
      this.setState({activeQuestion: preQuestion, questionIndex: index, AnswerValue:preQuestion.AnswerValue, BoolAnswer: preQuestion.isCorrect, Notes:'' });
    }else{
      // (async () => infoWrapper('You are at First Question!!'))();
    }
  };
  onNextQuestion = ()=>{
    if(this.state.questionIndex < this.state.totalQuestions -1){
      let index = this.state.questionIndex + 1;
      let nextQuestion = this.state.examQuestions[index];
      this.getQuestionData(nextQuestion.questionID);
      this.setState({activeQuestion: nextQuestion, questionIndex: index, AnswerValue:nextQuestion.AnswerValue, BoolAnswer: nextQuestion.isCorrect, Notes:'' });
    }else{
      // (async () => infoWrapper('You are at Last Question!!'))();
    }
  }
  onNavigation = (e)=>{
    e.preventDefault();
    let index = +e.target.value;
    let question = this.state.examQuestions[index];
    this.getQuestionData(question.questionID);
    this.setState({activeQuestion: question, questionIndex: index, AnswerValue:question.AnswerValue, BoolAnswer: question.isCorrect, Notes:'' });
  }
  onSubmitAnswer = (e)=>{
    e.preventDefault();
    if(this.state.AnswerValue !== '' && this.state.BoolAnswer !== ''){
      let vid = this.state.activeQuestion.vid;
      this.state.examQuestions[vid].isAnswered = true;
      this.state.examQuestions[vid].isAnswerCorrect = this.state.BoolAnswer;
      this.state.examQuestions[vid].AnswerValue = this.state.AnswerValue;
      this.state.activeQuestion.isAnswered = true;
      this.state.activeQuestion.isAnswerCorrect = this.state.BoolAnswer;
      this.state.activeQuestion.AnswerValue = this.state.AnswerValue;
      if(this.state.BoolAnswer){
        let percent = (((this.state.CorrectAnswers +1)/this.state.totalQuestions)*100).toFixed(0);
        this.setState({CorrectAnswers: this.state.CorrectAnswers +1, NotAnswered: this.state.NotAnswered -1, ScorePercentage: percent})
      }
      else{
        this.setState({WrongAnswers: this.state.WrongAnswers +1, NotAnswered: this.state.NotAnswered -1})
      }
    }
    else{
      (async () => infoWrapper('Please select the Answer!'))();
    }
  }
  onSeletAnswer = (answervalue, boolanswer, isRadio, value)=>{
    if (this.state.Question.selectedOptions == null) {
      this.state.Question.selectedOptions = {};
      this.state.Question.selectedOptions[answervalue] = { isAdded: true, isSelected: value, isUpdated: true }
    } else {
      if (isRadio && this.state.Question.selectedOptions[this.state.AnswerValue] != undefined) {
        if (this.state.Question.selectedOptions[this.state.AnswerValue].isAdded)
          delete this.state.Question.selectedOptions[this.state.AnswerValue];
        else {
          this.state.Question.selectedOptions[this.state.AnswerValue].isSelected = false;
        }
      }
      if (this.state.Question.selectedOptions[answervalue] == undefined) {
        this.state.Question.selectedOptions[answervalue] = { isAdded: true, isSelected: value, isUpdated: true }
      }
      else {
        this.state.Question.selectedOptions[answervalue].isSelected = value;
        this.state.Question.selectedOptions[answervalue].isUpdated = true;
      }          
    }
    this.setState({ AnswerValue: answervalue, BoolAnswer: boolanswer });
  }
  onReportQuestion = (e)=>{
    e.preventDefault();
    this.setState({reportQuestion: true});
  }
  ReportQuestionCloseHandler = ()=>{
    this.setState({reportQuestion:false})
  }
  onEndExamHandler = async ()=>{
    if(this.state.NotAnswered > 0){
      if (!(await confirmWrapper('Are your sure that you want End the Examination?'))) {
        return;
      }
    }
    let reportData = {
      Correct: this.state.CorrectAnswers,
      NotCorrect: this.state.WrongAnswers,
      NotAnsweed: this.state.NotAnswered,
      Score: this.state.ScorePercentage
    }
    this.props.endExam(reportData);
  }
  endExamOnTimeout = () => {
    let reportData = {
      Correct: this.state.CorrectAnswers,
      NotCorrect: this.state.WrongAnswers,
      NotAnsweed: this.state.NotAnswered
    }
    this.props.endExam(reportData);
  }
  onNotesChange = (e)=>{
    this.setState({Notes: e.target.value});
  }
  updateRemainingTime = (min) => {

  }
  
  render() {
    const {examQuestions, activeQuestion, questionIndex, Question, AnswerValue, reportQuestion, totalQuestions, ScorePercentage, Notes, minutes} = this.state;
    const index1 = questionIndex + 1;
    return (
      <div className="container my-3">
        <div className="row">
          <div className="col-md-8 col-lg-9">
            <div className="row">
              {Question !== '' && Question.type === 'Drop Down' && <DropDownQuestionView index={activeQuestion.vid} SlNo={index1} Question={Question} />}
              {Question !== '' && Question.type === 'Check Box' && <CheckQuestionView index={activeQuestion.vid} SlNo={index1} Question={Question} onSeletAnswer={this.onSeletAnswer} isAnswered={activeQuestion.isAnswered} /> }
              {Question !== '' && Question.type === 'Radio Button' && <RadioQuestionView index={activeQuestion.vid} SlNo={index1} Question={Question} answer={AnswerValue} onSeletAnswer={this.onSeletAnswer} isAnswered={activeQuestion.isAnswered}/> }
              {Question !== '' && Question.type === 'True or False' && <TrueOrFalseView index={activeQuestion.vid} SlNo={index1} Question={Question} answer={AnswerValue} onSeletAnswer={this.onSeletAnswer} isAnswered={activeQuestion.isAnswered} />}
            </div>
            <div className="text-center my-3">
              <button type="button" className="btn btn-sm btn-info btn-rounded" onClick={this.onPreviousQuestion}><i className="fas fa-angle-left"></i></button>
              {/* <button type="button" className="btn btn-sm btn-default btn-rounded" onClick={activeQuestion.isAnswered?((this.state.questionIndex < this.state.totalQuestions -1)? this.onNextQuestion:this.onEndExamHandler):this.onSubmitAnswer}>{activeQuestion.isAnswered? ((this.state.questionIndex < this.state.totalQuestions -1)? "Next Question": "End Exam" ): "Submit Answer"}</button> */}
              <button type="button" className="btn btn-sm btn-default btn-rounded" onClick={this.onSubmitAnswer} disabled={activeQuestion.isAnswered}>Submit Answer</button>
              <button type="button" className="btn btn-sm btn-info btn-rounded" onClick={this.onNextQuestion}><i className="fas fa-angle-right"></i></button>
            </div>
            {!activeQuestion.isAnswered && <div className="card mx-2 my-3">
                <div className="card-header">
                  Notes
                </div>
                <div className="card-body">
                <textarea id="Notes" className="md-textarea form-control" value={Notes}  rows="5" onChange={this.onNotesChange}/>
                </div>
              </div>}
            {activeQuestion.isAnswered && <div>
              <div className="card mx-2 my-3">
                <div className="card-header">
                  Explanation
                </div>
                <div className="card-body">
                {ReactHtmlParser(Question.explanation)}
                </div>
              </div>
              <div className="text-center my-3">
                <button type="button" className="btn btn-sm btn-info btn-rounded" onClick={this.onPreviousQuestion}><i className="fas fa-angle-left"></i></button>
                <button type="button" className="btn btn-sm btn-default btn-rounded" onClick={this.onSubmitAnswer} disabled={activeQuestion.isAnswered}>Submit Answer</button>
                <button type="button" className="btn btn-sm btn-info btn-rounded" onClick={this.onNextQuestion}><i className="fas fa-angle-right"></i></button>
              </div>
            </div>}
          </div>
          <div className="col-md-4 col-lg-3 text-center">
            <div className="card my-3">
              <h5 className="card-header card-header-color">Time Left</h5>
              <div className="card-body">
                <ul className="list-group list-group-flush text-left">
                  <li className="list-group-item text-center">
                    <Timer min ={minutes} endExam={this.endExamOnTimeout} updateRemainingTime={this.updateRemainingTime} />
                  </li>
                </ul>
              </div>
            </div>
            <div className="card my-3">
              <h5 className="card-header card-header-color">Question Navigation</h5>
              <div className="card-body" style={{ height:"300px", overflowY:"scroll", overflowX:"hidden" }} id="style-3">
                <nav aria-label="Page navigation example">
                  <ul className="pagination pagination-circle pg-default row">
                    {examQuestions.map((question, index)=> {
                      let qNo  = index + 1;
                      if(qNo <= totalQuestions){
                        return <li key={qNo} className={question.isAnswered?"page-item active col-2 col-md-4 col-lg-3 my-1":"page-item col-2 col-md-4 col-lg-3 my-1"}>
                        <button type="button" className={`${question.isAnswered?(question.isCorrect?"page-link bg-success":" page-link bg-danger"):"page-link"}`} value={index} onClick={this.onNavigation}>{qNo}</button>
                      </li>
                      }
                    })}
                  </ul>
                </nav>
              </div>
            </div>
            <div className="card my-3">
              <h5 className="card-header card-header-color">Submission</h5>
              <div className="card-body">
                <button type="button" className="btn btn-default btn-md btn-rounded" onClick={this.onEndExamHandler}>End Exam</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const TrueOrFalseView = ({ index, SlNo, Question, answer, onSeletAnswer, isAnswered }) => {
  const onChangeOption = (e) => {
    let answervalue = e.target.value;
    let questionBankOptionID = e.target.getAttribute("questionBankOptionID");
    let [option] = Question.options.filter(o => o.questionBankOptionID === parseInt(questionBankOptionID));
    onSeletAnswer(questionBankOptionID, option.isAnswer, false, answervalue==='true');
  };
  return (
    <Fragment>
      <div className="col-12 my-2">
        <div className="card">
          <div vid={`${index}_QText`} className="card-header">
            {Question.title}
          </div>
          <div vid={`${index}_Desc`} className="card-body">
            {ReactHtmlParser(Question.description)}
          </div>
        </div>
      </div>
      <div className="col-12 my-2">
        <div className="card ">
          <div className="card-header">
              Select following are true or false
          </div>
          <ul className="list-group list-group-flush options">
            {Object.entries(Question.options).map(([key1, value]) => {
              let option = null;
              if (Question.selectedOptions != null)
                option = Question.selectedOptions[value.questionBankOptionID];
              return (
                <li key={key1} className={(option == null && isAnswered && !value.isAnswer) || (option != null && !option.isUpdated && isAnswered && ((value.isAnswer && option.isSelected) || (!value.isAnswer && !option.isSelected))) ? 'list-group-item examination-view-option-bg' : 'list-group-item'}>
                    <div vid={key1} className="custom-control custom-radio" style={{ display: 'inline-block' }}>{/*  className={`custom-control custom-radio ${(isAnswered && value.isAnswer)?'bg-success':''}${(isAnswered && (value.isAnswer === false) && (answer === value.questionBankOptionID))?'bg-danger':''}`} */}
                        <input type="radio" className="custom-control-input" questionBankOptionID={value.questionBankOptionID} name={key1} checked={option == null ? "" : option.isSelected} value="true" onChange={onChangeOption} />
                        <label className="custom-control-label">True</label>
                    </div>
                    <div vid={key1} className="custom-control custom-radio" style={{ display: 'inline-block', marginLeft:'5px', borderRight: '2px solid #ccc', paddingRight: '7px'  }}>
                        <input type="radio" className="custom-control-input" questionBankOptionID={value.questionBankOptionID} name={key1} checked={option == null ? (isAnswered ? true : "") : !option.isSelected} value="false" onChange={onChangeOption} />
                        <label className="custom-control-label" htmlFor={key1}>False</label>
                    </div>
                    <div style={{ display: 'inline-block', marginLeft: '10px' }}>{`${value.optionText}. `}</div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </Fragment>
  )
};

const CheckQuestionView = ({ index, SlNo, Question, answer, onSeletAnswer, isAnswered }) => {
  const onChangeOption = (e) => {
    let answervalue = e.target.checked;
    console.log(answervalue);
    console.log(e.target);
    let questionBankOptionID = e.target.getAttribute("questionBankOptionID");
    let [option] = Question.options.filter(o => o.questionBankOptionID === parseInt(questionBankOptionID));
    onSeletAnswer(questionBankOptionID, option.isAnswer, false, answervalue);
  };
  return (
    <Fragment>
      <div className="col-12 my-2">
        <div className="card">
          <div vid={`${index}_QText`} className="card-header">
            {Question.title}
          </div>
          <div vid={`${index}_Desc`} className="card-body">
            {ReactHtmlParser(Question.description)}
          </div>
        </div>
      </div>
      <div className="col-12 my-2">
        <div className="card ">
          <div className="card-header">
            Select applicable options from following list
          </div>
          <ul className="list-group list-group-flush options">
            {Object.entries(Question.options).map(([key1, value]) => {
              let option = null;
              if (Question.selectedOptions != null)
                option = Question.selectedOptions[value.questionBankOptionID];
              return (
                <li key={key1} className={(option == null && isAnswered && !value.isAnswer) || (option != null && !option.isUpdated && isAnswered && ((value.isAnswer && option.isSelected) || (!value.isAnswer && !option.isSelected))) ? 'list-group-item examination-view-option-bg' : 'list-group-item'}>
                    <div vid={key1} className="custom-control" style={{ display: 'inline-block' }}>{/*  className={`custom-control custom-radio ${(isAnswered && value.isAnswer)?'bg-success':''}${(isAnswered && (value.isAnswer === false) && (answer === value.questionBankOptionID))?'bg-danger':''}`} */}
                        <input type="checkbox" className="custom-control-input" questionBankOptionID={value.questionBankOptionID} name={key1} checked={option == null ? "" : option.isSelected} onChange={onChangeOption} />
                        <label className="custom-control-label" htmlFor={`defaultGroupExample_${key1}`}>{`${value.optionText}. `} </label>
                    </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </Fragment>
  )
};

const RadioQuestionView = ({ index, SlNo, Question, answer, onSeletAnswer, isAnswered }) => {
  const onChangeOption = (e)=>{
    let answervalue = +e.target.value;
    let [option] = Question.options.filter(o => o.questionBankOptionID === answervalue);
    onSeletAnswer(answervalue, option.isAnswer, true, true);
  };
  return (
    <Fragment>
      <div className="col-12 my-2">
        <div className="card">
          <div vid={`${index}_QText`} className="card-header">
            {Question.title}
          </div>
          <div vid={`${index}_Desc`} className="card-body">
            {ReactHtmlParser(Question.description)}
          </div>
        </div>
      </div>
      <div className="col-12 my-2">
        <div className="card ">
          <div className="card-header">
            Options
          </div>
          <ul className="list-group list-group-flush options">
            {
              Object.entries(Question.options)
              .map(([key1, value]) => {
                return (
                  <li key={key1} className={(Question.selectedOptions!=null && isAnswered && value.isAnswer)?'list-group-item examination-view-option-bg':'list-group-item'}>
                    <div vid={key1} className="custom-control custom-radio" >{/*  className={`custom-control custom-radio ${(isAnswered && value.isAnswer)?'bg-success':''}${(isAnswered && (value.isAnswer === false) && (answer === value.questionBankOptionID))?'bg-danger':''}`} */}
                      <input type="radio" className="custom-control-input" id={`defaultGroupExample_${key1}`} name="options" checked={answer === value.questionBankOptionID ?true:false} value={value.questionBankOptionID} onChange={onChangeOption}/>
                      <label className="custom-control-label" htmlFor={`defaultGroupExample_${key1}`}>{`${value.optionText}. `} </label>
                      {Question.selectedOptions!=null && isAnswered && value.isAnswer && <i className="far fa-check-circle" aria-hidden="true" style={{ fontSize:"25px", color:"green"}}></i>}
                      {Question.selectedOptions!=null && isAnswered && (value.isAnswer === false) && answer === value.questionBankOptionID && <i className="far fa-times-circle" aria-hidden="true" style={{fontSize:"25px", color:"red"}}></i>}
                    </div>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
    </Fragment>
  )
};

const DropDownQuestionView = ({ index, SlNo, Question }) => (
  <Fragment>
    <div vid={index} className="questiontext">Q {SlNo}. {Question.title} <div className="code"> Code:{Question.questionCode}</div></div>
    <div vid={index}>{ReactHtmlParser(Question.description)}</div>
    <select>
    <option vid={index} value="Select Answer">Select Answer</option>
    {
      Object.entries(Question.options)
      .map(([key1, value]) => {
        return (
          <option key={key1} value={value.optionText}>{value.optionText}</option>
        )
      })
    }
    </select>
    <button className="btn btn-success code">Report Question</button>       
  </Fragment>
);

export default DemoExamPracticeMode;