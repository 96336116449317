import React, { Component, Fragment } from 'react';
import { HeaderSection } from './HeaderSection';
import { FooterSection } from './FooterSection';
export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
      <Fragment>
        <HeaderSection />
        <Fragment>                
            {this.props.children}
        </Fragment>
        <FooterSection />
      </Fragment>
    );
  }
}
 