import * as api from '../API/Api'
import React, { Component, Fragment } from 'react'
import { confirmWrapper } from '../ConfirmAlert'
import { infoWrapper } from '../InfoAlert'
import { Link } from 'react-router-dom';

export class UserCart extends Component {
  static displayName = UserCart.name;
  constructor(props) {
    super(props)
    this.onRemoveItem = this.onRemoveItem.bind(this);
    this.state = {
      UserName: props.User,
      TotalCost: 0,
      CartItems:[]
    };
  }
  componentDidMount(){
    api.fetchData('/Api/CartItems/CartItemsListByUserName?user='+this.state.UserName)
    .then( res =>{
      let Cost = 0
      res.data.map(item => Cost += item.cost)
      this.setState({CartItems: res.data, TotalCost: Cost});
    })
    .catch(err=>{
      console.log(err);
    })
  }
  async onRemoveItem(e){
    e.preventDefault();
    let id = +e.target.value;
    if (await confirmWrapper('Are your sure that you want to remove from cart ?')){
      api.deleteData('/Api/CartItems/DeleteCartItem?id=' + id)
      .then(res =>{
        (async () => infoWrapper(res.data.message))();
        this.props.CartHandler();
        const updatedCartitems = this.state.CartItems.filter(item => item.cartItemID !== id);
        const [removedItem] = this.state.CartItems.filter(item => item.cartItemID === id);
        this.setState({CartItems: updatedCartitems, TotalCost: this.state.TotalCost-=removedItem.cost})
      })
      .catch( err =>{
        (async () => infoWrapper('Error while removing Cart item.'))();
      })
    }
  }
  render() {
    const {CartItems, TotalCost} = this.state;
    return (
      <section className="banner-area" data-aos="fade-up" data-aos-duration="2000">
        <div className="container">
          <img src="assets/img/cart-banner.png" alt="My Cart" className="img-fluid mt-2"/>
          <div className="my-3 d-flex justify-content-between align-items-center sticky-top">
          {CartItems.length > 0 && <Fragment><h4 className="text-default">Exams In Cart</h4>
            <Link className="btn btn-default btn-md btn-rounded" to="/User-information" >Checkout</Link> </Fragment>}
          </div>
          {CartItems.length > 0 &&  
            CartItems.map((Item, Index)=>{
              let ItemNo = Index +1;
              return <div key = {ItemNo} className="card my-3">
                <div className="card-body d-md-flex justify-content-between align-items-center">
                  <h5 className="card-title">{Item.examName}</h5>
                  <p className="card-title">{Item.durationName} - {Item.days} Days</p>
                  <p className="card-title"><b>Cost</b> : ${Item.cost}</p>
                  {/* <button className="btn btn-secondary btn-md btn-rounded" type="button" value={Item.cartItemID} onClick = {this.onRemoveItem}>Remove</button> */}
                  <button className="btn btn-danger btn-md btn-rounded" aria-label="Close" title='Remove' type="button" value={Item.cartItemID} onClick = {this.onRemoveItem}>x</button>
                  {/* <button className="btn-floating btn-sm btn-danger" title='Remove' type="button" value={Item.cartItemID} onClick = {this.onRemoveItem}>
                    <i value={Item.cartItemID} className="fas fa-trash text-white"></i>
                  </button> */}
                </div>
              </div>
            })
          }
          {CartItems.length == 0 &&
            <div className="card my-3">
              <div className="card-body d-md-flex justify-content-between align-items-center">
                <p className="card-title">No Exams In Cart.</p>
              </div>
            </div>
          }
          <div className="my-3 d-flex justify-content-between align-items-center sticky-top">
            {/* <h4 className="text-default"> </h4> */}
            {CartItems.length > 0 && <p className="card-title"><b>Total Cost</b> : ${TotalCost}</p>}
            {/* {CartItems.length > 0 && <Link className="btn btn-default btn-md btn-rounded" to="/User-information" >Checkout</Link>} */}
          </div>
        </div>
      </section>
    )
  }
};

export default UserCart;