import React, { Fragment, useState } from 'react';
import ReactDOM from 'react-dom';
import { infoWrapper } from './InfoAlert';
import axios from 'axios';
import { FormDialogNoTitle } from './FormDialogNoTitle';

export const ContactUSForm = (props) => {
    const [fullName, setFullName] = useState('');
    const [notValidFullName, setNotValidFullName] = useState('');
    const [email, setEmail] = useState('');
    const [notValidEmail, setNotValidEmail] = useState('');
    const [queryType, setQueryType] = useState('');
    const [notValidQueryType, setNotValidQueryType] = useState('');
    const [queryText, setQueryText] = useState('');
    const [notValidQueryText, setNotValidQueryText] = useState('');

    const fullNameChangeHandler = (e) => {
        if(e.target.value.length <= 30){
            setFullName(e.target.value);
        }else{
            (async () => infoWrapper("The maximum characters should not exceed more than 30."))();
        }
        if (notValidFullName !== '') {
            setNotValidFullName('');
        }
    };
    const emailChangeHandler = (e) => {
        if(e.target.value.length <= 30){
            setEmail(e.target.value);
        }else{
            (async () => infoWrapper("The maximum characters should not exceed more than 30."))();
        }
        if (notValidEmail !== '') {
            setNotValidEmail('');
        }
    };
    const queryTypeChangeHandler = (e) => {
        setQueryType(e.target.value);
        if (notValidQueryType !== '') {
            setNotValidQueryType('');
        }
    };
    const queryTextChangeHandler = (e) => {
        if(e.target.value.length <= 255){
            setQueryText(e.target.value);
        }else{
            (async () => infoWrapper("The maximum characters should not exceed more than 30."))();
        }
        if (notValidQueryText !== '') {
            setNotValidQueryText('');
        }
    };
    const validateForm = () => {
        let isValid = true;
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (fullName === '') {
            setNotValidFullName("Please eneter Full Name.");
            isValid = false;
        }
        else if (email === '') {
            setNotValidEmail("Please enter Email.");
            isValid = false;
        }
        else if (email !== '' && !pattern.test(email)) {
            isValid = false;
            setNotValidEmail("Please enter valid Email.");
        }
        else if (queryType === '') {
            setNotValidQueryType("Please choose Query Type.");
            isValid = false;
        }
        else if (queryText === '') {
            setNotValidQueryText("Please eneter your query.");
            isValid = false;
        }
        return isValid;
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if(validateForm()){
            let query = {
                Id: 0,
                Fullname: fullName,
                Email: email,
                Querytype: queryType,
                Querytext: queryText,
            }
            axios.post('/Api/Contactus/ContactUsQuery', query)
            .then(res => {
                (async () => await infoWrapper(res.data))();
            })
            .catch(error => {
                console.log(error);
            }) 
            props.forClose();
        }
    }
    const handleClose = (e)=> {
        e.preventDefault();
        props.forClose();
    }
    return (
        <Fragment>
            <div className="custom-header text-center">
                <h4 className="modal-title w-100 font-weight-bold text-default">Contact Us</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <form action="" onSubmit={handleSubmit}>
                    <div className="md-form" style={{textAlign:"left"}}>
                        <i className="fas fa-user prefix grey-text"></i>
                        <input type="text" id="contactus-fullname" className="form-control round" value={fullName} onChange={fullNameChangeHandler} />
                        <label htmlFor="contactus-fullname">Full Name</label>
                        {(notValidFullName !== '') && <span className="help-block text-danger" style={{paddingLeft:"40px"}}>{notValidFullName}</span>}
                    </div>
                    <div className="md-form" style={{textAlign:"left"}}>
                        <i className="fas fa-envelope prefix grey-text"></i>
                        <input type="email" id="contactus-email" className="form-control" value={email} onChange={emailChangeHandler} />
                        <label htmlFor="contactus-email">Email Address</label>
                        {(notValidEmail !== '') && <span className="help-block text-danger" style={{paddingLeft:"40px"}}>{notValidEmail}</span>}
                    </div>
                    <div className="md-form" style={{textAlign:"left"}}>
                        <i className="fas fa-align-justify prefix grey-text"></i>
                        <select style={{padingLeft: "40px", border: "0", borderBottom:"1px solid lightgrey", width:"90%", marginLeft:"40px"}} id="contactus-query-type" className="form-control" value={queryType} onChange={queryTypeChangeHandler}>
                            <option value="">--Choose Query Type--</option>
                            <option value="Billing">Billing</option>
                            <option value="Refund">Refund</option>
                            <option value="Subscription">Subscription</option>
                            <option value="Payment">Payment</option>
                        </select>
                        {(notValidQueryType !== '') && <span className="help-block text-danger" style={{paddingLeft:"40px"}}>{notValidQueryType}</span>}
                    </div>
                    <div className="md-form" style={{textAlign:"left"}}>
                        <i className="fas fa-pencil-alt prefix  grey-text"></i>
                        <textarea id="form10" className="md-textarea form-control" rows="3" value={queryText} onChange={queryTextChangeHandler}/>
                        <label htmlFor="form10">Query</label>
                        {(notValidQueryText !== '') && <span className="help-block text-danger" style={{paddingLeft:"40px"}}>{notValidQueryText}</span>}
                    </div>
                    <div className="text-center">
                        <button className="btn btn-default btn-rounded my-4" type="submit" onClick={handleSubmit}>Send</button>
                    </div>
                </form>
            </div>
        </Fragment>
    );
}

export const ContactUs = (props) => {
    return (
    <Fragment>
      {ReactDOM.createPortal(
          <Fragment>
          <FormDialogNoTitle open={props.active} closeDialog={props.forClose}>
              <div className="IdentityCont">
                  <ContactUSForm forClose={props.forClose} />
              </div>
          </FormDialogNoTitle> : <Fragment />
      </Fragment>, document.getElementById('popups')
      )}
    </Fragment>
    );
  };
export default ContactUs;