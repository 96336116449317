import React, { Component, Fragment, useState } from 'react';
import * as api from '../API/Api';
import { confirmWrapper } from '../ConfirmAlert';
import { infoWrapper } from '../InfoAlert';
import { FormDialogNew } from '../FormDialogNew';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import $ from 'jquery';

export class AddorUpdateExam extends Component {
    static DisplayName = AddorUpdateExam.name
    constructor(props) {
        super(props)
        this.onSubmit = this.onSubmit.bind(this)
        this.onCancel = this.onCancel.bind(this)
        this.closeDialog = this.closeDialog.bind(this)
        this.state = {
            Action: '',
            ExamID: '',
            ExamCode: '',
            ExamName: '',
            ExamDesc: '',
            Status: '',
            openDialog: false,
            ischecked: true,
            examQuestions: {},
            examDurations: {},
            openMapingDialog: false,
            isDurationMaping: false
        }
    }
    componentDidMount() {
        const id = this.props.id
        if (id > 0) {
            api.fetchData('/Api/Exams/ExamdetailsById?id=' + id)
                .then(res => {
                    let data = res.data
                    if (data.status === 'Deactivated') {
                        this.setState({ ischecked: false })
                    }
                    else {
                        this.setState({ ischecked: true })
                    }
                    this.setState({ Action: 'Update', ExamID: data.examID, ExamCode: data.examCode, ExamName: data.examName, ExamDesc: data.examDesc, Status: data.status })
                    api.fetchData('/Api/Exams/GetExamQuestionsDetails?id=' + data.examID)
                    .then(questions => {
                        let questionList = questions.data
                        let examQuestions = {}
                        questionList.map(examQuestion => {
                            let vid = Math.floor((Math.random() * 10000) + 9000)
                            examQuestions[vid] = { vid: vid, eqid: examQuestion.examQuestionID, qid: examQuestion.questionID, questionCode: examQuestion.questionCode, questionTitle: examQuestion.title, type: examQuestion.type, isDemoQuestion : examQuestion.isDemoQuestion, isdeleted: false }
                        });
                        console.log(examQuestions);
                        this.setState({ examQuestions: examQuestions })
                        $('#dteqlist').DataTable();
                        $('.dataTables_length').addClass('bs-select');
                    })
                    .catch(error => {
                        console.log(error);
                    });
                    api.fetchData('/Api/Exams/GetExamDurations?id=' + data.examID)
                    .then(questions => {
                        let durationList = questions.data
                        let examDurations = {}
                        durationList.map((duration, vid) => {
                            examDurations[vid] = { vid: vid, edid: duration.examDurationID, edCode: duration.durationCode, edName: duration.durationName, edDays: duration.durationDays, edCost: duration.cost}
                        });
                        console.log(examDurations);
                        this.setState({ examDurations: examDurations })
                        $('#dtedlist').DataTable();
                        $('.dataTables_length').addClass('bs-select');
                    })
                    .catch(error => {
                        console.log(error);
                    });
                })
                .catch(error => {
                    console.log(error);
                })
        }
        else {
            this.setState({ Action: 'Create', ExamID: 0, ExamCode: '', ExamName: '', ExamDesc: '', Status: 'Activated' })
        }
    }
    getExamDurations = ()=>{
        api.fetchData('/Api/Exams/GetExamDurations?id=' + this.state.ExamID)
        .then(questions => {
            let durationList = questions.data
            let examDurations = {}
            durationList.map((duration, vid) => {
                examDurations[vid] = { vid: vid, edid: duration.examDurationID, edCode: duration.durationCode, edName: duration.durationName, edDays: duration.durationDays, edCost: duration.cost}
            });
            this.setState({ examDurations: examDurations })
            $('#dtedlist').DataTable();
            $('.dataTables_length').addClass('bs-select');
        })
        .catch(error => {
            console.log(error);
        });
    }
    getExamQuestions = ()=>{
        api.fetchData('/Api/Exams/GetExamQuestionsDetails?id=' + this.state.ExamID)
        .then(questions => {
            let questionList = questions.data
            let examQuestions = {}
            questionList.map(examQuestion => {
                let vid = Math.floor((Math.random() * 10000) + 9000)
                examQuestions[vid] = { vid: vid, eqid: examQuestion.examQuestionID, qid: examQuestion.questionID, questionCode: examQuestion.questionCode, questionTitle: examQuestion.title, type: examQuestion.type, isDemoQuestion : examQuestion.isDemoQuestion, isdeleted: false }
            });
            this.setState({ examQuestions: examQuestions })
            $('#dteqlist').DataTable();
            $('.dataTables_length').addClass('bs-select');
        })
        .catch(error => {
            console.log(error);
        });
    }
    onSubmit(e) {
        e.preventDefault()
        const exam = {
            ExamID: this.state.ExamID,
            ExamCode: this.state.ExamCode,
            ExamName: this.state.ExamName,
            ExamDesc: this.state.ExamDesc,
            Status: this.state.Status
        }
        api.postData('/Api/Exams/AddorUpdateExam', exam)
            .then(res => {
                if (this.state.ExamID === 0){
                    this.props.closeAddorEdit();
                    // (async () => await infoWrapper("New Exam Added Successfully."))();
                }
                else
                    (async () => await infoWrapper("Data Updated Successfully."))();
            })
            .catch(error => {
                console.log(error);
            })
    }
    closeDialog = (e) => {
        this.setState({ openDialog: false })
        console.log("closeDialog")
    }
    openDialog = (e) => {
        this.setState({ openDialog: true })
        console.log("openDialog")
    }
    changeName = (e) => {
        if(e.target.value.length <= 50){
            this.setState({ ExamName: e.target.value })
        }else{
            (async () => infoWrapper("The maximum characters should not exceed more than 50."))();
        }
    }
    changeCode = (e) => {
        if(e.target.value.length <= 15){
            this.setState({ ExamCode: e.target.value })
        }else{
            (async () => infoWrapper("The maximum characters should not exceed more than 15."))();
        }
    }
    changeDesc = (e) => { this.setState({ ExamDesc: e.target.value }) }
    changeStatus = (e) => {
        if (this.state.ischecked) { this.setState({ Status: 'Deactivated', ischecked: false }) }
        else { this.setState({ Status: 'Activated', ischecked: true }) }
    }
    onCancel(e) {
        e.preventDefault()
        this.props.closeAddorEdit();
    }
    openQuestionsMapDialong = () =>{
        this.setState({openMapingDialog: true});
    }
    closeQuestionsMapDialong = () =>{
        this.setState({openMapingDialog: false});
    }
    openDurationsMapDialong = (e) =>{
        e.preventDefault();
        this.setState({isDurationMaping: true});
    }
    closeDurationsMapDialong = () =>{
        this.setState({isDurationMaping: false});
    }
    onMappingExams = () =>{
        this.setState({openMapingDialog: false, examQuestions: {}});
        this.getExamQuestions();
    }
    onMappingDurations = () =>{
        this.setState({isDurationMaping: false, examDurations: {}});
        this.getExamDurations();
    }
    deleteQuestion = async (value) => {
        if (await confirmWrapper('Are you sure that you want to Remove the Question ?')) {
            api.deleteData('api/ExamQuestions/DeleteExamQuestion?id=' + value.eqid)
            .then(res => {
                if (res.data.status === 'Delete') {
                    (async () => infoWrapper(res.data.message))();
                    this.setState({ examQuestions: {} });
                    this.getExamQuestions();
                }
                else {
                    (async () => infoWrapper(res.data.message))();
                }
            })
            .catch( err=>{
                console.log(err.data);
            });
        }
    }
    deleteDuration = async (value) => {
        if (await confirmWrapper('Are you sure that you want to Remove the Duration ?')) {
            api.deleteData('api/ExamDurations/DeleteExamDuration?id=' + value.edid)
            .then(res => {
                if (res.data.status === 'Delete') {
                    (async () => infoWrapper(res.data.message))();
                    this.setState({ examDurations: {} });
                    this.getExamDurations();
                }
                else if (res.data.status === 'Not Deleted') {
                    (async () => infoWrapper(res.data.message))();
                }
            })
        }
    }
    render() {
        const { Action, ExamCode, ExamName, ExamDesc, ischecked, openMapingDialog, isDurationMaping } = this.state;
        return (
            <Fragment>
                <div className="row d-flex justify-content-between card-header align-items-center">
                    <h5 className="">{Action} Exam</h5>
                </div>
                <div className="mb-5" data-aos="fade-up" data-aos-duration="2000">
                    <form action="" method="post" onSubmit={this.onSubmit}>
                        <div className="mb-2" data-aos="fade-up" data-aos-duration="2000">
                            <label htmlFor="exam-code" className="h6">Exam Code</label>
                            <input value={ExamCode}
                                onChange={this.changeCode}
                                type="text" required id="exam-code" className="form-control round" />
                        </div>
                        <div className="mb-2" data-aos="fade-up" data-aos-duration="2000">
                            <label htmlFor="exam-name" className="h6">Exam Name</label>
                            <input value={ExamName}
                                onChange={this.changeName}
                                type="text" required id="exam-name" className="form-control round" />
                        </div>
                        <div className="mb-2" data-aos="fade-up" data-aos-duration="2000">
                            <label htmlFor="exam-description" className="h6">Exam Description</label>
                            <textarea cols="20" rows="3"
                            value={ExamDesc}
                            onChange={this.changeDesc}
                            id="exam-description" className="form-control round"/>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox"
                                checked={ischecked}
                                onChange={this.changeStatus} className="custom-control-input" id="active" />
                            <label className="custom-control-label" htmlFor="active">Active</label>
                        </div>
                        <br/>
                        <div className="mb-2">
                            <button type="submit" className="btn btn-default btn-md" onClick={this.onSubmit}>{Action}</button>
                            <button type="reset" className="btn btn-default btn-md" onClick={this.onCancel}>cancel</button>
                        </div>
                        {Action === 'Update' && (Object.entries(this.state.examDurations).length > 0) && <div className="table-responsive">
                            <p><b>List of Associated Durations</b></p>
                            <table id="dtedlist" className="table table-striped table-bordered table-sm" cellSpacing="0" width="100%" role="grid" aria-describedby="dtedlist_info">
                                <thead>
                                    <tr>
                                        <th className="th-sm">Duration Code</th>
                                        <th className="th-sm">Duration Name (Days)</th>
                                        <th className="th-sm">Cost</th>
                                        <th className="th-sm">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(this.state.examDurations)
                                        .map(([key, value]) => {
                                            const deleteDuration = ()=>{
                                                this.deleteDuration(value);
                                            }
                                            return <tr key={key} id={`ed_${value.vid}`}>
                                                <td>
                                                    {value.edCode}
                                                </td>
                                                <td>
                                                    {value.edName} ({value.edDays} Days)
                                                </td>
                                                <td>
                                                    {value.edCost}
                                                </td>
                                                <td>
                                                    <button title="Remove" type="button" value={value.vid} vid={value.vid} onClick={deleteDuration} className="btn-floating btn-sm btn-danger m-1"><i className="fas fa-trash text-white"></i></button>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>}
                        <br/>
                        {Action === 'Update' && (Object.entries(this.state.examQuestions).length > 0) && <div className="table-responsive">
                            <p><b>List of Associated Questions</b></p>
                            <table id="dteqlist" className="table table-striped table-bordered table-sm" cellSpacing="0" width="100%" role="grid" aria-describedby="dteqlist_info">
                                <thead>
                                    <tr>
                                        <th className="th-sm">Question Code</th>
                                        <th className="th-sm">Question Title</th>
                                        <th className="th-sm">is Demo Question</th>
                                        <th className="th-sm">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(this.state.examQuestions)
                                        .map(([key, value]) => {
                                            if (!(value.isdeleted)) {
                                                const deleteQuestion = ()=>{
                                                    this.deleteQuestion(value);
                                                }
                                                return <tr key={key} id={`eq_${value.vid}`}>
                                                    <td>
                                                        {value.questionCode}
                                                    </td>
                                                    <td>
                                                        {value.questionTitle}
                                                    </td>
                                                    <td>
                                                        {value.isDemoQuestion === true ? "Demo" : ""}
                                                    </td>
                                                    <td>
                                                        <button title="Remove" type="button" value={value.vid} onClick={deleteQuestion} vid={value.vid} className="btn-floating btn-sm btn-danger m-1"><i className="fas fa-trash text-white"></i></button>
                                                    </td>
                                                </tr>
                                            }
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>}
                        <br/>
                        {Action === 'Update' && <div className="mb-5" data-aos="fade-up" data-aos-duration="2000">
                            <button type='button' className='btn btn-default btn-md' title='Maping Questions' onClick={this.openQuestionsMapDialong}>Add Questions</button>
                            <button type='button' className='btn btn-default btn-md' title='Maping Durations' onClick={this.openDurationsMapDialong}>Add Durations</button>
                            <button className="btn btn-default btn-md" type="button" onClick={this.openDialog}>Exam Preview</button>
                        </div>}
                        {openMapingDialog && <FormDialogNew title="Add Questions" open={this.state.openMapingDialog} closeDialog={this.closeQuestionsMapDialong}>
                            <MapingQuestions  examId={this.state.ExamID} onAddMaping={this.onMappingExams} close={this.closeQuestionsMapDialong}/>
                        </FormDialogNew>}
                        {isDurationMaping && <FormDialogNew title="Add Durations" open={isDurationMaping} closeDialog={this.closeDurationsMapDialong}>
                            <MapingDurations  examId={this.state.ExamID} onAddMaping={this.onMappingDurations} close={this.closeDurationsMapDialong}/>
                        </FormDialogNew>}
                        {Action === 'Update' && <div className="FormDiv formAmin">
                            {this.state.openDialog ?
                                <FormDialogNew title="Exam Preview" open={this.state.openDialog} closeDialog={this.closeDialog}>
                                    <ExamPreview examID={this.state.ExamID}></ExamPreview>
                                </FormDialogNew> : <Fragment />
                            }
                        </div>}
                    </form>
                    <br/>
                    
                </div>
            </Fragment>
        )
    }
}

const CheckQuestionView = ({ key, SlNo, Question }) => (
    <div className="questionview">
        <div vid={Question.vid} className="questiontext">Q {SlNo}. {Question.questionTitle}<div className="code"> Code:{Question.questionCode}</div></div>
        <div vid={Question.vid}>{ReactHtmlParser(Question.questionDesc)}</div>
        {
            Object.entries(Question.options)
                .map(([key1, value]) => {
                    return (
                        <div vid={key1}><input type="checkbox" name={key} /><lable className="option">{value.optionText}</lable></div>
                    )
                })
        }
    </div>
);

const RadioQuestionView = ({ key, SlNo, Question }) => (
    <div className="questionview">
        <div vid={Question.vid} className="questiontext">Q {SlNo}. {Question.questionTitle} <div className="code"> Code:{Question.questionCode}</div></div>
        <div vid={Question.vid}>{ReactHtmlParser(Question.questionDesc)}</div>
        {
            Object.entries(Question.options)
                .map(([key1, value]) => {
                    return (
                        <div vid={key1}><input type="radio" name={SlNo} checked={value.isAnswer} /> <lable className="option">{value.optionText}</lable></div>
                    )
                })
        }
    </div>
);

const DropDownQuestionView = ({ key, SlNo, Question }) => (
    <div className="questionview">
        <div vid={Question.vid} className="questiontext">Q {SlNo}. {Question.questionTitle} <div className="code"> Code:{Question.questionCode}</div></div>
        <div vid={Question.vid}>{ReactHtmlParser(Question.questionDesc)}</div>
        <select>
            <option vid={key} value="Select Answer">Select Answer</option>
            {
                Object.entries(Question.options)
                    .map(([key1, value]) => {
                        return (
                            <option vid={key1} value={value.optionText}>{value.optionText}</option>
                        )
                    })
            }
        </select>
    </div>
);
class MapingQuestions extends Component {
    static DisplayName = MapingQuestions.name;
    // props - examId, onAddMaping, close
    constructor(props){
        super(props);
        this.state = {
            activeQuestions : {}
        }
    }
    componentDidMount(){
        api.fetchData('/Api/Exams/QuestionsListByExamID?id=' + this.props.examId)
        .then(questions => {
            let questionList = questions.data
            console.log(questionList);
            let allQuestions = {}
            questionList.map(question => {
                let vid = Math.floor((Math.random() * 10000) + 9000)
                if(question.subjectName != null){
                    allQuestions[vid] = { vid: vid,
                        questionID: question.questionID,
                        questionCode: question.questionCode,
                        title: question.title,
                        subjectName: question.subjectName,
                        isSubjectIncluded: true,
                        isAdded: false
                    }
                }else{
                    allQuestions[vid] = { vid: vid,
                        questionID: question.questionID,
                        questionCode: question.questionCode,
                        title: question.title,
                        subjectName: question.subjectName,
                        isSubjectIncluded: false,
                        isAdded: false
                    }
                }
            });
            console.log(allQuestions);
            this.setState({ activeQuestions: allQuestions });
            $('#dtAQlist').DataTable();
            $('.dataTables_length').addClass('bs-select');
        })
        .catch(error => {
            console.log(error);
        });
    }
    onAddQuestion = (e)=>{
        let allQuestions = this.state.activeQuestions;
        let vid = +e.target.value;
        let item = allQuestions[vid];
        allQuestions[vid] = {vid: vid,
            questionID: item.questionID,
            questionCode: item.questionCode,
            title: item.title,
            subjectName: item.subjectName,
            isSubjectIncluded: item.isSubjectIncluded,
            isAdded: !item.isAdded
        };
        this.setState({ activeQuestions: allQuestions })
    }
    onSubjectInclude = (e) =>{
        let allQuestions = this.state.activeQuestions;
        let vid = +e.target.value;
        let item = allQuestions[vid];
        allQuestions[vid] = {vid: vid,
            questionID: item.questionID,
            questionCode: item.questionCode,
            title: item.title,
            subjectName: item.subjectName,
            isSubjectIncluded: !item.isSubjectIncluded,
            isAdded: item.isAdded
        };
        this.setState({ activeQuestions: allQuestions })
    }
    onChangeAllSelected = () =>{
        if(!this.state.allSelected){
            let allQuestions = {};
            let vid;
            Object.entries(this.state.activeQuestions).map(([key, item]) =>{
                vid = item.vid;
                allQuestions[vid] = {vid: vid,
                    questionID: item.questionID,
                    questionCode: item.questionCode,
                    title: item.title,
                    subjectName: item.subjectName,
                    isSubjectIncluded: item.isSubjectIncluded,
                    isAdded: true
                };
            });
            this.setState({ activeQuestions: allQuestions, allSelected: true })
        }else{
            let allQuestions = {};
            let vid;
            Object.entries(this.state.activeQuestions).map(([key, item]) =>{
                vid = item.vid;
                allQuestions[vid] = {vid: vid,
                    questionID: item.questionID,
                    questionCode: item.questionCode,
                    title: item.title,
                    subjectName: item.subjectName,
                    isSubjectIncluded: item.isSubjectIncluded,
                    isAdded: false
                };
            });
            this.setState({ activeQuestions: allQuestions, allSelected: false })
        }
    }
    onSubmit = (e)=>{
        e.preventDefault();
        let selectedQuestions = [];
        let count = 0;
        Object.entries(this.state.activeQuestions)
        .map(([key, value]) => {
            if(value.isAdded){
                selectedQuestions.push({ExamID: this.props.examId, 
                    QuestionID: value.questionID,
                    SubjectIncluded: value.isSubjectIncluded
                });
                count++;
            }
        })
        if(selectedQuestions.length > 0){
            api.postData('/Api/ExamQuestions/MapExamAndQuestions', selectedQuestions)
            .then(res => {
                (async () => await infoWrapper( count+" Question(s) Added Successfully!"))();
                this.props.onAddMaping();
            })
            .catch(err =>{
                console.log(err.data);
            });
        }else{
            (async () => await infoWrapper(" No Question(s) selected to Add!"))();
        }
    }
    render(){
        return(
            <div className="table-responsive">
                <p>List of Active Questions</p>
                <table id="dtAQlist" className="table table-striped table-bordered table-sm" cellSpacing="0" width="100%" role="grid" aria-describedby="dteqlist_info">
                    <thead>
                        <tr>
                            <th className="th-sm">
                                Select <input type='checkbox' value="all" checked={this.state.allSelected} onChange={this.onChangeAllSelected}/>
                            </th>
                            <th className="th-sm">Question Code</th>
                            <th className="th-sm">Question Title</th>
                            <th className="th-sm">Subject Name</th>
                            <th className="th-sm">Include Subject</th>                            
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(this.state.activeQuestions)
                            .map(([key, value]) => {
                                
                                return <tr key={key} id={`eq_${value.vid}`}>
                                    <td>
                                        <input type='checkbox' value={value.vid} checked={value.isAdded} onChange={this.onAddQuestion} />
                                    </td>
                                    <td>
                                        {value.questionCode}
                                    </td>
                                    <td>
                                        {value.title}
                                    </td>
                                    <td>
                                        {value.subjectName}
                                    </td>
                                    <td>
                                        {(value.subjectName !== null) && <input type='checkbox' value={value.vid} checked={value.isSubjectIncluded} onChange={this.onSubjectInclude}/>}
                                    </td>                                    
                                </tr>
                            })
                        }
                    </tbody>
                </table>
                <br/>   
                <button type='button' className="btn btn-default btn-md" onClick={this.onSubmit}>Add</button>
            </div>
        );
    }
}

class MapingDurations extends Component {
    static DisplayName = MapingDurations.name;
    constructor(props) {
      super(props)
        // examId, onAddMaping, close
      this.state = {
        activeDurations : {},
        allSelected: false
      }
    }
    componentDidMount(){
        api.fetchData('/Api/Exams/DurationsListByExamID?id=' + this.props.examId)
        .then(durations => {
            let durationList = durations.data;
            let allDurations = {};
            durationList.map((duration) => {
                let vid = Math.floor((Math.random() * 10000) + 9000);
                allDurations[vid] = { vid: vid,
                    durationID: duration.durationID,
                    name: duration.name,
                    code: duration.code,
                    days: duration.days,
                    cost: '',
                    isAdded: false
                };
            });
            console.log(allDurations);
            this.setState({ activeDurations: allDurations });
            $('#dtADlist').DataTable();
            $('.dataTables_length').addClass('bs-select');
        })
        .catch(error => {
            console.log(error);
        });
    }
    onChangeAllSelected = ()=>{
        if(!this.state.allSelected){
            let allDurations = {};
            let vid;
            Object.entries(this.state.activeDurations).map(([key, item]) =>{
                vid = item.vid;
                allDurations[vid] = {vid: vid,
                    durationID: item.durationID,
                    name: item.name,
                    code: item.code,
                    days: item.days,
                    cost: item.cost,
                    isAdded: true
                };
            });
            this.setState({ activeDurations: allDurations, allSelected: true });
        }else{
            let allDurations = {};
            let vid;
            Object.entries(this.state.activeDurations).map(([key, item]) =>{
                vid = item.vid;
                allDurations[vid] = {vid: vid,
                    durationID: item.durationID,
                    name: item.name,
                    code: item.code,
                    days: item.days,
                    cost: item.cost,
                    isAdded: false
                };
            });
            this.setState({ activeDurations: allDurations, allSelected: false });
        }
    }
    onAddDuration = (e)=>{
        let allDurations = this.state.activeDurations;
        let vid = +e.target.value;
        let item = allDurations[vid];
        allDurations[vid] = {vid: vid,
            durationID: item.durationID,
            name: item.name,
            code: item.code,
            days: item.days,
            cost: item.cost,
            isAdded: !item.isAdded
        };
        this.setState({ activeDurations: allDurations });
    }
    onCostChanged = (e) =>{
        let allDurations = this.state.activeDurations;
        let vid = +e.target.id;
        let cost = e.target.value;
        let item = allDurations[vid];
        if(cost > -1 || cost === ''){
            allDurations[vid] = {vid: vid,
                durationID: item.durationID,
                name: item.name,
                code: item.code,
                days: item.days,
                cost: cost,
                isAdded: item.isAdded
            };
            this.setState({ activeDurations: allDurations });
        }else{
            (async () => await infoWrapper("Invalid Data. The cost must be 0 to 9999 only!"))();
        }
    }
    onSubmit = (e)=>{
        e.preventDefault();
        let selectedDurations = [];
        let count = 0;
        Object.entries(this.state.activeDurations)
        .map(([key, value]) => {
            if(value.isAdded){
                if(value.cost !== '' && +value.cost > 0){
                    selectedDurations.push({
                        ExamID: this.props.examId, 
                        DurationId: value.durationID,
                        Cost: +value.cost
                    });
                    count++;
                }else{
                    (async () => await infoWrapper("Please enter the cost of Duration - " + value.name))();
                    return;
                }
            }
        });
        if(selectedDurations.length > 0){
            api.postData('/Api/ExamDurations/MapExamAndDurations', selectedDurations)
            .then(res => {
                if (res.data.status === 'Success') {
                    (async () => await infoWrapper( count+" Duration(s) Added Successfully!"))();
                    this.props.onAddMaping();
                }else{
                    (async () => await infoWrapper( res.data.message))();
                }
            })
            .catch(err =>{
                console.log(err.data);
            });
        }else{
            (async () => await infoWrapper(" No Duration(s) selected to Add!"))();
        }
    }
    render(){
        return(
            <div className="table-responsive">
                <p>List of Active Durations</p>
                <table id="dtADlist" className="table table-striped table-bordered table-sm" cellSpacing="0" width="100%" role="grid" aria-describedby="dtADlist_info">
                    <thead>
                        <tr>
                            <th className="th-sm">
                                Select <input type='checkbox' value="all"  onChange={this.onChangeAllSelected}/>  {/* checked={this.state.allSelected} */}
                            </th>
                            <th className="th-sm">Duration Code</th>
                            <th className="th-sm">Duration Name (Validity in Days)</th>
                            <th className="th-sm">Cost</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(this.state.activeDurations)
                            .map(([key, value]) => {
                                
                                return <tr key={key} id={`ed_${value.vid}`}>
                                    <td>
                                        <input type='checkbox' value={value.vid} checked={value.isAdded} onChange={this.onAddDuration} />
                                    </td>
                                    <td>
                                        {value.code}
                                    </td>
                                    <td>
                                        {value.name} ({value.days} Days)
                                    </td>
                                    <td>
                                        <input className="form-control round"
                                        vid={value.vid}
                                        id={value.vid}
                                        value={value.cost}
                                        onChange={this.onCostChanged}
                                        type="number" min={0} max={9999} step={0.01} required />
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
                <br/>   
                <button type='button' className="btn btn-default btn-md" onClick={this.onSubmit}>Add Durations</button>
            </div>
        );
    }
}

class ExamPreview extends Component {
    static DisplayName = ExamPreview.name
    constructor(props) {
        super(props)
        this.state = {
            ExamID: props.examID,
            ExamQuestions: {}
        }
    }
    componentDidMount(){
        api.fetchData('/Api/Exams/GetExamQuestionsDetailsForPreview?id=' + this.state.ExamID)
        .then(questions => {
            let questionList = questions.data
            let examQuestions = {}
            questionList.map(examQuestion => {
                let vid = Math.floor((Math.random() * 10000) + 9000)
                examQuestions[vid] = { 
                    vid: vid, 
                    eqid: examQuestion.examQuestionID, 
                    qid: examQuestion.questionID, 
                    questionCode: examQuestion.questionCode, 
                    questionTitle: examQuestion.title,
                    questionDesc: examQuestion.description,
                    options: examQuestion.options,
                    type: examQuestion.type,  }
            });
            this.setState({ ExamQuestions: examQuestions })
        })
        .catch(error => {
            console.log(error);
        })
    }
    render() {
        let index1 = 0;
        return (
            <div className="FormDiv" style={{ width: "70vw", maxWidth: "550px" }}>
                {
                    Object.entries(this.state.ExamQuestions)
                        .map(([key, value]) => {
                            index1 = index1 + 1;
                            console.log(value);
                            debugger;
                            if (value.type === 'Drop Down') {
                                return (
                                    <DropDownQuestionView key={key} SlNo={index1} Question={value} />
                                )
                            }
                            else if (value.type === 'Check Box') {
                                return (
                                    <CheckQuestionView key={key} SlNo={index1} Question={value} />
                                )
                            }
                            else if (value.type === 'Radio Button') {
                                return (
                                    <RadioQuestionView key={key} SlNo={index1} Question={value} />
                                )
                            }
                        })
                }
            </div>
        )
    }
}

class ViewExam extends Component {
    static DisplayName = AddorUpdateExam.name
    constructor(props) {
        super(props)
        this.onClose = this.onClose.bind(this)
        this.state = {
            Exam: {},
            examQuestions: {}
        }
    }
    componentDidMount() {
        const id = this.props.id
        if (id > 0) {
            api.fetchData('/Api/Exams/ExamdetailsById?id=' + id)
                .then(res => {
                    let data = res.data
                    this.setState({ Exam: data })
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }
    onClose(e) {
        e.preventDefault()
        this.props.closeViewExam()
    }

    render() {
        return (
            <Fragment>
                <div style={{ border: "1px solid blue", margin: "5px" }}>
                    <h5 className="text-headding"> Selected Exam Details</h5>
                    <table className="table" style={{ marginTop: 10, width: "50%" }}>
                        <tbody>
                            <tr>
                                <th>Exam Code :</th> <td>{this.state.Exam.examCode}</td>
                            </tr>
                            <tr>
                                <th>Exam Name :</th> <td>{this.state.Exam.examName}</td>
                            </tr>
                            <tr>
                                <th>Exam Description :</th> <td>{this.state.Exam.examDesc}</td>
                            </tr>
                            <tr>
                                <th>Exam status :</th> <td>{this.state.Exam.status}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <button className="btnSubmit" onClick={this.onClose} type="reset">Close</button>
            </Fragment>
        )
    }
}

const TableRow = (props) => {
    const rowID = "Row_" + props.obj.examID;

    const DeleteExam = async () => {
        if (await confirmWrapper('Are you sure that you want to delete the record ?')) {
            api.deleteData('api/Exams/DeleteExam?id=' + props.obj.examID)
                .then(res => {
                    if (res.data.status === 'Delete') {
                        // (async () => infoWrapper('Record deleted successfully!!'))();
                        document.getElementById("Row_" + props.obj.examID).remove();
                    }
                    else if (res.data.status === 'Not Deleted') {
                        (async () => infoWrapper(res.data.message))();
                    }
                })
        }
    }
    const EditExam = (e) => {
        e.preventDefault();
        props.forEdit(props.obj.examID)
    };
    const ViewExam = (e) => {
        e.preventDefault();
        props.forView(props.obj.examID)
    };
    return (
        <tr id={rowID}>
            <td>
                {props.obj.examCode}
            </td>
            <td className="wraptext">
                {props.obj.examName}
            </td>
            <td className="wraptext">
                {props.obj.examDesc}
            </td>
            <td>
                {props.obj.status}
            </td>
            <td>
                <button title="Edit" type="button" onClick={EditExam} className="btn-floating btn-sm btn-default m-1">
                    <i className="fas fa-pen text-white"></i>
                </button>
                <button title="Delete" type="button" onClick={DeleteExam} className="btn-floating btn-sm btn-danger m-1">
                    <i className="fas fa-trash text-white"></i>
                </button>
            </td>
        </tr>
    );
}

class ExamsList extends Component {
    static DisplayName = ExamsList.name
    constructor(props) {
        super(props)
        this.state = { Exams: [] }
    }
    componentDidMount() {
        api.fetchData('api/Exams/ExamsList')
            .then(response => {
                let data = response.data
                this.setState({ Exams: data })
                $('#dtexamlist').DataTable();
                $('.dataTables_length').addClass('bs-select');
            }).catch(error => {
                console.log(error);
            });
    }
    onAddExam = () => {
        this.props.forAddorEdit(0)
    }
    onEditExam = (id) => {
        this.props.forAddorEdit(id)
    }
    onViewExam = (id) => {
        this.props.forViewExam(id)
    }

    render() {
        const examslist = this.state.Exams.map(exam => <TableRow obj={exam} key={exam.examID} forEdit={this.onEditExam} forView={this.onViewExam} />)
        return (
            <Fragment>
                <div className="row d-flex justify-content-between card-header align-items-center">
                    <h5 className="">Exams</h5>
                    <button className="btn btn-default btn-md btn-rounded" onClick={this.onAddExam}>Add Exam</button>
                </div>
                {this.state.Exams.length > 0 ?
                    <div className="row">
                        <div className="col-sm-12" className="table-responsive">
                            <table id="dtexamlist" className="table table-striped table-bordered table-sm" cellSpacing="0" width="100%" role="grid" aria-describedby="dtexamlist_info" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th className="th-sm">Exam Code</th>
                                        <th className="th-sm">Exam Name</th>
                                        <th className="th-sm">Description</th>
                                        <th className="th-sm">Status</th>
                                        <th className="th-sm">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {examslist}
                                </tbody>
                            </table>
                        </div>
                    </div> : <p>No Data found to Display</p>
                }
            </Fragment>
        )
    }
}
const Exams = () => {
    const [isAddorEdit, setIsAddorEdit] = useState(false);
    const [isView, setIsView] = useState(false);
    const [addorEditorViewId, setaddorEditorViewId] = useState(0);

    const onAddOrEdit = (id) => {
        setIsAddorEdit(true)
        setIsView(false)
        setaddorEditorViewId(id)
    };
    const onView = (id) => {
        setIsView(true)
        setIsAddorEdit(false)
        setaddorEditorViewId(id)
    };
    const forList = () => {
        setIsAddorEdit(false)
        setIsView(false)
    };

    return (
        <Fragment>
            {!isView && !isAddorEdit && <ExamsList forAddorEdit={onAddOrEdit} forViewExam={onView} />}
            {isView && <ViewExam id={addorEditorViewId} closeViewExam={forList} />}
            {isAddorEdit && <AddorUpdateExam id={addorEditorViewId} closeAddorEdit={forList} />}
        </Fragment>
    )
};
export default Exams;
