import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../store/auth-context';
import * as api from '../API/Api';
import { Link } from 'react-router-dom';
import { infoWrapper } from '../InfoAlert';
import { confirmWrapper } from '../ConfirmAlert';

export const ChooseDuration = (props) => {
  const AuthContent = useContext(AuthContext);
  const [ExamId, setExamId] = useState(0);
  const [Exam, setExam] = useState('');
  const [ExamDurations, setExamDurations] = useState([]);
  const [SelectedDuration, setSelectedDuration] = useState('');
  const [DurationIndex, setDurationIndex] = useState('');
  const [Cost, setCost] = useState(0);
  const [cartCount, setcartCount] = useState(0);

  useEffect(() => {
    let id = props.onChooseExam.location.examId;
    if(id === undefined || id === null){ // task for page reload or refresh
      let examid  = JSON.parse(localStorage.getItem('ChooseExamDuration'));
      if(examid === null || examid === undefined){
        setExamId(0);
      }else{
        setExamId(examid);
        id = examid;
      }
    }else{
      setExamId(id);
      localStorage.setItem('ChooseExamDuration', JSON.stringify(id));
    }
    if(id > 0){
      api.fetchData('/Api/Exams/ExamdetailsWithDurationsById?id='+id)
      .then(res => {
        let data = res.data
        let durations = []
        data.examDurations.map((duration, index) => {
          durations[index] = { vid: index, 
            ExamDurationId: duration.examDurationId,
            DurationId: duration.durationId,
            Cost: duration.cost,
            DurationName: duration.durationName,
            Days : duration.days
          }
        })
        const [item] = durations //First Duration
        setExam(data);
        setExamDurations(durations);
        setDurationIndex(item.vid);
        setCost(item.Cost);
        setSelectedDuration(item);
      })
      api.fetchData('/Api/CartItems/CartItemsListByUserName?user='+AuthContent.UserName)
      .then( res =>{
        setcartCount(res.data.length);
      })
      .catch(err=>{
        console.log(err);
      })
    }
  }, [props.onChooseExam.location.examId]);

  const onChangeDuration = (e) => {
    let key = e.target.value;
    let item = ExamDurations[key]
    setDurationIndex(item.vid);
    setCost(item.Cost);
    setSelectedDuration(item);
  };
  const  onAddCart = (e)=>{
    e.preventDefault();
    const CartItem = {
      CartItemID : 0,
      UserName :AuthContent.UserName,
      ExamDurationId : SelectedDuration.ExamDurationId
    }
    const Request = {
      UserName: AuthContent.UserName,
      ExamID: props.onChooseExam.location.examId
    }
    api.postData('/Api/CartItems/IsExamActive', Request)
    .then(async (res)=>{
      if(res.data === true){
        if (await confirmWrapper('Exam already active, do you want to extend subscription?')){
          AddExamToCart(CartItem);
        }
      }
      else{
        AddExamToCart(CartItem);
      }
    })
    .catch(err=>{
      console.log(err.data);
    })
  };
  const AddExamToCart = (CartItem) =>{
    api.postData('/Api/CartItems/AddCartItem', CartItem)
    .then(res => {
      console.log(res.data.message);
      AuthContent.CartCountHandler();
    })
    .catch( err =>{
      (async () => await infoWrapper("Data Not valid"))();
    })
  };
  const DurationsList = ExamDurations.map((dr,index)=><option key = {index} value={dr.vid}>{dr.DurationName} - ${dr.Cost}</option>)

  return (
    <section className="banner-area" data-aos="fade-up" data-aos-duration="2000">
      <div className="container">
        <div className="my-3">
          <h4 className="text-default">Selected Exam Details</h4>
        </div>
        <div className="row justify-content-center">
          {/* {props.onChooseExam.location.image && <div className="col-md-9"> */}
            {/* <div className="card my-3"> */}
              {/* <div className="card-body"> */}
              {/* <img src={props.onChooseExam.location.image} alt="img" /> */}
              {/* </div> */}
            {/* </div> */}
          {/* </div>} */}
          <div className="col-md-9">
            <div className="card my-3">
              <div className="card-body">
                <ul className="list-group list-group-flush mb-3">
                  <li className="list-group-item d-flex">
                    <div className="col-6">Exam Name :</div>
                    <div className="col-6">{Exam.examName}</div>
                  </li>
                  <li className="list-group-item d-flex">
                    <div className="col-6">Exam Description :</div>
                    <div className="col-6">{Exam.examDesc}</div>
                  </li>
                  <li className="list-group-item d-flex">
                    <div className="col-6">Choose Your Duration :</div>
                      <div className="col-6">
                        <select className="custom-select" name = "forDuration" value= {DurationIndex} onChange={onChangeDuration}>
                          {DurationsList}
                        </select>
                      </div>
                  </li>
                  <div className="card-header h6">
                      <b>Price : </b> ${Cost}
                  </div>
                </ul>
                <Link className="btn btn-primary btn-md btn-rounded" to="/home" >Back</Link>
                {ExamId > 0 && <Link className="btn btn-secondary btn-md btn-rounded " to={{pathname:'/exams-demo', examId: ExamId, examName: Exam.examName}}>Take Demo</Link>}
                {ExamId > 0 && <button type="button" className="btn btn-secondary btn-md btn-rounded" onClick={onAddCart}>Add To Cart</button>}
                {cartCount > 0 && <Link className="btn btn-primary btn-md btn-rounded" to="/User-information" >Proceed to Check out</Link>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};
{/* <section className="banner-area" data-aos="fade-up" data-aos-duration="2000">
  <div className="container">
    <div className="exam-duration">
      {props.onChooseExam.location.image?null: <h5 className="text-headding"> Selected Exam Details</h5>}
      {props.onChooseExam.location.image && <div>
        <h3>{Exam.examName}</h3>
        <img src={props.onChooseExam.location.image} alt="img" />
      </div>}
      <br/>
      <table className="table" style={{ marginTop: 10, width: "50%" }}>
        <tbody>
          <tr>
            <th>Exam Name :</th><td>{Exam.examName}</td>
          </tr>
          <tr>
            <th>Exam Description :</th><td>{Exam.examDesc}</td>
          </tr>
          <tr>
            <th>Choose Your Own Duration :</th>
            <td>  
              <select name = "forDuration" value= {DurationIndex} onChange={onChangeDuration}>
                {DurationsList}
              </select>
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <p><b>Cost - ${Cost}</b></p>
      </div>     
    </div>
    <div>
      <Link className="btn btn-success" to="/home" >Back</Link>|
      <button className="btnSubmit" onClick={onAddCart}>Add To Cart</button>|
      <Link className="btn btn-success" to="/cart-items" >Proceed to Checkout</Link>
    </div>
  </div>
</section> */}