import React, { Component } from 'react';
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';



import './HTMLEditor.css'

export class HTMLEditor extends Component{
    static displayName = HTMLEditor.name;  
    constructor(props) {
        super(props);
        this.state = {
            defaultValue: props.defaultValue,
            editorState: ''
        };
    }

    onEditorStateChange = (editorState) => {
        // console.log(editorState)
        this.setState({
            editorState,
        });
    };
    handleBlur = (event, editorContents) => {        
        this.props.handleBlur(editorContents);
    }
    render() {
        const { editorState } = this.state;
        return <div className='editor'>
            <SunEditor defaultValue={this.state.defaultValue} setOptions={{
                toolbarContainer: '#toolbar_container',
                showPathLabel: false,
                charCounter: true,
                maxCharCount: 5000,
                width: 'auto',
                maxWidth: '700px',
                height: 'auto',
                minHeight: '100px',
                maxHeight: '250px',               
                buttonList: [['undo', 'redo', 'font', 'fontSize', 'formatBlock'], ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview']
                ]
                // Other option
            }} onBlur={this.handleBlur} />
        </div>
    }
}