import React, { Fragment, useState } from 'react';
import axios from 'axios';
import { infoWrapper } from '../InfoAlert';

const ForgetPassword = (props) => {
    const [email, setEmail] = useState('');
    const [notValidEmail, setNotValidEmail] = useState('');

    const emailChangeHandler = (e) => {
        if(e.target.value.length <= 30){
            setEmail(e.target.value);
        }else{
            (async () => infoWrapper("The maximum characters should not exceed more than 30."))();
        }
    };
    const validateForm = () => {
        let isValid = true;
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (email === '') {
            setNotValidEmail("Please enter Email.");
            isValid = false;
        }
        else if (email !== '' && !pattern.test(email)) {
            isValid = false;
            setNotValidEmail("Please enter valid Email.");
        }
        return isValid;
    };
    const submitHandler = (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log("Email: " + email);
            sendPasswordResestLink(email);
        }
    };
    const sendPasswordResestLink = async (email) => {
        axios.post('/api/AuthManagement/PasswordResetLink?user=' + email)
            .then(res => {
                const data = res.data;
                (async () => await infoWrapper(data.message))();
            })
            .catch(err => {
                (async () => await infoWrapper("Invalid Request!"))();
            });
    };
    const onLoginHandler = (e) => {
        e.preventDefault();
        props.onLogin();
    };
    const onRegisterHandler = (e) => {
        e.preventDefault();
        props.onRegister();
    };
    const forResendEmail = (e) => {
        e.preventDefault();
        props.onReSendEmail();
    };
    const onClose = (e) => {
        e.preventDefault();
        props.forClose();
    }
    return (
        <Fragment>
            <div id="modalLoginForm" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                aria-hidden="true">
                <div>
                    <div className="custom-header text-center">
                        <h4 className="modal-title w-100 font-weight-bold text-default">Forgot your password?</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" onClick={onClose}>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body mx-1">
                        <form action="#!">

                            <div className="md-form" style={{textAlign:"left"}}>
                                <i className="fas fa-envelope prefix grey-text"></i>
                                <input type="email" id="defaultForm-email" className="form-control" value={email}
                                    onChange={emailChangeHandler} />
                                <label htmlFor="defaultForm-email">Email Address</label>
                                <span className="help-block text-danger" style={{paddingLeft:"40px"}}>{notValidEmail}</span>
                            </div>

                            <div className="text-center">
                                <button className="btn btn-default btn-rounded my-4" onClick={submitHandler} type="submit">Send Reset Link</button>
                            </div>

                        </form>
                        <div>
                            <a href="" onClick={onLoginHandler}>Already have account ? Login here</a> <br /><br />
                            <a href="" onClick={onRegisterHandler}>Don’t have an account? Register here</a><br /><br />
                            <a href="" onClick={forResendEmail}>Resend Email Confirmation</a>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ForgetPassword;