import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { infoWrapper } from '../InfoAlert';

export const ConfirmEmail = (props) => {
  // const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);
  useEffect(() => {
    EmailConfirmationRequest();
  }, []);

  const EmailConfirmationRequest = () => {
    const ConfirmEmail = {
      Email : props.email,
      Token : props.token
    };
    axios.post('/api/AuthManagement/EmailConfirmation', ConfirmEmail )
    .then(res => {
      if(res.data.status === "Success"){
        // setIsEmailConfirmed(true);
        (async () => await infoWrapper("Your Email has been confirmed successfully. Please click on Login."))();
      }else{
        (async () => await infoWrapper(res.data.message))();
      }
    })
    .catch( err => {
      console.log(err);
    });
  };
  return (
    <div>
      {/* <div id="cont">
        Email Confirmation.
      </div>
      {isEmailConfirmed && <p>Your Email has been confirmed successfully.<br/>
      Please click on Login.</p>}
      {!isEmailConfirmed && <p>Error while confirming your email. Please re send confirmation link.</p>} */}
    </div>
  )
}

export default ConfirmEmail;