import React, { Component, Fragment, useState } from 'react'
import { confirmWrapper } from '../ConfirmAlert'
import { infoWrapper } from '../InfoAlert'
import * as api from '../API/Api';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import $ from 'jquery';

export class AddorUpdateState extends Component {
  static DisplayName = AddorUpdateState.name
  constructor(props){
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.state = {
      Action:'',
      StateId:'',
      Name:'',
      Code:'',
      CountryId:'',
      Countries:[]
    }
  }
  componentDidMount(){
    let id = this.props.id
    if(id > 0){
      api.fetchData('/Api/States/StatedetailsById?id='+this.props.id)
      .then(res => {
        let data = res.data
        this.setState({Action: 'Update', StateId: data.stateId, Name: data.name, Code: data.code, CountryId: data.countryId})
      })
      .catch(error=>{
        console.log(error); 
      })
    }
    else{
      this.setState({Action: 'Create', StateId: 0, Name: '', Code:'', CountryId:''})
    }
    api.fetchData('api/Countries/CountriesList')
    .then(response => {
      let data = response.data
      this.setState({ Countries: data })
    })  
    .catch(function (error) {  
      console.log(error);
    })
  }
  onSubmit(e){
    e.preventDefault()
    if(this.state.CountryId === '')
    {
      (async () => await infoWrapper("Please Select the country..."))();
    }
    else
    {
      const State = {
        StateId: this.state.StateId,
        Name:this.state.Name,
        Code:this.state.Code ,
        CountryId:this.state.CountryId     
      }
      api.postData('/Api/States/AddorUpdateState', State)
      .then(res => {
        // if(this.state.StateId === 0)
        //   (async () => await infoWrapper("New State Added Successfully."))();
        // else
        //   (async () => await infoWrapper("Data Updated Successfully."))();
        this.props.closeAddorEdit();
      })
    }
  }
  changeName = (e)=>{
    if(e.target.value.length <= 100){
      this.setState({ Name : e.target.value})
    }else{
      (async () => infoWrapper("The maximum characters should not exceed more than 100."))();
    }
  }
  changeCode = (e)=>{
    if(e.target.value.length <= 15){
      this.setState({ Code : e.target.value})
    }else{
      (async () => infoWrapper("The maximum characters should not exceed more than 15."))();
    }
  }
  changeCountry = (e)=>{ this.setState({ CountryId : e.target.value})}
  onCancel(e){
    e.preventDefault()
    this.props.closeAddorEdit();
  }
  render() {
    const {Action, Name, Code, CountryId, Countries} = this.state;
    const CountriesList = Countries.map(obj => <option key={obj.countryId} value={obj.countryId}>{obj.name}</option>)
    return (
      <Fragment>
        <div className="row d-flex justify-content-between card-header align-items-center">
          <h5 className="">{Action} State</h5>
        </div>
        <div className="mb-5" data-aos="fade-up" data-aos-duration="2000">
          <form action="" method="post" onSubmit={this.onSubmit}>
            <div className="my-4" data-aos="fade-up" data-aos-duration="2000">
              <label htmlFor="state-code" className="h6">State Code</label>
              <input className="form-control round"
                id="state-code"
                value={Code}
                onChange={this.changeCode}
                type="text" required />
            </div>
            <div className="my-4" data-aos="fade-up" data-aos-duration="2000">
              <label htmlFor="state-name" className="h6">State Name</label>
              <input className="form-control round"
                id="state-name"
                value={Name}
                onChange={this.changeName}
                type="text" required />
            </div>
            <div className="my-2" data-aos="fade-up" data-aos-duration="2000">
              <label htmlFor="country" className="h6">Country</label>
              <select name="country" className="custom-select" value={CountryId} onChange={this.changeCountry}>
                <option value="">Please Select Country...</option>
                {CountriesList}
              </select>
            </div>
            <div className="my-2">
              <button type="submit" className="btn btn-default btn-md" onClick={this.onSubmit}>{Action}</button>
              <button type="reset" className="btn btn-default btn-md" onClick = {this.onCancel}>Cancel</button>
            </div>
          </form>
      </div>
    </Fragment>
    )
  }
}

const TableRow = (props)=>{
  const rowID = "Row_" + props.obj.stateId;

  const DeleteState = async () => {
    if (await confirmWrapper('Are you sure that you want to delete the record ?')){
      api.deleteData('api/States/DeleteState?id=' + props.obj.stateId)
      .then(res => {
        if (res.data.status === 'Delete') {
          // (async () => infoWrapper('Record deleted successfully!!'))();
          document.getElementById("Row_" + props.obj.stateId).remove();
        }
      })
    }
  };
  const EditState = (e)=>{
    e.preventDefault();
    props.forEdit(props.obj.stateId)
  };
  return (
    <tr id={rowID}>  
      <td>
        {props.obj.code}  
      </td>
      <td>
        {props.obj.name}  
      </td>
      <td>
        {props.obj.country}  
      </td>
      <td>
        <button title="Edit" type="button" onClick={EditState} className="btn-floating btn-sm btn-default m-1">
          <i className="fas fa-pen text-white"></i>
        </button>
        <button title="Delete" type="button" onClick={DeleteState} className="btn-floating btn-sm btn-danger m-1">
          <i className="fas fa-trash text-white"></i>
        </button>
      </td>
    </tr>
  );
};

class StatesList extends Component {
  static DisplayName = StatesList.name
  constructor(props){
    super(props)
    this.state = { States: []}
  }
  componentDidMount(){
    // Replced Country Name Insted of Country ID when retriving data from the server
    api.fetchData('api/States/StatesListWithCountry')
    .then(response => {
      let data = response.data;
      this.setState({ States: data });
      $('#dtStatesList').DataTable();
      $('.dataTables_length').addClass('bs-select');
    })  
    .catch(function (error) {  
      console.log(error);  
    })
  }
  onAddState = ()=>{
    this.props.forAddorEdit(0)
  }
  forEditState = (id)=>{
    this.props.forAddorEdit(id)
  }

  render() {
    const Stateslist = this.state.States.map(State => <TableRow obj={State} key={State.stateId} forEdit={this.forEditState}/>)
    return (
      <Fragment>
        <div className="row d-flex justify-content-between card-header align-items-center">
          <h5 className="">States</h5>
            <button className="btn btn-default btn-md btn-rounded" onClick={this.onAddState}>Add State</button>
        </div>
        { this.state.States.length >0 ?
          <div className="row">
            <div className="col-sm-12" className="table-responsive">
              <table id="dtStatesList" className="table table-striped table-bordered table-sm" cellSpacing="0" width="100%" role="grid" aria-describedby="dtStatesList_info" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th className="th-sm">State Code</th>
                    <th className="th-sm">State Name</th>
                    <th className="th-sm">Country/Region</th>
                    <th className="th-sm">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Stateslist}
                </tbody>
              </table>
            </div>
          </div>:<p>No Data found to Display</p>
        }
      </Fragment>
    )
  }
}

const State=()=> {
  const [isAddorEdit, setIsAddorEdit] = useState(false);
  const [addorEditId, setAddorEditId] = useState(0);

  const onAddOrEdit = (id)=>{
    setIsAddorEdit(true)
    setAddorEditId(id)
  };
  const forList = ()=>{
    setIsAddorEdit(false)
  }

  return (
    <Fragment>
      { !isAddorEdit && <StatesList forAddorEdit={onAddOrEdit}/> }
      { isAddorEdit && <AddorUpdateState id={addorEditId} closeAddorEdit={forList}/>}
    </Fragment>
  )
};
export default State;