import React, { useState } from "react";
import styled from "@emotion/styled";

import Row from "./prebuilt/Row";
import SubmitButton from "./prebuilt/SubmitButton";
import CheckoutError from "./prebuilt/CheckoutError";

import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';

const CardElementContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;

  & .StripeElement {
    width: 100%;
    padding: 15px;
  }
`;

const CheckoutForm = ({ price, CartItems, submitHandler }) => {
    const [isProcessing, setProcessingTo] = useState(false);
    const [checkoutError, setCheckoutError] = useState();

    const handleFormSubmit = async ev => {
        ev.preventDefault();

        setProcessingTo(true);
        submitHandler(CartItems, price);
        //axios.post('/api/Payment/SubmitPayment', { value: "GP" }).then(res => {
        //    console.log(res.data);
        //    onSuccessfulCheckout();
        //}).catch(err => {
        //    console.log(err);
        //});
        console.log("GP");
    };

    const cardElementOptions = {
        style: {
            base: {
                fontSize: "16px",
                color: "#fff",
                "::placeholder": {
                    color: '#87bbfd'
                }
            },
            invalid: {
                color: "#FFC7EE",
                iconColor: "#FFC7EE",
            },
            complete: {

            }
        },
        hidePostalCode: true,
    };

    return (
        <form onSubmit={handleFormSubmit}>
            <Row>
                <CardElementContainer>
                    <CardElement options={cardElementOptions} />
                </CardElementContainer>
            </Row>
            {checkoutError && <CheckoutError>{checkoutError}</CheckoutError>}
            <Row>
                <SubmitButton disabled={isProcessing}>
                    {isProcessing ? "Processing..." : `Pay ${price}`}
                </SubmitButton>
            </Row>
        </form>
    );
};

export default CheckoutForm;
