import React, { Component, Fragment, useState } from 'react'
import { confirmWrapper } from '../ConfirmAlert'
import { infoWrapper } from '../InfoAlert'
import * as api from '../API/Api';
import { Checkbox } from '@material-ui/core';
import { HTMLEditor } from '../HTMLEditor'
import { FormDialog } from '../FormDialog'
import { FormDialogNew } from '../FormDialogNew';
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import $ from 'jquery'

export class AddorUpdateQuestionBank extends Component {
    static DisplayName = AddorUpdateQuestionBank.name
    constructor(props){
        super(props)
        this.onSubmit = this.onSubmit.bind(this)
        this.onCancel = this.onCancel.bind(this)
        this.appendChild = this.appendChild.bind(this)
        this.deleteChild = this.deleteChild.bind()
        this.IsAnswerChanged = this.IsAnswerChanged.bind()
        this.optionTextChanged = this.optionTextChanged.bind()
        this.onExamSelectionChanged = this.onExamSelectionChanged.bind()
        this.addExams = this.addExams.bind(this)
        this.state = {
            Action:'',
            QuestionID:'',
            QuestionCode: '',
            QuestionDesc: '',
            QuestionExp: '',
            Title: '',
            Type:'',
            SubjectID: null,
            Status: '',
            isDemoQuestion: false,
            ischecked: true,
            Options: {},
            optionsCount: 1001,
            Exams: {},
            Subjects: [],
            ExamQuestions: [],
            openDescDialog: false,
            openExpDialog: false,
            openExamsListDialog: false
        }
    }
    closeDialog = (e) => {
        this.setState({ openDescDialog: false, openExpDialog: false, openExamsListDialog: false })
        console.log("closeDialog")
    }
    openDescDialog = (e) => {
        this.setState({ openDescDialog: true })
    }
    openExpDialog = (e) => {
        this.setState({ openExpDialog: true })
    }
    openExamsListDialog = (e) => {
        this.setState({ openExamsListDialog: true })
    }
    handleBlurDesc = (editorContent) => {
        console.log(editorContent); //Get the blur event
        this.setState({ QuestionDesc : editorContent })
    }
    handleBlurExp = (editorContent) => {
        this.setState({ QuestionExp : editorContent })
    }
    componentDidMount(){
        const id = this.props.id
        this.fecthData(id);
        api.fetchData('api/Subjects/SubjectsList')
        .then(response => {
            this.setState({ Subjects: response.data });
        })
        .catch(err =>{
            console.log(err.message);
        });
    }
    fecthData = (id) =>{
        if(id > 0){
            api.fetchData('/Api/QuestionBanks/QuestionBankdetailsById?id='+id)
            .then(res => {
                let data = res.data
                api.fetchData('/Api/QuestionBankOptions/QuestionBankOptiondetailsByQuestionId?id=' + data.questionID)
                    .then(opt => {
                        let optiondata = opt.data
                        let options = {}
                        let vid  = this.state.optionsCount;
                        optiondata.map(option => {
                            // let vid = Math.floor((Math.random() * 10000) + 9000)
                            options[vid] = { vid: vid, id: option.questionBankOptionID, Text: option.optionText, checked: option.isAnswer, isdeleted:false }
                            vid = vid + 1;
                        })
                        this.setState({ Options: options, optionsCount: vid  })
                    })
                    .catch(error=>{
                        console.log(error);
                    })
                if (data.status ==='Deactivated'){
                    this.setState({ischecked: false})
                }
                else{
                    this.setState({ischecked: true})
                }
                this.getExamsData(data.questionID);
                if(data.subjectID === null){
                    this.setState({ Action: 'Update', QuestionID: data.questionID, QuestionCode: data.questionCode, QuestionDesc: data.questionDesc, QuestionExp: data.questionExp, Title: data.title, Type: data.type, SubjectID:'', Status: data.status, isDemoQuestion: data.isDemoQuestion })
                }else{
                    this.setState({ Action: 'Update', QuestionID: data.questionID, QuestionCode: data.questionCode, QuestionDesc: data.questionDesc, QuestionExp: data.questionExp, Title: data.title, Type: data.type, SubjectID: data.subjectID, Status: data.status, isDemoQuestion: data.isDemoQuestion })
                }
            })
            .catch(error=>{
                console.log(error);
            });
        }
        else{
            this.setState({ Action: 'Create', QuestionID: 0, QuestionCode: '', QuestionDesc: '', QuestionExp: '', Title: '', Type: '', SubjectID:'', Status: 'Activated', isDemoQuestion: false })
        }
    }
    getExamsData = (id)=>{
        api.fetchData('api/Exams/ExamsListByQuestionID?id=' + id)
            .then(response => {
                let examsData = response.data
                let exams = {}
                examsData.map(exam => {
                    let vid = Math.floor((Math.random() * 10000) + 9000)
                    exams[vid] = { vid: vid, id: exam.examID, code: exam.examCode, name: exam.examName, checked: false, isdeleted: false }
                })
                this.setState({ Exams: exams });
                $('#dtAllexams').DataTable();                
                $('.dataTables_length').addClass('bs-select');
            })
            .catch(error=>{
                console.log(error);
            })
        api.fetchData('api/ExamQuestions/AssociatedExamsList?id=' + id)
            .then(response => {
                let ExamQuestions = response.data;
                let examQuestions = {};
                ExamQuestions.map(eq => {
                    let vid = Math.floor((Math.random() * 10000) + 9000)
                    examQuestions[vid] = { vid: vid,
                        id: eq.examQuestionID,
                        examID: eq.examID,
                        questionID: eq.questionID,
                        examCode: eq.examCode,
                        examName: eq.examName,
                        subjectIncluded: eq.subjectIncluded,
                        checked: eq.subjectIncluded
                    }
                })
                this.setState({ ExamQuestions: examQuestions });
                $('#dtaelist').DataTable();
                $('.dataTables_length').addClass('bs-select');
            })
            .catch(error=>{
                console.log(error);
            })
    }
    onSubjectInludeChangeHandler = (item) =>{
        let associatedExams = this.state.ExamQuestions;
        associatedExams[item.vid] = { vid: item.vid,
            id: item.id,
            examID: item.examID,
            questionID: item.questionID,
            examCode: item.examCode,
            examName: item.examName,
            subjectIncluded: item.subjectIncluded,
            checked: !item.checked,
            isdeleted: false
        };
        this.setState({ExamQuestions: associatedExams });
    }
    removeAssociateExam = async (item) =>{
        if (await confirmWrapper('Are your sure that you want to delete the record ?')){
            api.deleteData('api/ExamQuestions/DeleteExamQuestion?id=' + item.id)
            .then(res => {
                if (res.data.status === 'Delete') {
                    // (async () => infoWrapper(res.data.message))();
                    this.getExamsData(this.state.QuestionID);
                }
                else {
                    (async () => infoWrapper(res.data.message))();
                }
            })
            .catch( err=>{
                console.log(err.data);
            });
        }
    }
    onSubmit(e){
        e.preventDefault()
        let items = [];
        let optionsAnswers = 0;
        Object.entries(this.state.Options)
        .map(([key, value]) => {
            items.push({ QuestionBankOptionID: value.id, OptionText: value.Text, IsAnswer: value.checked, IsDeleted: value.isdeleted });
            if(value.checked){
                optionsAnswers = optionsAnswers + 1;
            }
        });
        let subjectId;
        if(this.state.SubjectID === "" || this.state.SubjectID === ''){
            subjectId = null;
        }
        else{
            subjectId = this.state.SubjectID;
        }
        const QuestionBank = {
            QuestionID: this.state.QuestionID,
            QuestionCode: this.state.QuestionCode,
            QuestionDesc: this.state.QuestionDesc,
            QuestionExp: this.state.QuestionExp,
            Title: this.state.Title,
            Type:this.state.Type,
            SubjectID: subjectId,
            Status: this.state.Status,
            isDemoQuestion: this.state.isDemoQuestion,
            QuestionBankOptions: items
        }
        let updatedExamQuestions = []
        Object.entries(this.state.ExamQuestions)
        .map(([key, value]) => {
            if (value.subjectIncluded !== value.checked) {
                updatedExamQuestions.push({ExamQuestionID: value.id, ExamID: value.examID, QuestionID: value.questionID, SubjectIncluded: value.checked })
            }
        });
        if(updatedExamQuestions.length > 0){
            api.postData('/Api/ExamQuestions/UpdateSubjectIncluded', updatedExamQuestions)
            .then(resOfSubInc => {
                console.log(resOfSubInc.data);
            })
            .catch(error=>{
                console.log(error);
            });
        }
        if(items.length > 0 && optionsAnswers === 0){
            (async () => await infoWrapper("Please select an answer for the options!"))();
            return;
        }
        api.postData('/Api/QuestionBanks/AddorUpdateQuestionBank', QuestionBank)
        .then(res => {
            console.log(res);
            if(res.data.status === "Success"){
                let id = +res.data.message;
                (async () => await infoWrapper("New Question Added Successfully."))();
                this.fecthData(id);
                // this.props.closeAddorEdit();
            }
            else if(res.data.status === "Updated"){
                (async () => await infoWrapper("Data Updated Successfully."))();
            // this.props.closeAddorEdit();
            }else if(res.data.status === "Error"){
                (async () => await infoWrapper("Error while adding/updatin question!"))();
            }else{
                (async () => await infoWrapper("Error while adding/updatin question!"))();
            }
        })
        .catch(error => {
            (async () => await infoWrapper("Error while adding Question."))();
        });
    }

    changeTitle = (e) => {
        if(e.target.value.length <= 50){
            this.setState({ Title: e.target.value })
        }else{
            (async () => infoWrapper("The maximum characters should not exceed more than 50."))();
        }
    }
    changeCode = (e)=>{
        if(e.target.value.length <= 15){
            this.setState({ QuestionCode : e.target.value})
        }else{
            (async () => infoWrapper("The maximum characters should not exceed more than 15."))();
        }
    }
    changeType = (e)=>{ this.setState({ Type : e.target.value})}
    changeStatus = (e)=>{
        if(this.state.ischecked){this.setState({ Status: 'Deactivated', ischecked: false})}
        else{this.setState({ Status: 'Activated', ischecked: true})}
    }
    changeSubject = (e) =>{this.setState({SubjectID: e.target.value})};
    onCancel(e){
        e.preventDefault()
        this.props.closeAddorEdit();
    }
    changeIsDemoQuestion = ()=>{
        this.setState({ isDemoQuestion: !this.state.isDemoQuestion});
    }
    appendChild = () => {
        let { Options } = this.state;
        // let vid = Math.floor((Math.random() * 10000) + 9000)
        let vid = this.state.optionsCount;
        Options[vid] = { vid: vid, id: 0, Text: "", checked: false, isdeleted: false };
        vid = vid + 1;
        this.setState({ Options: Options, optionsCount: vid });
    };

    IsAnswerChanged = (e) => {
        let { Options } = this.state;
        let key = e.target.getAttribute("vid");
        let index = Options[key]

        if (this.state.Type === "Check Box" || this.state.Type === "True or False") {
            index.checked = !(index.checked);
        }
        else {
            Object.entries(this.state.Options)
                .map(([key, value]) => (
                    value.checked = false
                ));
            index.checked = true;
        }
        this.setState({ Options: Options });
    };

    optionTextChanged = (e) => {
        let { Options } = this.state;
        let key = e.target.getAttribute("vid");
        let index = Options[key]
        Options[key].Text = e.target.value;
        this.setState({ Options: Options });
    };

    deleteChild = (e) => {
        let { Options } = this.state;
        let key = e.target.getAttribute("vid");
        let index = Options[key]
        if (index.checked) {
            (async () => await infoWrapper("Answer record cannot be deleted."))();
        }
        else {
            if (index.id == 0)
                delete Options[key];
            else
                index.isdeleted = true
            this.setState({ Options: Options });
        }
    };

    onExamSelectionChanged = (e) => {
        let { Exams } = this.state;
        let key = e.target.getAttribute("vid");
        let item = Exams[key]
        item.checked = !(item.checked);
        this.setState({ Exams: Exams });
    }

    addExams = () => {
        let selectedExams = []
        Object.entries(this.state.Exams)
            .map(([key, value]) => {
                if (value.checked) {
                    selectedExams.push({ ExamID: value.id, QuestionID: this.state.QuestionID })
                }
            });
        api.postData('/Api/ExamQuestions/MapExamAndQuestions', selectedExams)
            .then(res => {
                // if(this.state.QuestionID === 0)
                //   (async () => await infoWrapper("New QuestionBank Added Successfully."))();
                // else
                //   (async () => await infoWrapper("Data Updated Successfully."))();
                // this.props.closeAddorEdit();
                this.getExamsData(this.state.QuestionID);
                this.closeDialog();
            })
            .catch(error=>{
                console.log(error);
            })
    };

    render() {
        const { Action, QuestionCode, QuestionDesc, QuestionExp, Title, Type, SubjectID, ischecked, isDemoQuestion} = this.state;
        const subjectsList = this.state.Subjects.map((sub, index )=> <option key={index} value={sub.subjectID}>{sub.subjectName}</option>);
        return (
            <Fragment>
                <div className="row d-flex justify-content-between card-header align-items-center">
                    <h5 className="">{Action} Question</h5>
                </div>
                <div className="mb-2" data-aos="fade-up" data-aos-duration="2000">
                    <form method="post" onSubmit={this.onSubmit}>
                        <div className="mb-2" data-aos="fade-up" data-aos-duration="2000">
                            <label htmlFor="question-code" className="h6">Question Code</label>
                            <input className="form-control round"
                                id="question-code"
                                value={QuestionCode}
                                onChange={this.changeCode}
                                type="text" required />
                        </div>
                        <div className="my-2" data-aos="fade-up" data-aos-duration="2000">
                            <label htmlFor="question-title" className="h6">Question Title</label>
                            <input className="form-control round"
                                id="question-title"
                                value={Title}
                                onChange={this.changeTitle}
                                type="text" required />
                        </div>
                        <div className="my-2">
                            <button className="btn btn-default btn-md" type="button" onClick={this.openDescDialog}>Add/Edit Description</button>
                            {this.state.openDescDialog ?
                                <FormDialog title="Question Description" open={this.state.openDescDialog} closeDialog={this.closeDialog}>
                                    <HTMLEditor defaultValue = { this.state.QuestionDesc } handleBlur = { this.handleBlurDesc } />
                                </FormDialog> : <Fragment />
                            }
                            <button className="btn btn-default btn-md" type="button" onClick={this.openExpDialog}>Add/Edit Explanation</button>
                            {this.state.openExpDialog ?
                                <FormDialog title="Question Explanation" open={this.state.openExpDialog} closeDialog={this.closeDialog}>
                                    <HTMLEditor defaultValue = { this.state.QuestionExp } handleBlur = { this.handleBlurExp } />
                                </FormDialog> : <Fragment />
                            }
                        </div>
                        <div className="my-2" data-aos="fade-up" data-aos-duration="2000">
                            <label htmlFor="question-subject" className="h6">Subject</label>
                            <select className="custom-select" value={SubjectID} onChange={this.changeSubject}>
                                <option value=''>No Subject</option>
                                {subjectsList}
                            </select>
                        </div>
                        <div className="my-2" data-aos="fade-up" data-aos-duration="2000">
                            <label htmlFor="question-type" className="h6">Question Type</label>
                            <select className="custom-select" value={Type} onChange={this.changeType}>
                                <option value="">-- Choose Question Type--</option>
                                <option value="Radio Button">Radio Button</option>
                                <option value="True or False">True or False</option>
                                <option value="Check Box">Check Box</option>
                                {/* <option value="Drop Down">Drop Down</option> */}
                            </select>
                        </div>
                        <div className="my-2" data-aos="fade-up" data-aos-duration="2000">
                            <input type="checkbox"
                                checked={ischecked}
                                onChange={this.changeStatus}/> Active.
                        </div>
                        <div className="my-2" data-aos="fade-up" data-aos-duration="2000">
                            <input type="checkbox"
                                checked={isDemoQuestion}
                                onChange={this.changeIsDemoQuestion}/> Demo Question .
                        </div>
                        {Object.keys(this.state.Options).length > 0 ?
                            <div className="my-2">
                                <div className="row align-items-center my-2">
                                    <div className="col-10" >
                                        Answer Options
                                    </div>
                                    <div className="col-1" style={{ whiteSpace:'nowrap' }}>
                                        {
                                            this.state.Type === 'Radio Button' ? 'Answer':'Is Correct'
                                        }
                                    </div>
                                </div>
                                {Object.entries(this.state.Options)
                                    .map(([key, value]) => {
                                        if (!(value.isdeleted)) {
                                            return (
                                                <QuestionOptions key={key} item={value} type={this.state.Type} code={this.state.QuestionCode} deleteChild={this.deleteChild} onChangeAnswer={this.IsAnswerChanged} optionTextChanged={this.optionTextChanged} />
                                            )
                                        }
                                    })
                                }
                            </div>
                            :<div className="my-2"><p>No Options available</p></div>
                        }
                        <div className="my-2">
                            <button type="button" className="btn btn-default btn-md" onClick={this.appendChild}>Add Option</button>
                        </div>
                        {Object.keys(this.state.ExamQuestions).length > 0 ?
                            <div className="">
                                <p>List of Associated Exams</p>
                                <table name="dtaelist" id="dtaelist" className="table table-striped table-bordered table-sm" cellSpacing="0" width="100%" role="grid" aria-describedby="dtaelist_info">
                                    <thead>
                                        <tr>
                                            <th className="th-sm">Exam Question ID</th>
                                            <th className="th-sm">Exam Code</th>
                                            <th className="th-sm">Exam Title</th>
                                            <th className="th-sm">Include Subject</th>
                                            <th className="th-sm">Remove Exam</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.entries(this.state.ExamQuestions)
                                            .map(([key, eq]) => {
                                                if (!(eq.isdeleted)) {
                                                    return (<EQRow key={key} item={eq} subjectIncludeChange={this.onSubjectInludeChangeHandler} unMapingExam={this.removeAssociateExam} />)
                                                }
                                            })}
                                    </tbody>
                                </table>
                            </div>
                            : <div>Associated Exams are empty</div>
                        }
                        {this.state.Action === "Update" && <div className="my-2">
                            <button className="btn btn-default btn-md" type="button" onClick={this.openExamsListDialog}>Add Exams</button>
                        </div>}
                        <br/>
                        <div className="my-2">
                            <button type="submit" className="btn btn-default btn-md" onClick={this.onSubmit}>{Action}</button>
                            <button type="reset" className="btn btn-default btn-md" onClick = {this.onCancel}>Close</button>
                        </div>
                        {this.state.openExamsListDialog ?
                            <FormDialogNew title="Select Exams" open={this.state.openExamsListDialog} closeDialog={this.closeDialog}>
                                {Object.keys(this.state.Exams).length > 0 ?
                                    <div className="">
                                        <p>Active Exams List</p>
                                        <table name="dtAllexams" id="dtAllexams" className="table table-striped table-bordered table-sm" cellSpacing="0" width="100%" role="grid" aria-describedby="dtAllexams_info">
                                            <thead>
                                                <tr>
                                                    <th className="th-sm">Select</th>
                                                    <th className="th-sm">Exam Code</th>
                                                    <th className="th-sm">Exam Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.entries(this.state.Exams)
                                                    .map(([key, value]) => {
                                                        if (!(value.isdeleted)) {
                                                            return (<ExamRow key={key} item={value} onChange={this.onExamSelectionChanged} ischecked={false} />)
                                                        }
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        <div className="my-2">
                                            <button className="btn btn-default btn-md" type="button" onClick={this.addExams}>Add Exams</button>
                                        </div>
                                    </div>
                                    : <div className=""><p>No Active Exams available</p></div>
                                }
                            </FormDialogNew> : <Fragment />
                        }

                    </form>
                </div>
            </Fragment>
        )
    }
}

const QuestionOptions = ({ type, code, deleteChild, item, onChangeAnswer, optionTextChanged }) => (
    <div className="row align-items-center my-2">
        <div className="col-10">
            <input type="text"
                placeholder="Enter option text"
                value={item.Text}
                onChange={optionTextChanged}
                vid={item.vid}
                id="exam-name" className="form-control round" required/>
        </div>
        <div className="col-1">
            <div className={type === 'Radio Button' ? 'custom-control custom-radio' : 'custom-control custom-checkbox'}>
                <input className="custom-control-input" type={type === 'Radio Button' ? 'radio' : 'checkbox'} name={code} vid={item.vid} onChange={onChangeAnswer} checked={item.checked} />
                <label className="custom-control-label" htmlFor={code}></label>
            </div>
        </div>
        <div className="col-1">
            <button type="button" className="close text-danger" aria-label="Close" title="Delete" onClick={deleteChild}  vid={item.vid}>&times;</button>
        </div>
    </div>
);

const ExamRow = ({ item, onChange, ischecked }) => (
    <tr>
        <td className="align-items-center">
            <input id={`I_${item.vid}`} vid={item.vid} type="checkbox" checked={item.checked} onChange={onChange} />
        </td>
        <td>
            {item.code}
        </td>
        <td>
            {item.name}
        </td>
    </tr>
);

const EQRow = (props) =>{
    const changeHandler = ()=>{
        props.subjectIncludeChange(props.item);
    }
    const RemoveExamHandler = (e)=>{
        e.preventDefault();
        props.unMapingExam(props.item);
    }
    return (
        <tr>
            <td>
                {props.item.id}
            </td>
            <td>
                {props.item.examCode}
            </td>
            <td>
                {props.item.examName}
            </td>
            <td>
                <input type="checkbox" checked={props.item.checked} onChange={changeHandler}/>
            </td>
            <td>
                <button type="button" className="close text-danger" aria-label="Close" title="Remove from Question" onClick={RemoveExamHandler}>&times;</button>
            </td>
        </tr>
    );
}

const TableRow = (props)=>{
    const rowID = "Row_" + props.obj.questionID;

    const DeleteQuestionBank = async () => {
        if (await confirmWrapper('Are you sure that you want to delete the record ?')){
            api.deleteData('api/QuestionBanks/DeleteQuestionBank?id=' + props.obj.questionID)
                .then(res => {
                    if (res.data.status === 'Delete') {
                        // (async () => infoWrapper('Record deleted successfully!!'))();
                        document.getElementById("Row_" + props.obj.questionID).remove();
                    }
                })
        }
    };
    const EditQuestionBank = (e)=>{
        e.preventDefault();
        props.forEdit(props.obj.questionID)
    };
    const CloneQuestion = async (e)=>{
        if (await confirmWrapper('Are you sure that you want to clone the Question ?')){
            api.postData('api/QuestionBanks/CloneQuestion?id=' + props.obj.questionID)
                .then(res => {
                    props.forEdit(res.data);
                })
        }
    };
    return (
        <tr id={rowID}>
            <td>
                {props.obj.questionCode}
            </td>
            <td>
                {props.obj.title}
            </td>
            <td>
                {props.obj.subject === null ? "No Subject": props.obj.subject.subjectName }
            </td>
            <td>
                {props.obj.type}
            </td>
            <td>
                {props.obj.status}
            </td>
            <td style={{ minWidth: "133px"}}>
                <button title="Edit" type="button" onClick={EditQuestionBank} className="btn-floating btn-sm btn-default m-1">
                    <i className="fas fa-pen text-white"></i>
                </button>
                <button title="Clone" type="button" onClick={CloneQuestion} className="btn-floating btn-sm btn-default m-1">
                    <i className="far fa-clone"></i>
                </button>
                <button title="Delete" type="button" onClick={DeleteQuestionBank} className="btn-floating btn-sm btn-danger m-1">
                    <i className="fas fa-trash text-white"></i>
                </button>
            </td>
        </tr>
    );
}

class QuestionBanksList extends Component {
    static DisplayName = QuestionBanksList.name
    constructor(props){
        super(props)
        this.state = { QuestionBanks: []}
    }
    componentDidMount() {        
        this.fetchQuestionBank(this, '');
    }
    resetQBList = () => {
        this.fetchQuestionBank(this, "");
    }
    searchQBListInServer = async () => {
        this.fetchQuestionBank(this, $('#myCustomSearchBox').val());
    }

    DeleteQuestionBank = async (questionID) => {
        if (await confirmWrapper('Are you sure that you want to delete the record ?')) {
            api.deleteData('api/QuestionBanks/DeleteQuestionBank?id=' + questionID)
                .then(res => {
                    if (res.data.status === 'Delete') {
                        // (async () => infoWrapper('Record deleted successfully!!'))();
                        document.getElementById("Row_" + questionID).remove();
                    }
                })
        }
    };
    
    CloneQuestion = async (questionID) => {
        if (await confirmWrapper('Are you sure that you want to clone the Question ?')) {
            api.postData('api/QuestionBanks/CloneQuestion?id=' + questionID)
                .then(res => {
                    this.forEditQuestionBank(res.data);
                })
        }
    };
    fetchQuestionBank = (thisObject, searchString) => {
        let url = '';
        if (typeof searchString === 'undefined' || searchString.trim() == '')
            url = 'api/QuestionBanks/QuestionBanksListBySubject';
        else
            url = 'api/QuestionBanks/QuestionBanksListSearchByDesc/' + searchString;

        api.fetchData(url)
            .then(response => {
                let data = response.data;
                this.setState({ QuestionBanks: [] });
                this.setState({ QuestionBanks: data });
                let table = $('#dtqblist').DataTable({
                    "dom": "lrtip"
                });
                $('.dataTables_length').addClass('bs-select');
                let searchString = "";

                table.columns().every(function () {
                    var that = this;
                    $('#dtqblist_filter input').on('keyup change', function () {
                        searchString = this.value;
                        if (that.search() == this.value) {
                            that.search(this.value).draw();
                        }
                    });
                });

                table.on('preDraw', function () {
                }).on('draw.dt', function () {
                    var rowCount = $("#dtqblist tbody tr").length - $("#dtqblist tbody .dataTables_empty").length;
                    if (rowCount == 0 && searchString != "") {
                        thisObject.fetchQuestionBank(thisObject, searchString);
                    }
                });

                $('#myCustomSearchBox').keyup(function () {
                    table.search($(this).val()).draw(); // this  is for customized searchbox with datatable search feature.
                })
            });
    }
    onAddQuestionBank = ()=>{
        this.props.forAddorEdit(0)
    }
    forEditQuestionBank = (id)=>{
        this.props.forAddorEdit(id)
    }

    render() {

        return (
            <Fragment>
                <div className="row d-flex justify-content-between card-header align-items-center">
                    <h5 className="">Questions</h5>
                    <button className="btn btn-default btn-md btn-rounded" onClick={this.onAddQuestionBank}>Add Question</button>
                </div>
                <div className="row">                    
                    <div className="input-group">
                        <input type="text" name="myCustomSearchBox" id="myCustomSearchBox" className="form-control" style={{ marginTop:"7px" }} placeholder="Enter text to search" />
                        <button className="btn btn-default btn-md btn-rounded" onClick={this.searchQBListInServer} title="Search in server"><i className="fas fa-search"></i></button>
                        <button className="btn btn-default btn-md btn-rounded" onClick={this.resetQBList} title="Reset"><i className="fas fa-undo"></i></button>
                    </div>
                </div>
                {this.state.QuestionBanks.length > 0 ?
                    <div className="row">
                        <div className="col-sm-12" className="table-responsive">
                            <table id="dtqblist" className="table table-striped table-bordered table-sm table-responsive" cellSpacing="0" width="100%" role="grid" aria-describedby="dtqblist_info" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th className="th-sm">Question Code</th>
                                        <th className="th-sm">Question Title</th>
                                        <th className="th-sm">Subject</th>
                                        <th className="th-sm">Question Type</th>
                                        <th className="th-sm">Status</th>
                                        <th className="th-sm">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.QuestionBanks.map(question => {
                                            return (
                                                <tr id={`Row_${question.questionID}`}>
                                                    <td>
                                                        {question.questionCode}
                                                    </td>
                                                    <td>
                                                        {question.title}
                                                    </td>
                                                    <td>
                                                        {question.subject === null ? "No Subject" : question.subject.subjectName}
                                                    </td>
                                                    <td>
                                                        {question.type}
                                                    </td>
                                                    <td>
                                                        {question.status}
                                                    </td>
                                                    <td style={{ minWidth: "133px" }}>
                                                        <button title="Edit" type="button" onClick={() => this.forEditQuestionBank(question.questionID)} className="btn-floating btn-sm btn-default m-1">
                                                            <i className="fas fa-pen text-white"></i>
                                                        </button>
                                                        <button title="Clone" type="button" onClick={() => this.CloneQuestion(question.questionID)} className="btn-floating btn-sm btn-default m-1">
                                                            <i className="far fa-clone"></i>
                                                        </button>
                                                        <button title="Delete" type="button" onClick={() => this.DeleteQuestionBank(question.questionID)} className="btn-floating btn-sm btn-danger m-1">
                                                            <i className="fas fa-trash text-white"></i>
                                                        </button>
                                                    </td>
                                                </tr>)
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div> :<p>No Data found to Display</p>
                }
            </Fragment>
        )
    }
}

const QuestionBank=()=> {
    const [isAddorEdit, setIsAddorEdit] = useState(false);
    const [addorEditId, setAddorEditId] = useState(0);

    const onAddOrEdit = (id)=>{
        setAddorEditId(id);
        setIsAddorEdit(true);
    };
    const forList = ()=>{
        setIsAddorEdit(false)
    }

    return (
        <Fragment>
            { !isAddorEdit && <QuestionBanksList forAddorEdit={onAddOrEdit}/> }
            { isAddorEdit && <AddorUpdateQuestionBank id={addorEditId} closeAddorEdit={forList}/>}
        </Fragment>
    )
};
export default QuestionBank;