import React, { Fragment } from "react";

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe("pk_test_51JqEA4DsQcxgC4NZvMjR77Y6GHZjDXiVi4jNIw9ErtTWQ7YHtpXNgbtbTGRWvw9KiufJBSeZCvXhBPbyYLkgrN6V00Tbzu9Frg");

const StripeContainer = ({ children, title }) => {
    console.log(process.env.PUBLISHABLE_KEY);
    return (
        <Fragment>
            {/*<GlobalStyles />*/}
            <Elements stripe={stripePromise}>{children}</Elements>
        </Fragment>
    );
};

export default StripeContainer;