import React, {useState, useEffect, Fragment} from 'react';
import { infoWrapper } from '../InfoAlert';

export const Timer = (props)=>{
  const [second, setSecond] = useState(0);
  const [minute, setMinute] = useState(props.min%60);
  const [hour, setHour] = useState(Math.floor(props.min/60));
  useEffect(()=>{
    let timeInterval = second > 0 && setInterval(() => setSecond(second - 1), 1000);
    if(second === 0){
      if(minute === 0){
        if(hour === 0){
          (async () => infoWrapper('Time Completed!'))();
          props.endExam();
        }
        else{
          setHour(hour - 1)
          setMinute(59)
          timeInterval = setInterval(() => setSecond(59), 1000);
        }
      }
      else{
        setMinute(minute - 1)
        timeInterval = setInterval(() => setSecond(59), 1000);
      }
      props.updateRemainingTime(hour * 60 + minute);
    }

    return () => {
      clearInterval(timeInterval);
    };
  },[second]);

  return ( 
  <Fragment>
    {hour < 10 ? `0${hour}`: hour}:
    {minute < 10 ? `0${minute}`: minute}:
    {second < 10 ? `0${second}`: second}
  </Fragment>
  );
};

export default Timer;

{/* <div className="question-view-side-timer">
{hour < 10 ? `0${hour}`: hour}:
{minute < 10 ? `0${minute}`: minute}:
{second < 10 ? `0${second}`: second}
</div> */}